<template>
  <form
    class="form"
    v-if="is_active"
    @submit.prevent="handleSubmit({ ...embed })"
  >
    <div class="form__wrapper padding__l--12 padding__r--6">
      <fa-icon
        class="form__icon"
        :icon="embed.type === 'video' ? ['fab', 'youtube'] : ['fal', 'code']"
        fixed-width
      />

      <input
        class="form__input"
        type="text"
        v-model="embed.input"
        :placeholder="embed.type === 'video' ? t('editor.placeholder_video') : t('editor.placeholder_iframe')"
        ref="input"
      >

      <button
        class="link__color--success link--naked form__action"
        type="submit"
      >
        <fa-icon
          :icon="['fal', 'check']"
          fixed-width
        />
      </button>

      <button
        class="link__color--danger link--naked form__action"
        @click="handleSubmit({ input: null })"
        type="button"
      >
        <fa-icon
          :icon="['fal', 'times']"
          fixed-width
        />
      </button>
    </div>

    <div
      class="padding__l--12 padding__r--6 padding__t--6"
      v-if="v$ && v$.input.$error"
    >
      <span
        class="input--error"
        v-if="v$.input && v$.input.validVideoUrl && v$.input.validVideoUrl.$invalid"
      >
        {{ t('errors.input_url_incorrect') }}
      </span>

      <span
        class="input--error"
        v-if="v$.input && v$.input.validIframe && v$.input.validIframe.$invalid"
      >
        {{ t('errors.input_iframe_incorrect') }}
      </span>
    </div>
  </form>
</template>

<script setup>
import { ref, reactive, computed, nextTick } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useVuelidate } from '@vuelidate/core'
import { validVideoUrl, validIframe } from '@/utils/validators'

const emit = defineEmits(['show', 'hide', 'command'])

const { t } = useI18n()

const props = defineProps({
  editor: { type: Object, required: true }
})

const rules = computed(() => {
  if (embed.type === 'video') return { input: { validVideoUrl } }
  if (embed.type === 'code') return { input: { validIframe } }

  return {}
})

const input = ref(null)

const is_active = ref(false)
const type = ref(null)
const embed = reactive({ input: null, type: null })

const v$ = useVuelidate(rules, embed)

const toggle = attrs => attrs ? show(attrs) : hide()

const show = (attrs = {}) => {
  Object.assign(embed, { input: attrs.input || '', type: type.value = attrs.type })

  is_active.value = true

  emit('show', { padding: 71 })

  nextTick(() => input.value.focus())
}

const hide = () => {
  if (!is_active.value) return

  Object.assign(embed, { input: null, type: null })

  is_active.value = false

  v$.value.$reset()

  emit('hide')
}

const handleSubmit = attrs => {
  const { type, input } = attrs

  v$.value.$touch()

  if (v$.value.$error) return

  props.editor.commands.setEmbed({ type, input })
  hide()
}

defineExpose({ show, hide, toggle })
</script>

<style lang="scss" scoped>
.form {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: $padding__base;
  background: $dw;
  transform: translateY(100%);

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $white;
    border-radius: $radius__input;
  }

  &__input {
    display: block;
    flex-grow: 1;
    outline: none;
    padding: 0 calc($padding__base / 2);
    height: 48px;
    border: 0;
    width: 100%;

    @include mq(sm) {
      width: inherit;
    }
  }

  &__action {
    padding: calc($padding__base / 2);

    @include mq(md) {
      padding: $padding__base;
    }
  }
}

.colors {
  &__list {
    display: flex;
    justify-content: center;
    margin-top: calc($margin__base / 1.5);

    @include mq(sm) {
      margin-top: 0;
    }
  }

  &__li {
    &:not(:last-child) {
      margin-right: calc($margin__base / 1.5);

      @include mq(sm) {
        margin-right: $margin__base;
      }

      @include mq(md) {
        margin-right: calc($margin__base / 2);
      }

      @include mq(lg) {
        margin-right: $margin__base;
      }
    }
  }

  &__label {
    display: block;
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all $transition__duration;

    @include mq(sm) {
      width: 24px;
      height: 24px;
    }

    @include mq(md) {
      width: 20px;
      height: 20px;
    }

    @include mq(lg) {
      width: 24px;
      height: 24px;
    }

    &--white-color {
      border-color: rgba($dg, 0.1);
    }
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked+label {
      border-color: rgba($dg, 0.5);
    }
  }
}

.radio {
  &__toggles {
    background-color: $white;
    border-radius: $radius__input;
    border: 1px solid v$dw;
    display: inline-block;
    position: relative;
  }

  &__label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 75px;
    padding: 4px calc($padding__base / 2);
    z-index: 2;
    text-transform: uppercase;
    text-align: center;
    font-size: rem(14px);
    white-space: nowrap;
    color: $lg;
    transition: all .3s ease-out;

    @include mq(sm) {
      padding: 4px $padding__base;
      width: 100px;
    }

    @include mq(md) {
      padding: 4px calc($padding__base / 2);
      width: 75px;
    }

    @include mq(lg) {
      padding: 4px $padding__base;
      width: 100px;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked+.radio__label {
      color: $white;
    }

    &:checked~.radio__slide {
      border-radius: $radius__input;
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(50% - 4px);
      height: calc(100% - 4px);
      background-color: $color--primary;
      z-index: 1;
      transition: all .3s ease-out;
    }

    @for $i from 1 through 2 {
      &:nth-of-type(#{$i}):checked~.radio__slide {
        left: calc((50% * (#{$i - 1})) + 2px);
      }
    }
  }
}
</style>