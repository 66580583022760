<template>
  <div class="input--border input--radius">
    <div :class="{'input__area': currency}">
      <app-currency
        class="input__currency"
        :type="currency"
        v-if="currency"
      />

      <input
        type="text"
        class="input"
        v-model="number"
        ref="input"
        :id="id"
        :placeholder="placeholder"
        :name="name"
        :disabled="disabled"
        @blur="blurIfMandatory()"
      >
    </div>

    <div
      class="input--errors"
      v-if="v?.$error"
    >
      <p
        class="input--error"
        v-if="v.numeric?.$invalid"
      >
        {{ t('errors.input_number_not_numeric') }}
      </p>

      <p
        class="input--error"
        v-if="v.minAmount?.$invalid"
      >
        {{ t('errors.input_number_min_value_currency', { value: getAmount(v.minAmount.$params.min) }) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount, ref, watch } from 'vue'
import { getAmount } from '@/utils/utils'
import { guid } from '@/utils/utils'
import { useI18n } from '@/vendors/i18n'
import Cleave from 'cleave.js'

import AppCurrency from '&/atoms/AppCurrency'

const { t, getLocaleCurrencyFormat } = useI18n()
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  id: { type: String, default: () => Math.random().toString() },
  placeholder: { type: String, default: '...' },
  modelValue: { type: [Number, String], default: null },
  disabled: { type: Boolean, default: false },
  currency: { type: String, default: 'EUR' },
  v: Object
})

const name = `input_amount${guid(1)}`

const cleave = ref()
const input = ref()
const format = getLocaleCurrencyFormat()

const number = computed({
  get () {
    if (props.modelValue === null) return null

    return cleave.value ? cleave.value.properties.result : (props.modelValue / 100).toFixed(2).toString().replace('.00', '').replace('.', format.decimal)
  },
  set (value) {
    const val = value ? (parseFloat(cleave.value.getRawValue()).toFixed(2)).toString().replace('.', '') : 0
    cleave.value.setRawValue(value)
    emit('update:modelValue', parseInt(val))
  }
})

const blurIfMandatory = () => {
  if (props.v) props.v.$touch()
}

watch(() => props.modelValue, newValue => cleave.value.setRawValue(newValue / 100))

onMounted(() => {
  cleave.value = new Cleave(input.value, {
    delimiter: format.delimiter || ',',
    numeralDecimalMark: format.decimal || '.',
    numeralDecimalScale: 2,
    numeralPositiveOnly: true,
    numeral: true
  })
})

onBeforeUnmount(() => {
  if (!cleave.value) return
  cleave.value.destroy()
  cleave.value = null
})

</script>

<style lang="scss" scoped>
.input {
  &__area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: $padding__base;
  }

  &__currency {
    margin-right: calc($margin__base / 2);
  }
}
</style>
