<template>
  <section v-if="project">
    <article class="card">
      <div class="card__padding">
        <h3 class="title__h3 bold">
          {{ t('literal.project_info') }}
        </h3>

        <form-notification
          class="margin__t--12"
          :keys="error"
        />

        <fieldset class="margin__t--18">
          <label
            for="name"
            class="input__label input__label--small"
          >
            {{ t('literal.title') }}
          </label>

          <input-text
            id="name"
            class="margin__t--6"
            v-model="clonedProject.name"
            :v="v$.name"
          />
        </fieldset>

        <fieldset class="margin__t--18">
          <label
            class="input__label input__label--small"
            for="image"
          >
            {{ t('literal.cover_image') }}
          </label>

          <app-image-preview
            id="image"
            class="margin__t--6"
            v-model="cover"
            :width="900"
            :height="600"
            accept="image/gif, image/jpeg, image/png"
            :upload="false"
            :deletable="false"
          />
        </fieldset>

        <fieldset class="margin__t--18">
          <label
            for="video"
            class="input__label input__label--small"
          >
            {{ t('literal.link_to_video') }} (Youtube, Vimeo)
          </label>

          <input-url
            id="video"
            class="margin__t--6"
            v-model="clonedProject.video_url"
            :v="v$.video_url"
          />
        </fieldset>

        <app-save-button
          class="margin__t--24"
          name="save_general_infos"
          :is-loading="loader"
          :is-disabled="loader || deleteLoader"
          :is-saved="!isUnsaved"
          @click="handleClickSave()"
        />
      </div>
    </article>

    <article
      class="card margin__t--24"
      v-if="project.isAdmin(authUser)"
    >
      <div class="card__main">
        <h3 class="title__h3 bold">
          {{ t('literal.delete_project') }}
        </h3>

        <form-notification
          class="margin__t--12"
          :keys="deleteError"
        />

        <p class="margin__t--18">
          {{ t('project.settings_delete_description') }}
        </p>

        <app-save-button
          class="btn__color--danger margin__t--24"
          name="delete_project"
          :is-loading="deleteLoader"
          :is-disabled="loader || deleteLoader"
          @click="openDeleteConfirmationModal()"
        >
          {{ t('literal.delete_project') }}

          <template #icon>
            <fa-icon :icon="['fal', 'trash']" />
          </template>
        </app-save-button>
      </div>
    </article>

    <modal-confirm
      v-if="show_delete_confirmation_modal"
      @close="closeDeleteConfirmationModal()"
      @confirm="handleClickDelete()"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('project.settings_delete_confirmation_msg') }}</p>
        </div>
      </template>
    </modal-confirm>

    <modal-confirm
      v-if="show_unsaved_modal"
      @close="cancelLeavingPage()"
      @confirm="leavePage()"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('literal.leave_page_without_saving') }}</p>
        </div>
      </template>
    </modal-confirm>
  </section>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useProjectStore } from '@/stores/ProjectStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useTracking } from '@/vendors/tracking'
import { useModals } from '@/composables/app/useModals'
import { useUnsaved } from '@/composables/app/useUnsaved'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { validVideoUrl } from '@/utils/validators'
import { scrollTo, resolvePromisesSequentially } from '@/utils/utils'
import { get as _get } from 'lodash'

import InputText from '&/atoms/InputText'
import InputUrl from '&/atoms/InputUrl'
import AppImagePreview from '&/organisms/AppImagePreview'
import AppSaveButton from '&/molecules/AppSaveButton'

const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))
const ModalConfirm = defineAsyncComponent(() => import('&/organisms/ModalConfirm'))

const props = defineProps({ projectSlug: { type: String, required: true } })

const { projectSlug } = toRefs(props)

const { t } = useI18n()
const { push, replace } = useRouter()
const route = useRoute()
const tracking = useTracking()
const { postSideNotification } = useNotificationStore()
const { authUser } = storeToRefs(useAuthenticationStore())
const { getProject, patchProject, patchProjectImage, deleteProject, $resetStore } = useProjectStore()
const { loader: deleteLoader, error: deleteError } = storeToRefs(useProjectStore())
const { show_modal: show_delete_confirmation_modal, openModal: openDeleteConfirmationModal, closeModal: closeDeleteConfirmationModal } = useModals()

const { project, clonedProject, loader, error } = getProject({ id: projectSlug.value })

useHead({ title: computed(() => `${t('literal.general')} / ${t('literal.settings')} / ${project.value?.name}`) })
const v$ = useVuelidate({ name: { required, minLength: minLength(3) }, video_url: { validVideoUrl } }, clonedProject)
const { show_unsaved_modal, isUnsaved, cancelLeavingPage, leavePage } = useUnsaved({ condition: computed(() => JSON.stringify(project.value) !== JSON.stringify(clonedProject.value)) })

const formData = new FormData()

const cover = computed({
  get: () => _get(clonedProject.value, 'image.url') || _get(clonedProject.value, 'campaign.image.url'),
  set: file => {
    clonedProject.value.image.url = file?.toBlobURL()

    formData.delete('project[image]')

    if (file) formData.append('project[image]', file)
  }
})

const handleClickSave = () => {
  v$.value.$touch()

  if (v$.value.$error) return scrollTo('.input--errors')

  const promises = [patchProject.bind(null, { id: project.value.slug, params: clonedProject.value })]

  if (formData.get('project[image]')) promises.unshift(patchProjectImage.bind(null, { id: project.value.slug, params: formData }))

  resolvePromisesSequentially(promises).then(() => {
    postSideNotification({ text: 'literal.modifications_have_been_saved' })

    if (projectSlug.value !== project.value.slug) replace({ ...route, params: { projectSlug: project.value.slug } })
  })
}

const handleClickDelete = () => {
  closeDeleteConfirmationModal()

  deleteProject({ id: project.value.slug })
    .then(() => {
      tracking.project.deleted({ project: project.value })

      $resetStore(['projects', 'project'])
      push({ name: 'Home' })
    })
}
</script>
