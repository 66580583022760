
import i18n from '@/vendors/i18n'

export const default_config = {
  flow: 'epms',
  amounts: [10, 25, 40, 100],
  allowCustomAmount: true,
  paymentMethods: ['card', 'apple_pay', 'google_pay', 'sepa_dd', 'twi', 'eps', 'mae', 'bancontact', 'ideal', 'paypal'],
  currencies: ['eur'],
  defaultCurrency: 'eur',
  purposes: [],
  showFooter: false,
  showSubmitButton: false,
  showTestModeBar: false,
  uiInvalidElementsScope: undefined,
  showBlockHeaders: {
    payment_payment_methods: false
  },
  paymentFormPrefill: {
    currency: 'eur'
  },
  forceShowBlocks: {
    payment_purposes: false,
    payment_amounts_and_intervals: false,
    payment_payment_methods: true,
    payment_profile: false,
    payment_profile_short: false,
    payment_address: false,
    payment_email_permission: false,
    payment_cover_fee: false,
    payment_sepa_mandate_terms: false
  },
  showFields: {
    stored_is_company_donation: false,
    stored_customer_company: false,
    stored_customer_salutation: false,
    stored_customer_firstname: false,
    stored_customer_lastname: false,
    stored_customer_raw_name: false,
    stored_customer_phone: false,
    stored_customer_email: false,
    stored_customer_birthdate: false,
    stored_customer_pan: false,
    stored_customer_email_permission: false,
    stored_customer_message: false,
    stored_customer_donation_receipt: false,
    stored_customer_street: false,
    stored_customer_street_number: false,
    stored_customer_street2: false,
    stored_customer_pobox: false,
    stored_customer_zip_code: false,
    stored_customer_city: false,
    stored_customer_country: false,
    stored_customer_state: false,
    stored_customer_raw_address: false,
    bic: true
  },
  paymentValidations: {
    purpose: { required: false },
    payment_method: { payment_method_valid: false },
    stored_customer_salutation: { required: false },
    stored_customer_birthdate: { required: false, age: false },
    stored_customer_street: { required: false },
    stored_customer_street_number: { required: false },
    stored_customer_zip_code: { required: false },
    stored_customer_city: { required: false },
    stored_customer_state: { required: false },
    stored_customer_country: { required: false }
  },
  testMode: false,
  epmsEnv: process.env.VUE_APP_TAMARO_ENV,
  recurringIntervals: ['monthly']
}

export const available_fields = {
  stored_customer_birthdate: {
    name: 'transaction_customer_birthday',
    model: 'customer.birthday',
    mandatory: ['required'],
    attributes: {
      label: i18n.getLocalesTranslations('$block_tamaro.customer_birthday'),
      placeholder: i18n.getLocalesTranslations('$block_tamaro.customer_birthday_ph')
    }
  },
  stored_customer_street: {
    name: 'transaction_customer_address_line1',
    model: 'customer.address.line1',
    mandatory: ['required'],
    attributes: {
      label: i18n.getLocalesTranslations('$block_tamaro.customer_address_line1'),
      placeholder: i18n.getLocalesTranslations('$block_tamaro.customer_address_line1_ph')
    }
  },
  stored_customer_zip_code: {
    name: 'transaction_customer_address_postal_code',
    model: 'customer.address.postal_code',
    mandatory: ['required'],
    attributes: {
      label: i18n.getLocalesTranslations('$block_tamaro.customer_address_postal_code'),
      placeholder: i18n.getLocalesTranslations('$block_tamaro.customer_address_postal_code_ph')
    }
  },
  stored_customer_city: {
    name: 'transaction_customer_address_city',
    model: 'customer.address.city',
    mandatory: ['required'],
    attributes: {
      label: i18n.getLocalesTranslations('$block_tamaro.customer_address_city'),
      placeholder: i18n.getLocalesTranslations('$block_tamaro.customer_address_city_ph')
    }
  },
  stored_customer_country: {
    name: 'transaction_customer_address_country',
    model: 'customer.address.country',
    mandatory: ['required'],
    attributes: {
      label: i18n.getLocalesTranslations('$block_tamaro.customer_address_country'),
      placeholder: i18n.getLocalesTranslations('$block_tamaro.customer_address_country_ph')
    }
  },
  stored_customer_state: {
    name: 'transaction_customer_address_state',
    model: 'customer.address.state',
    mandatory: ['required'],
    attributes: {
      label: i18n.getLocalesTranslations('$block_tamaro.customer_address_state'),
      placeholder: i18n.getLocalesTranslations('$block_tamaro.customer_address_state_ph')
    }
  },
  sepa_mandate_terms: {
    name: 'informations_sepa_mandate_terms',
    model: 'informations.sepa_mandate_terms',
    attributes: {
      editor_content: i18n.getLocalesTranslations('$block_tamaro.informations_sepa_mandate_terms'),
      text: i18n.getLocalesTranslations('$block_tamaro.informations_sepa_mandate_terms_text')
    }
  }
}

export const available_payment_types = {
  one_time: 'onetime',
  monthly: 'recurring'
}