<template>
  <div class="home">
    <section class="banner">
      <figure
        class="banner__bg"
        :style="{ backgroundImage: `url(${$i18n.translate(attributes.banner.image)})` }"
      />

      <div class="container">
        <div class="banner__content">
          <h1 class="title--h1">
            {{ $i18n.translate(attributes.banner.title) }}
          </h1>

          <div
            class="editor editor__content"
            v-html="$i18n.interpolate($i18n.translate(attributes.banner.text), { stats: { amount, members } })"
          />

          <ul class="banner__ul">
            <li
              v-for="({ button }, index) in attributes.banner.buttons"
              :key="`banner_button_${index}`"
              class="banner__cta"
            >
              <button
                type="button"
                class="btn"
                v-if="button.show"
                @click.prevent="setRouteNavigation(button)"
              >
                {{ $i18n.translate(button.text) }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="projects">
      <div class="container">
        <h2 class="title--h2">
          {{ $i18n.translate(attributes.projects.title) }}
        </h2>

        <div class="projects__list row">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-4 col-lg-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>
      </div>
    </section>

    <section class="campaigns">
      <div class="container">
        <h2 class="title--h2 align--center">
          {{ $i18n.translate(attributes.campaigns.title) }}
        </h2>

        <div class="campaigns__list">
          <article
            class="campaign"
            v-for="(campaign, index) in attributes.campaigns.items"
            :key="`campaign_home_${index}`"
          >
            <figure
              class="campaign__bg"
              :style="{ backgroundImage: `url(${$i18n.translate(campaign.img)})` }"
            >
              <figcaption class="figcaption">
                {{ $i18n.translate(campaign.title) }}
              </figcaption>
            </figure>

            <div class="campaign__content">
              <h3 class="title--h3">
                {{ $i18n.translate(campaign.title) }}
              </h3>

              <div
                class="editor editor__content"
                v-html="$i18n.translate(campaign.text)"
              />

              <button
                type="button"
                class="btn"
                v-if="campaign.button.show"
                @click.prevent="setRouteNavigation(campaign.button)"
              >
                {{ $i18n.translate(campaign.button.text) }}
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>

    <section class="steps">
      <div class="container">
        <h2 class="title--h2 align--center">
          {{ $i18n.translate(attributes.steps.title) }}
        </h2>

        <div>
          <article
            class="step"
            v-for="(step, index) in attributes.steps.items"
            :key="`step_home_${index}`"
          >
            <figure
              class="step__bg"
              :style="{ backgroundImage: `url(${$i18n.translate(step.img)})` }"
            >
              <figcaption class="figcaption">
                {{ $i18n.translate(step.title) }}
              </figcaption>
            </figure>

            <div>
              <span class="step__number">
                {{ $i18n.translate(step.number) }}
              </span>

              <h2 class="title--h2">
                {{ $i18n.translate(step.title) }}
              </h2>

              <div
                class="editor editor__content"
                v-html="$i18n.translate(step.text)"
              />

              <span class="step__tip">
                {{ $i18n.translate(step.tip) }}
              </span>
            </div>
          </article>
        </div>
      </div>
    </section>

    <section class="calltoaction">
      <div class="container">
        <h2 class="title--h2 align--center">
          {{ $i18n.translate(attributes.cta.title) }}
        </h2>

        <button
          type="button"
          class="btn"
          v-if="attributes.cta.button.show"
          @click.prevent="setRouteNavigation(attributes.cta.button)"
        >
          {{ $i18n.translate(attributes.cta.button.text) }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { get as _get } from 'lodash'
import PageRouteNavigation from '%/PageRouteNavigation'
import ProjectCard from '&/organisms/ProjectCard'
import { getAmount } from '@/utils/utils'

export default defineComponent({
  name: 'CustomHomepageUZBrusselFoundation',
  components: {
    ProjectCard
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      projects: []
    }
  },
  created () {
    this.fetchClientStatistics()
    this.fetchProjects({
      query: _get(this.attributes, 'projects.query', {
        'sort_by_field[field]': 'current_amount',
        'sort_by_field[direction]': 'desc',
        'per_page': 4
      }), removeStoredItems: true
    })
      .then(data => this.projects = Object.values(data.items))
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    ...mapGetters({
      statistics: 'client/statistics'
    }),
    attributes () {
      return this.section.attributes
    },
    amount () {
      return getAmount(_get(this.statistics, 'amount_collected', 0), this.$i18n.locale)
    },
    members () {
      return _get(this.statistics, 'project_members_count', 0)
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      fetchClientStatistics: 'client/fetchClientStatistics'
    })
  }
})
</script>

<style lang="scss" scoped>
.banner {
  z-index: 10;
  position: relative;

  &__bg {
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 40.625%;
    }
  }

  &__content {
    position: relative;
    max-width: 780px;
    background-color: $white;
    padding: 30px;
    margin-top: -50px;

    @include mq(sm) {
      padding: 50px 110px;
    }

    @include mq(md) {
      position: absolute;
      right: 90px;
      bottom: 0;
      transform: translate3d(0, 55%, 0);
      z-index: 0;
      margin: 0;
    }

    & .editor {
      margin-top: $margin__base * 2;
      margin-bottom: 40px;
    }
  }

  &__ul {
    display: block;

    @include mq(sm) {
      display: flex;
    }
  }

  &__cta {
    &:not(:first-child) {
      margin-top: 10px;

      @include mq(sm) {
        margin-left: 40px;
        margin-top: 0;
      }
    }

    &:nth-child(odd) .btn {
      background: $color--primary;
      color: #121415;

      &:hover {
        background: $color--tertiary;
        color: $white;
      }
    }

    &:nth-child(even) .btn {
      background: $color--tertiary;
      color: $white;

      &:hover {
        background: $color--primary;
        color: #121415;
      }
    }
  }
}

.projects {
  padding-top: 30px;
  overflow: hidden;
  z-index: 0;
  position: relative;

  @include mq(md) {
    padding-top: 265px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    max-height: 420px;
    box-sizing: border-box;
    display: none;
    background: rgba(#808285, .18);

    @include mq(md) {
      display: block;
    }
  }

  & .title--h2 {
    margin-bottom: 32px;
  }
}

.campaigns {
  margin-top: 50px;

  & .title--h2 {
    margin-bottom: 40px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: $margin--base * 4;
    }

    @include mq(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.campaign {
  height: 100%;
  background-color: #bcbe009e;

  &__bg {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 91.37056%;
    }
  }

  &__content {
    padding: 12px 30px 60px;
    text-align: center;
  }

  & .title--h3 {
    margin-bottom: 6px;
  }

  & .editor {
    color: #60614a;
  }

  & .btn {
    font-size: rem(15px);
    margin-top: 20px;
    padding-bottom: 6px;
    color: #4e5a62;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: #435057;
    }
  }
}

.figcaption {
  font: 0;
  position: absolute;
  z-index: 0;
  color: transparent;
  border: 0;
}

.steps {
  padding-top: 50px;

  & .title--h2 {
    margin-bottom: 40px;
  }
}

.step {
  position: relative;
  margin-bottom: 40px;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @include mq(sm) {
    display: flex;
    align-items: center;
  }

  &__bg {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    margin-bottom: 30px;
    height: 225px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 58.4%;
    }

    @include mq(sm) {
      height: 225px;
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &__number {
    font-size: rem(24px);
    font-style: italic;
    line-height: 1.9;
    margin-bottom: 6px;
    color: #bcbe00;
  }

  & .title--h2 {
    margin-bottom: 25px;
    color: #131415;
  }

  & .editor {
    font-size: rem(21px);
    line-height: 1.4;
  }

  &__tip {
    font-size: rem(12px);
    font-style: italic;
    line-height: 1.3;
    display: inline-block;
    padding: 6px 16px;
    margin-top: 24px;
    color: #28292a;
    background: rgba(188,190,0,.3);
  }
}

.calltoaction {
  padding: 45px 0;

  @include mq(sm) {
    padding: 90px 0;
  }

  & .title--h2 {
    color: $color--primary;
    margin-bottom: 24px;
  }

  & .btn {
    width: 100%;
    max-width: 360px;
    background: $color--primary;
    display: block;
    margin: auto;
    text-align: center;
    color: #121415;
    transition: all .3s;

    &:hover {
      background: $color--tertiary;
      color: $white;
    }
  }
}

.btn {
  padding: 8px 16px;
  border-radius: 0;
  font-weight: bold;

  @include mq(sm) {
    padding: 11px 21px;
  }
}

.title {
  &--h1 {
    font-size: rem(36px);
    color: $color--tertiary;
    font-weight: bold;

    @include mq(sm) {
      font-size: rem(43px);
    }
  }

  &--h2 {
    font-size: rem(27px);
    color: $color--tertiary;
    font-weight: bold;

    @include mq(sm) {
      font-size: rem(34px);
    }
  }

  &--h3 {
    font-size: rem(26px);
    color: #131415;
    font-weight: bold;
  }
}
</style>
