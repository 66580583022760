<template>
  <div v-if="product">
    <div class="page__head">
      <router-link
        class="page__title"
        :to="{ name: 'PrivateAllProducts' }"
      >
        <fa-icon
          class="icon__back"
          :icon="['fal', 'arrow-left']"
        />

        <h2 class="title__h2 bold">
          {{ translate(product.name) }}
        </h2>
      </router-link>

      <app-save-button
        v-if="isEnabled || product.target_type !== 'Campaign'"
        :is-loading="loader || fileLoader"
        :is-disabled="loader || fileLoader"
        :is-saved="!isUnsaved"
        :is-responsive="true"
        @click-unsaved="handleClickSave"
      >
        {{ t('literal.save_changes') }}
      </app-save-button>
    </div>

    <private-product-form
      v-model:product="clonedProduct"
      v-model:project="clonedProject"
      v-model:added-quantity="added_quantity"
      @open-delete-modale="openDeleteModal()"
    />

    <app-save-button
      v-if="isEnabled || product.target_type !== 'Campaign'"
      :is-loading="loader || fileLoader"
      :is-disabled="loader || fileLoader"
      :is-saved="!isUnsaved"
      @click-unsaved="handleClickSave"
    >
      {{ t('literal.save_changes') }}
    </app-save-button>

    <modal-confirm
      v-if="show_unsaved_modal"
      @close="cancelLeavingPage()"
      @confirm="leavePage()"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('literal.leave_page_without_saving') }}</p>
        </div>
      </template>
    </modal-confirm>

    <modal-confirm
      v-if="show_delete_modal"
      @close="closeDeleteModal()"
      @confirm="confirmDelete()"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('literal.are_you_sure_you_want_to_delete_that_product') }}</p>
        </div>
      </template>
    </modal-confirm>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { mapGetters } from '@/store/map-state'
import { useProjectStore } from '@/stores/ProjectStore'
import { useProductStore } from '@/stores/ProductStore'
import { useFileStore } from '@/stores/FileStore'
import { useModals } from '@/composables/app/useModals'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from '@/vendors/i18n'
import { useRoles } from '@/composables/app/useRoles'
import { useProduct } from '@/composables/modules/projects/useProduct'
import { useUnsaved } from '@/composables/app/useUnsaved'
import { get as _get } from 'lodash'
import useVuelidate from '@vuelidate/core'
import { scrollTo } from '@/utils/utils'

import AppSaveButton from '&/molecules/AppSaveButton'
import PrivateProductForm from '&/modules/project/PrivateProductForm'
const ModalConfirm = defineAsyncComponent(() => import('&/organisms/ModalConfirm'))

const props = defineProps({
  projectSlug: { required: true, type: String },
  productId: { required: true, type: String }
})

const { t, translate } = useI18n()
const { push } = useRouter()
const { getProject, patchProject } = useProjectStore()
const { getProduct, patchProduct, postProductStock, deleteProduct, $resetStore } = useProductStore()
const { show_modal: show_delete_modal, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModals()
const { loader } = storeToRefs(useProductStore())
const { loader: fileLoader } = storeToRefs(useFileStore())
const { isSuperAdmin, isKoalect } = useRoles()
const { user } = mapGetters('auth')
const v$ = useVuelidate()

const { project, clonedProject } = getProject({ id: props.projectSlug })
const { product, clonedProduct } = getProduct({ id: props.productId })
const { productsDisplayOrder, isCampaignProduct } = useProduct(product, clonedProject)

useHead({ title: computed(() => project.value && product.value ? `${translate(_get(product.value, 'name', ''))} / ${t('literal.products')} / ${_get(project.value, 'name', '')}` : null) })

const isProjectUpdated = computed(() => JSON.stringify(project.value) !== JSON.stringify(clonedProject.value))
const isProductUpdated = computed(() => JSON.stringify(product.value) !== JSON.stringify(clonedProduct.value))
const added_quantity = ref(0)
const { show_unsaved_modal, isUnsaved, cancelLeavingPage, leavePage } = useUnsaved({ condition: computed(() => isProjectUpdated.value || isProductUpdated.value || added_quantity.value > 0) })

const isEnabled = computed(() => ['user'].includes(product.value.display_options))

const handleClickSave = () => {
  v$.value.$validate()
  if (v$.value.$invalid) return scrollTo('.input--errors')

  const promises = []
  if (isProjectUpdated.value) promises.push(patchProject({ id: props.projectSlug, params: { settings: { products_display_order: productsDisplayOrder.value } } }))
  if (isProductUpdated.value && !isCampaignProduct.value) promises.push(patchProduct({ id: props.productId, params: clonedProduct.value }))

  Promise.all(promises).then(() => {
    const promise = added_quantity.value ? postProductStock({ id: product.value.id, params: { added_quantity: added_quantity.value } }) : Promise.resolve()

    promise.then(() => {
      $resetStore('products')
      added_quantity.value = 0
      push({ name: 'PrivateAllProducts' })
    })
  })
}

const confirmDelete = () => deleteProduct({ id: props.productId })
  .then(() => {
    patchProject({ id: props.projectSlug, params: { settings: { products_display_order: productsDisplayOrder.value.filter(p => p.product_id !== props.productId) } } })
      .then(() => {
        $resetStore('products')
        push({ name: 'PrivateAllProducts' })
      })
  })

const isAllowed = computed(() => project.value ? (isSuperAdmin.value || isKoalect.value || project.value.isAuthorized(user.value)) : null)
watch(isAllowed, value => {
  if (value === false) push({ name: 'PrivateAllProducts' })
}, { immediate: true })
</script>

<style lang="scss" scoped>
.page {
  &__head {
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: rem(14px);

    @include mq(md) {
      font-size: rem(16px);
    }

    &:hover .icon__back {
      transform: translateX(-6px);
    }
  }
}

.icon {
  &__back {
    font-size: 20px;
    margin-right: 12px;
    transition: all .3s;
  }
}
</style>
