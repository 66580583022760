<template>
  <div>
    <div class="d-flex justify-content-center">
      <label
        class="input__label flex-grow-1 flex-shrink-1 margin__r--12"
        :for="id"
      >
        {{ translate(label) }} <span v-if="block.is_required">*</span>
      </label>

      <button
        class="filter flex-shrink-0 link__color--prim link--naked"
        @click="is_filter_open = !is_filter_open"
      >
        <fa-icon :icon="is_filter_open ? ['fas', 'filter'] : ['fal', 'filter']" />

        {{ t('literal.filter') }}
      </button>
    </div>

    <div
      class="tags d-flex align-items-center input--border input--radius margin__t--6"
      v-if="is_filter_open"
    >
      <fa-icon
        class="color--prim flex-shrink-0 margin__r--6 margin__l--12"
        :icon="['fal', 'filter']"
      />

      <input-select
        v-model="tags"
        class="flex-grow-1"
        :options="tagsOptions"
        :multiple="true"
        :searchable="true"
        :close-on-select="false"
        :reduce="tag => tag.label"
        @input="handleFetchBenefitings()"
      >
        <template #selected-option="{ option }">
          <span>{{ option.label }}</span>
        </template>

        <template #option="{ option }">
          <fa-icon
            class="margin__r--12"
            :class="{ 'color--prim': option.is_selected, 'color--black': !option.is_selected }"
            :icon="['fal', option.is_selected ? 'check-square' : 'square']"
            size="lg"
          />

          <span>{{ option.label }}</span>
        </template>
      </input-select>
    </div>

    <input-select
      class="margin__t--12"
      v-model="benefiting"
      :options="benefitings"
      :searchable="true"
      :clearable="true"
      :filterable="false"
      :reduce="benefiting => benefiting"
      :v="validator"
      @search="handleChangeSearch"
    >
      <template #selected-option="{ option }">
        <span>{{ option.name || selected_benefiting.name }}</span>
      </template>

      <template #option="{ option }">
        <span>{{ option.name }}</span>
      </template>

      <template #spinner="{ option }">
        <app-spinner
          size="3"
          v-if="option.loading"
        />
      </template>
    </input-select>
  </div>
</template>

<script setup>
import { ref, computed, inject, toRefs, onBeforeUnmount, defineAsyncComponent, onBeforeMount  } from 'vue'
import { storeToRefs } from 'pinia'
import { useBenefitingStore } from '@/stores/BenefitingStore'
import { useModule } from '@/composables/app/useModule'
import { useProcedure, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { omit as _omit } from 'lodash'

import InputSelect from '&/atoms/InputSelect'

const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const { t, translate } = useI18n()
const { getBenefitings, fetchBenefitings } = useBenefitingStore()
const { tags: allTags } = storeToRefs(useBenefitingStore())
const { id: procedureId } = useProcedure({ procedure })
const { label, model, id } = useModule({ component: block, parent: procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: model })

const is_filter_open = ref(false)
const timeout = ref(null)
const selected_benefiting = ref(null)
const query = ref({ tags: [], name: null, procedure_id: procedureId.value })

const { benefitings } = getBenefitings({ query })

const benefiting = computed({
  get: () => selected_benefiting.value,
  set: benefiting => {
    selected_benefiting.value = benefiting
    model.value = benefiting?.id
  }
})

const tags = computed({
  get: () => query.value.tags || [],
  set: tags => query.value.tags = tags.sort()
})

const tagsOptions = computed(() => allTags.value.map(tag => ({ label: tag, is_selected: tags.value.includes(tag) })))

const handleFetchBenefitings = () => new Promise(resolve => benefitings.value.length ? resolve() : fetchBenefitings({ query }).then(() => resolve()))
  .then(() => {
    if (model.value && !selected_benefiting.value) selected_benefiting.value = benefitings.value.find(({ id }) => id === model.value)
  })


const handleChangeSearch = (search, loading) => {
  clearTimeout(timeout.value)

  timeout.value = setTimeout(() => {
    loading(true)
    query.value.name = search

    handleFetchBenefitings().then(() => loading(false))
  }, 1000)
}

onBeforeMount(() => {
  handleFetchBenefitings().then(() => {
    if (!benefitings.value.length) {
      query.value = _omit(query.value, 'procedure_id')

      handleFetchBenefitings()
    }
  })
})

onBeforeUnmount(() => {
  if (!block.value.isVisible(procedure_execution)) model.value = null
})
</script>

<style lang="scss" scoped>
.filter {
  font-size: rem(14px);
}

.tags {
  :deep(.v-select) {
    border: 0;
    .vs__dropdown-option--selected {
      background-color: $white;
      color: $black;
    }
  }
}
</style>
