<template>
  <div class="home">
    <section
      class="banner"
      v-if="isVisible('banner')"
    >
      <div
        class="banner__img"
        :style="`background-image: url(${ $i18n.translate(banner.background) })`"
        v-if="$i18n.translate(banner.background) || $i18n.translate(banner.video)"
      >
        <video
          class="banner__video"
          width="1920"
          height="372"
          autoplay
          muted
          loop
          playsinline
          v-if="$i18n.translate(banner.video)"
        >
          <source
            :src="$i18n.translate(banner.video)"
            type="video/mp4"
          >
        </video>

        <div class="banner__container">
          <h1 class="banner__title">
            {{ $i18n.translate(banner.title) }}
          </h1>
        </div>
      </div>

      <fa-icon
        class="banner__arrow"
        :icon="['fal', 'arrow-down']"
      />
    </section>

    <section
      class="church"
      v-if="isVisible('quotes') || isVisible('stats')"
    >
      <blockquote
        class="quote quote--left"
        v-if="isVisible('quotes')"
      >
        <fa-icon
          class="quote__icon"
          :icon="['fal', 'quote-left']"
        />

        <img
          class="quote__img"
          :src="$i18n.translate(quotes.left.image)"
          :alt="`Picture of ${ $i18n.translate(quotes.left.name) }`"
        >

        <p
          class="quote__text editor editor__content"
          v-if="$i18n.translate(quotes.left.text)"
          v-html="$i18n.translate(quotes.left.text)"
        />

        <p class="quote__name">
          {{ $i18n.translate(quotes.left.name) }}
        </p>
      </blockquote>

      <div>
        <img
          class="quote__image"
          v-if="isVisible('quotes') && $i18n.translate(quotes.image)"
          :src="$i18n.translate(quotes.image)"
        >

        <div
          class="metrics"
          v-if="isVisible('stats')"
        >
          <ul
            class="metrics__ul"
            v-if="statistics.metrics && statistics.metrics.length"
          >
            <li
              class="metrics__li"
              v-for="(metric, index) in statistics.metrics"
              :key="`${metric.type}_${index}`"
            >
              <span class="metrics__label">{{ $i18n.translate(metric.text) }}</span>
              <strong class="metrics__value bold">
                <app-money
                  v-if="amount_type_stats.includes(metric.type)"
                  :amount="stats[metric.type]"
                />
                <span v-else>{{ stats[metric.type] }}</span>
              </strong>
            </li>
          </ul>

          <div class="progress-bar margin__t--36">
            <div
              class="progress-bar__current"
              :style="{ width: `${ stats['percentage'] }%` }"
            />
          </div>

          <div class="btn__container">
            <button
              v-if="statistics.cta && statistics.cta.show"
              @click.prevent="setRouteNavigation(statistics.cta)"
              class="btn translate__x3"
            >
              {{ $i18n.translate(statistics.cta.text) }}

              <fa-icon
                class="btn__icon element element"
                :icon="['fas', 'chevron-right']"
              />
            </button>
          </div>
        </div>
      </div>

      <blockquote
        class="quote quote--right"
        v-if="isVisible('quotes')"
      >
        <fa-icon
          class="quote__icon"
          :icon="['fal', 'quote-right']"
        />

        <img
          class="quote__img"
          :src="$i18n.translate(quotes.right.image)"
          :alt="`Picture of ${ $i18n.translate(quotes.right.name) }`"
        >

        <p
          class="quote__text editor editor__content"
          v-if="$i18n.translate(quotes.right.text)"
          v-html="$i18n.translate(quotes.right.text)"
        />

        <p class="quote__name">
          {{ $i18n.translate(quotes.right.name) }}
        </p>
      </blockquote>
    </section>

    <section
      class="events"
      v-if="isVisible('events') && events.items.length"
    >
      <h2 class="events__title">
        {{ $i18n.translate(events.title) }}
      </h2>

      <div class="container">
        <div class="events__grid">
          <article
            class="event"
            v-for="(event, index) in events.items"
            :key="`event_${index}`"
          >
            <div
              class="event__cover"
              :style="{ backgroundImage: `url(${ $i18n.translate(event.image) }), url(${ getImagePath('placeholders/project-card-cover.jpg') })` }"
            />

            <div class="event__infos">
              <h3
                class="title__section"
                v-if="$i18n.translate(event.title)"
              >
                {{ $i18n.translate(event.title) }}
              </h3>

              <p
                class="event__text editor editor__content"
                v-if="$i18n.translate(event.text)"
                v-html="$i18n.translate(event.text)"
              />

              <a
                class="event__link link__color--black translate__x3"
                @click.prevent="setRouteNavigation(event.link)"
              >
                {{ $i18n.translate(event.link.text) }}

                <fa-icon
                  class="event__chevron element"
                  :icon="['fal', 'chevron-right']"
                />
              </a>
            </div>
          </article>
        </div>
      </div>
    </section>

    <section
      class="projects"
      v-if="isVisible('projects')"
    >
      <h2 class="title__section">
        {{ $i18n.translate(projects.title) }}
      </h2>

      <section-projects-overview
        class="projects__items"
        :page="page"
        :section="projectSection"
      />

      <div class="btn__container">
        <button
          v-if="projects.cta && projects.cta.show"
          @click.prevent="setRouteNavigation(projects.cta)"
          class="btn translate__x3"
        >
          {{ $i18n.translate(projects.cta.text) }}

          <fa-icon
            class="btn__icon element element"
            :icon="['fas', 'chevron-right']"
          />
        </button>
      </div>
    </section>

    <section
      class="challenge"
      v-if="isVisible('challenge')"
    >
      <div class="container">
        <div class="challenge__grid">
          <article class="challenge__content">
            <h2 class="title__section">
              {{ $i18n.translate(challenge.title) }}
            </h2>

            <div
              class="editor editor__content challenge__text"
              v-html="$i18n.translate(challenge.text)"
            />
          </article>

          <article class="challenge__illu">
            <beers-kikirpa
              :section="challenge"
              :stats="stats"
            />
          </article>
        </div>
      </div>
    </section>

    <section
      class="capsule"
      v-if="isVisible('capsule')"
    >
      <div class="container">
        <div class="capsule__grid">
          <figure
            class="capsule__slider"
            ref="slider"
          >
            <template v-if="slides.length > 0">
              <div
                class="capsule__slides"
                :style="{ width: `${ clonedSlides.length * slider.width }px`, left: `${ -slider.width * ((clonedSlides.length - slides.length) / 2) }px`, transform: `translateX(${ -slider.index * slider.width }px)` }"
              >
                <div
                  class="capsule__slide"
                  v-for="(slide, index) in clonedSlides"
                  :style="{ backgroundImage: `url(${ $i18n.translate(slide.background) })`, width: `${slider.width}px` }"
                  :key="`modal_slide_${index}`"
                >
                  <h2
                    class="title__section slide__title"
                    v-if="$i18n.translate(slide.title)"
                  >
                    {{ $i18n.translate(slide.title) }}
                  </h2>

                  <div
                    class="editor editor__content slide__text"
                    v-html="$i18n.translate(slide.text)"
                    v-if="$i18n.translate(slide.text)"
                  />

                  <p
                    class="slide__amount"
                    v-if="$i18n.translate(slide.amount)"
                  >
                    {{ $i18n.translate(slide.amount) }}
                  </p>
                </div>
              </div>

              <nav
                class="capsule__nav"
                v-if="slides.length > 1"
              >
                <button
                  class="capsule--prev translate__x-3"
                  @click="goToPrev()"
                  aria-label="Go to prev slide"
                >
                  <fa-icon
                    class="element"
                    :icon="['fas', 'chevron-left']"
                    aria-hidden="true"
                  />
                </button>

                <button
                  class="capsule--next translate__x3"
                  @click="goToNext()"
                  aria-label="Go to next slide"
                >
                  <fa-icon
                    class="element"
                    :icon="['fas', 'chevron-right']"
                    aria-hidden="true"
                  />
                </button>
              </nav>
            </template>

            <fa-icon
              :icon="['far', 'image']"
              v-else
            />
          </figure>

          <article class="capsule__content">
            <h2 class="title__section">
              {{ $i18n.translate(capsule.title) }}
            </h2>

            <div
              class="editor editor__content capsule__text"
              v-html="$i18n.translate(capsule.text)"
            />

            <button
              v-if="capsule.cta && capsule.cta.show"
              @click.prevent="setRouteNavigation(capsule.cta)"
              class="btn translate__x3"
            >
              {{ $i18n.translate(capsule.cta.text) }}

              <fa-icon
                class="btn__icon element element"
                :icon="['fas', 'chevron-right']"
              />
            </button>
          </article>
        </div>
      </div>
    </section>

    <section
      class="timeline"
      v-if="isVisible('timeline')"
    >
      <h2 class="title__section">
        {{ $i18n.translate(timeline.title) }}
      </h2>

      <div class="container">
        <article class="timeline__news">
          <h3 class="timeline__title">
            {{ $i18n.translate(timeline.news.title) }}
          </h3>

          <div
            class="editor editor__content timeline__text"
            v-html="$i18n.translate(timeline.news.text)"
          />

          <a
            class="event__link link__color--black translate__x3 margin__t--6"
            @click.prevent="setRouteNavigation(timeline.news.cta)"
          >
            {{ $i18n.translate(timeline.news.cta.text) }}

            <fa-icon
              class="event__chevron element"
              :icon="['fal', 'chevron-right']"
            />
          </a>
        </article>

        <ol class="timeline__ol">
          <li class="timeline__li timeline__li--border-left timeline__li--bubble-top-left timeline__zomer2022">
            <h3 class="timeline__title">
              {{ $i18n.translate(timeline.zomer_2022.title) }}
            </h3>

            <div
              class="editor editor__content timeline__text"
              v-html="$i18n.translate(timeline.zomer_2022.text)"
            />

            <img
              class="timeline__img"
              :src="$i18n.translate(timeline.zomer_2022.image)"
              :alt="$i18n.translate(timeline.zomer_2022.title)"
            >
          </li>

          <li class="timeline__li timeline__li--border-left timeline__li--bubble-bottom-left timeline__herfst2022">
            <h3 class="timeline__title">
              {{ $i18n.translate(timeline.herfst_2022.title) }}
            </h3>

            <div
              class="editor editor__content timeline__text"
              v-html="$i18n.translate(timeline.herfst_2022.text)"
            />
          </li>

          <li class="timeline__li timeline__li--border-left timeline__li--bubble-top-left timeline__lente2023">
            <h3 class="timeline__title">
              {{ $i18n.translate(timeline.lente_2023.title) }}
            </h3>

            <div
              class="editor editor__content timeline__text"
              v-html="$i18n.translate(timeline.lente_2023.text)"
            />

            <img
              class="timeline__img"
              :src="$i18n.translate(timeline.lente_2023.image)"
              :alt="$i18n.translate(timeline.lente_2023.title)"
            >
          </li>

          <li class="timeline__li timeline__li--border-right timeline__li--bubble-bottom-right timeline__herfst2024">
            <h3 class="timeline__title">
              {{ $i18n.translate(timeline.herfst_2024.title) }}
            </h3>

            <div
              class="editor editor__content timeline__text"
              v-html="$i18n.translate(timeline.herfst_2024.text)"
            />

            <img
              class="timeline__img"
              :src="$i18n.translate(timeline.herfst_2024.image)"
              :alt="$i18n.translate(timeline.herfst_2024.title)"
            >
          </li>
        </ol>
      </div>
    </section>

    <section
      class="partners"
      v-if="isVisible('partners')"
    >
      <div class="container">
        <article class="partners__founding">
          <h2 class="title__section">
            {{ $i18n.translate(partners.founding.title) }}
          </h2>

          <ul class="partners__ul">
            <li
              class="partners__li"
              v-for="(image, index) in partners.founding.images"
              :key="`founding_${index}`"
            >
              <img
                class="partners__logo"
                :src="$i18n.translate(image.logo)"
              >
            </li>
          </ul>
        </article>

        <article class="partners__marketing">
          <h2 class="title__section">
            {{ $i18n.translate(partners.marketing.title) }}
          </h2>

          <ul class="partners__ul">
            <li
              class="partners__li"
              v-for="(image, index) in partners.marketing.images"
              :key="`founding_${index}`"
            >
              <img
                class="partners__logo"
                :src="$i18n.translate(image.logo)"
              >
            </li>
          </ul>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'
import BeersKikirpa from '&/modules/pages/kikirpa/BeersKikirpa'
import SectionProjectsOverview from '&/modules/pages/default/sections/SectionProjectsOverview'
import PageRouteNavigation from '%/PageRouteNavigation'
import { getImagePath } from '@/utils/utils'
import useSection from '@/composables/modules/pages/useSection'
import { get as _get } from 'lodash'

export default {
  name: 'CustomHomepageKikirpa',
  components: {
    SectionProjectsOverview,
    BeersKikirpa,
    AppMoney: defineAsyncComponent(() => import('&/atoms/AppMoney'))
  },
  setup: props => {
    const { getSection, getSectionAttributes, isVisible } = useSection(props.page)

    return { getImagePath, getSection, getSectionAttributes, isVisible }
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      slider: {
        index: 0,
        width: 625,
        is_animating: false
      },
      amount_type_stats: ['amount_collected', 'amount_left_to_collect', 'goal_amount'],
      stats: {}
    }
  },
  created () {
    this.fetchClientStatistics({ query: this.filteredStats })
      .then(stats => this.stats = {
        ...stats,
        amount_left_to_collect: _get(this.statistics, 'goal', 0) - stats.amount_collected,
        goal_amount: _get(this.statistics, 'goal', 0),
        percentage: parseInt(_get(this.statistics, 'goal') ? (stats.amount_collected / _get(this.statistics, 'goal')) * 100 : 0)
      })
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(() => this.slider.width = this.$refs.slider.clientWidth)
  },
  mixins: [
    PageRouteNavigation
  ],
  computed: {
    attributes () {
      return _get(this.section, 'attributes', {})
    },
    banner () {
      return this.getSectionAttributes('banner')
    },
    quotes () {
      return this.getSectionAttributes('quotes')
    },
    statistics () {
      return this.getSectionAttributes('stats')
    },
    filteredStats () {
      return this.statistics ? { campaign_id: this.statistics.campaign_id, procedure_id: this.statistics.procedure_id } : {}
    },
    events () {
      return this.getSectionAttributes('events')
    },
    projects () {
      return this.getSectionAttributes('projects')
    },
    challenge () {
      return this.getSectionAttributes('challenge')
    },
    capsule () {
      return this.getSectionAttributes('capsule')
    },
    timeline () {
      return this.getSectionAttributes('timeline')
    },
    partners () {
      return this.getSectionAttributes('partners')
    },
    slides () {
      return _get(this.capsule, 'slides', [])
    },
    clonedSlides () {
      const slides = [...this.slides]

      slides.unshift(this.slides[this.slides.length - 1])
      slides.push(this.slides[0])

      return slides
    },
    projectSection () {
      return this.getSection('projects')
    }
  },
  methods: {
    ...mapActions({
      fetchClientStatistics: 'client/fetchClientStatistics'
    }),
    handleResize () {
      if (this.$refs.slider && this.slides.length) this.slider.width = this.$refs.slider.clientWidth
    },
    goToPrev () {
      if (this.slider.is_animating) return

      this.slider.is_animating = true

      if (this.slider.index <= 0) {
        this.slider.index = this.slides.length

        setTimeout(() => {
          this.slider.is_animating = false
          this.goToPrev()
        })
      } else {
        this.slider.index--

        setTimeout(() => this.slider.is_animating = false, 300)
      }
    },
    goToNext () {
      if (this.slider.is_animating) return

      this.slider.is_animating = true

      if (this.slider.index >= this.slides.length - 1) {
        this.slider.index = -1

        setTimeout(() => {
          this.slider.is_animating = false
          this.goToNext()
        })
      } else {
        this.slider.index++

        setTimeout(() => this.slider.is_animating = false, 300)
      }
    }
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
.title {
  &__section {
    font-size: rem(36px);
    margin-bottom: 48px;
    text-align: center;
    font-family: $font-family__secondary;
    color: #B58C4B;

    @include mq(sm) {
      font-size: rem(48px);
      margin-bottom: 48px;
    }
  }
}

.banner {
  &__img {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 250px;

    @include mq(sm) {
      height: 372px;
    }

    @include mq(md) {
      @include aspect-ratio(1440, 372);
      height: auto;
    }
  }

  &__arrow {
    font-size: 38px;
    color: #979797;
    display: block;
    margin: auto;
    margin-top: 70px;

    @include mq(sm) {
      margin-top: 140px;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
  }

  &__container {
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: auto;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 24px;

    @include mq(sm) {
      top: auto;
      bottom: 0px;
    }

    @media (min-width: 1500px) {
      width: 1200px;
      bottom: -96px;
    }
  }

  &__title {
    font-size: 48px;
    font-family: $font-family__secondary;
    display: block;
    color: #DCDCDC;
    line-height: 1.2;

    @include mq(sm) {
      font-size: 80px;
    }

    @media (min-width: 1500px) {
      font-size: 132px;
    }
  }
}

.church {
  padding: 60px 0 45px 0;
  display: block;

  @media (min-width: 850px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1260px) {
    padding: 60px 0 72px 0;
    align-items: flex-start;
    flex-direction: row;
  }

  &__svg {
    @media (min-width: 850px) {
      min-width: 840px;
    }
  }
}

.quote {
  position: relative;
  max-width: 280px;
  margin: auto;
  padding: 12px;

  &__img {
    border-radius: 50%;
    margin-bottom: 24px;
    display: inline-block;
  }

  &__image {
    display: block;
    max-width: 100%;
  }

  &__text {
    font-family: $font-family--secondary;
    color: $color--primary;
    margin-bottom: 24px;
  }

  &__name {
    text-align: right;
    font-size: rem(18px);
    color: #979797;
  }

  &__icon {
    position: absolute;
    font-size: 120px;
    top: 30%;
    color: #E3E3E3;
  }

  &--right {
    @media (min-width: 1260px) {
      align-self: flex-end;
      margin-bottom: 86px;
      text-align: right;
    }

    .quote__icon {
      left: calc(100% - 24px);
    }
  }

  &--left {
    @media (min-width: 1260px) {
      margin-top: 86px;
    }

    .quote__icon {
      right: calc(100% - 24px);
    }
  }
}

.metrics {
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 0 30px;
  min-width: 100%;

  @include mq(lg) {
    padding: 0;
    margin-bottom: 0px;
    min-width: 700px;
  }

  &__ul {
    display: inline-flex;
    flex-direction: column;
    gap: 12px;

    @include mq(md) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 0;
    }
  }

  &__label {
    color: #979797;
    font-size: rem(18px);
    display: block;
  }

  &__value {
    font-size: rem(25px);
    color: #B58C4B;
    font-family: $font-family__secondary;
  }
}

.progress-bar {
  width: 100%;
  height: 18px;
  position: relative;
  background: rgba(151, 151, 151, 0.25);

  &__current {
    position: relative;
    background: #B58C4B;
    height: 100%;
    max-width: 100%;
    text-align: right;
  }
}

.events {
  background-color: #EFEFEF;
  padding: 0px 0 60px 0;
  position: relative;

  @include mq(lg) {
    padding: 188px 0 144px 0;
  }

  &__title {
    font-size: 33vw;
    line-height: 1;
    text-align: center;
    color: rgba(#FFFFFF, .48);
    white-space: nowrap;
    font-family: $font-family--secondary;
    pointer-events: none;

    @include mq(lg) {
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      line-height: 0;
      font-size: 350px;
    }
  }

  &__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }
}

.event {
  width: 100%;
  background-color: $white;

  @include mq(sm) {
    max-width: 408px;
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include aspect-ratio(408, 228);
  }

  &__infos {
    position: relative;
    padding: 28px 18px;
    color: $black;

    .title__section {
      font-size: rem(28px);
      color: #404040;
      margin-bottom: 18px;
      text-align: left;
      line-height: 1.2;
    }
  }

  &__text {
    margin-bottom: 12px;
  }

  &__chevron {
    font-size: 12px;
    margin-left: 6px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}

.projects {
  padding-top: 60px;
  background-color: $white;

  @include mq(sm) {
    padding-top: 120px;
  }

  &__btn-container {
    text-align: center;
    margin-top: 48px;
  }

  & :deep(.card) {
    border-radius: 0;
    box-shadow: none;

    .card__cover {
      border-radius: 0;
    }

    .infos__campaign , .goal__stats, .goal__current-amount {
      color: #131415;
      font-size: 14px;
    }

    .fa-flag, .fa-users, .fa-clock {
      color: #C44615;
    }
  }
}

.challenge {
  background-color: $white;
  padding-top: 60px;

  @include mq(lg) {
    padding-top: 109px;
  }

  .title__section {
    text-align: left;
    margin-bottom: 24px;
  }

  &__content {
    @include mq(lg) {
      padding-right: 80px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 48px;

    @include mq(lg) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  &__text {
    font-size: rem(16px);
    font-family: $font-family--secondary;
  }
}

.capsule {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $white;

  @include mq(md) {
    padding-top: 120px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;

    @include mq(lg) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  &__slider {
    background: $color--primary;
    position: relative;
    overflow: hidden;
    @include aspect-ratio(625, 1000);

    @include mq(sm) {
      @include aspect-ratio(625, 579);
    }
  }

  &__slides {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: .3s ease-in-out;
  }

  &__slide {
    position: relative;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 34px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include mq(sm) {
      padding: 54px;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    font-size: rem(18px);
    color: $white;
    line-height: 1;
    z-index: 10;

    svg {
      transition: .2s all;
    }
  }

  &--prev, &--next {
    background: #131415;
    padding: 10px 26px;
  }

  &--prev {
    margin-right: 2px;
  }

  &__content {
    padding: 54px;

    .title__section {
      text-align: left;
      margin-bottom: 24px;
    }
  }

  &__text {
    font-size: rem(16px);
    font-family: $font-family--secondary;
    margin-bottom: 48px;
  }
}

.slide {
  &__title {
    color: #131415;
    margin-bottom: 24px;
  }

  &__text {
    font-size: rem(16px);
    text-align: center;
    margin-bottom: 30px;

    @include mq(sm) {
      font-size: rem(20px);
    }
  }

  &__amount {
    font-size: rem(36px);
    color: $color--primary;
    font-family: $font-family--secondary;
    text-align: center;

    @include mq(sm) {
      font-size: rem(48px);
    }
  }
}

.timeline {
  background-color: #EFEFEF;
  padding-bottom: 60px;
  position: relative;

  @include mq(lg) {
    padding-bottom: 223px;
  }

  & .title__section {
    font-size: 14vw;
    color: rgba(#FFFFFF, .48);
    line-height: 0.8;
    margin: 0;
    position: relative;
    margin-bottom: 24px;

    @include mq(lg) {
      font-size: 250px;
      top: -40px;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: rem(22px);
    color: $color--primary;
    margin-bottom: 6px;
    font-family: $font-family--secondary;
  }

  &__text {
    font-size: rem(14px);
  }

  &__img {
    display: block;
    margin-top: 12px;

    @include mq(lg) {
      margin-top: 0px;
    }
  }

  &__news {
    width: 90%;
    margin: 0 auto 24px auto;
    background-color: #FFFFFF;
    padding: 24px;
    position: relative;

    @include mq(lg) {
      width: 300px;
      left: 300px;
      margin: 0;
    }
    @include mq(xl) {
      left: 350px;
    }
  }

  &__ol {
    border-left: 2px solid #D2D2D2;
    width: 100%;
    position: relative;
    margin-left: 24px;

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #D2D2D2;
      background-color: #EFEFEF;
      position: absolute;
      top: 0;
      left: -12px;
    }

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #D2D2D2;
      background-color: #EFEFEF;
      position: absolute;
      bottom: 0px;
      left: -12px;
    }

    @include mq(lg) {
      border-top: 2px solid #D2D2D2;
      margin: 12px 0 274px 0;

      &:before, &:after {
        display: none;
      }
    }
  }

  &__li {
    padding: 12px 24px;

    @include mq(lg) {
      max-width: 236px;
      position: absolute;
      padding: 0 24px;
    }

    &--border-left {
      @include mq(lg) {
        border-left: 2px solid #D2D2D2;
      }
    }

    &--border-right {
      @include mq(lg) {
        border-right: 2px solid #D2D2D2;
      }
    }

    &--bubble {
      @include mq(lg) {
        &:before {
          content: '';
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid #D2D2D2;
          background-color: #EFEFEF;
          position: absolute;
        }
      }
    }

    &--bubble-top-left {
      @extend .timeline__li--bubble;
      @include mq(lg) {
        &:before {
          left: -12px;
          top: 0px;
        }
      }
    }

    &--bubble-bottom-left {
      @extend .timeline__li--bubble;
      @include mq(lg) {
        &:before {
          left: -12px;
          bottom: 0px;
        }
      }
    }

    &--bubble-bottom-right {
      @extend .timeline__li--bubble;
      @include mq(lg) {
        &:before {
          right: -12px;
          bottom: 0px;
        }
      }
    }

    &.timeline__zomer2022 {
      @include mq(lg) {
        bottom: 100%;
        left: 80px;
        padding-bottom: 46px;
      }

      .timeline__img {
        @include mq(lg) {
          position: absolute;
          top: 100%;
          left: -80px;
        }
      }
    }

    &.timeline__herfst2022 {
      @include mq(lg) {
        padding-top: 46px;
        top: 100%;
        left: 204px;
      }
    }

    &.timeline__lente2023 {
      @include mq(lg) {
        bottom: 100%;
        right: 230px;
        padding-bottom: 46px;
      }

      .timeline__img {
        @include mq(lg) {
          position: absolute;
          top: 100%;
          right: 100%;
        }
      }
    }

    &.timeline__herfst2024 {
      @include mq(lg) {
        text-align: right;
        top: 100%;
        right: 50px;
        padding-top: 46px;
      }

      .timeline__img {
        @include mq(lg) {
          position: absolute;
          bottom: 100%;
          right: -50px;
        }
      }
    }
  }
}

.partners {
  background-color: $white;
  padding: 96px 0;

  .title__section {
    font-size: rem(36px);
    color: #131415;
  }

  &__ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  &__logo {
    display: block;
  }

  &__founding {
     padding-bottom: 30px;
     border-bottom: 1px solid #D2D2D2;

    .title__section {
      margin-bottom: 24px;
    }

    .partners__logo {
      max-width: 250px;
    }
  }

  &__marketing {
    margin-top: 24px;

    .title__section {
      margin-bottom: 12px;
    }

    .partners__logo {
      max-width: 150px;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  background: $color--primary;
  padding: 12px 18px;
  line-height: 1;
  font-size: rem(18px);
  color: $white;
  transition: all .3s;
  border-radius: 0;

  &:hover {
    background-color: shade($color--primary, 5%);
  }

  &__icon {
    font-size: 12px;
    margin-left: 6px;
  }

  &__container {
    justify-content: center;
    margin-top: 48px;
  }
}
</style>
