<template>
  <div v-if="isShowed('button') && procedure">
    <router-link
      :to="{ name: 'ClientProcedure', params: { procedureSlug: procedure.slug } }"
      id="donate"
      class="btn btn__color--prim btn__size--full-12 align--center"
      v-if="isOngoing && isApproved && !isPreview && !isExecutionLimitReached"
    >
      {{ translate(getText('ongoing')) || t('literal.donate') }}
    </router-link>

    <button
      class="btn btn__color--prim btn__size--full-12"
      :disabled="true"
      v-else
    >
      <template v-if="isExecutionLimitReached">
        {{ translate(getText('limited_to_one_contribution')) || t('literal.already_contributed') }}
      </template>

      <template v-else-if="isOngoing">
        {{ translate(getText('ongoing')) || t('literal.donate') }}
      </template>

      <template v-else-if="isFuture(startDate)">
        {{ translate(getText('not_started_yet')) || t('project_page.btn_donate_not_started_yet') }}
      </template>

      <template v-else-if="isPast(startDate)">
        {{ translate(getText('finished')) || t('project_page.btn_donate_finished') }}
      </template>
    </button>

    <p
      class="info align--center color--lg margin__t--6"
      v-if="isFuture(startDate)"
    >
      {{ tc('plural.starts_in_x_day', days, { daysNumber: days }) }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { useProcedureRestrictions } from '@/composables/modules/procedures'
import { isPast, isFuture, differenceInCalendarDays } from 'date-fns'
import { get as _get } from 'lodash'

const props = defineProps({ block: { type: Object, required: true } })

const { t, translate, tc } = useI18n()
const { objects, getText, isPreview, isShowed } = useBlock(props.block)

const procedure = computed(() => _get(objects.value, 'project.procedures', []).find(procedure => procedure.category === 'donation'))
const { isExecutionLimitReached, fetchProcedureRestrictions } = useProcedureRestrictions({ procedure })
const startDate = computed(() => new Date(procedure.value.start_date))
const endDate = computed(() => procedure.value.end_date ? new Date(procedure.value.end_date) : null)
const days = computed(() => differenceInCalendarDays(startDate.value, new Date()))
const isOngoing = computed(() => isPast(startDate.value) && (!endDate.value || isFuture(endDate.value)))
const isApproved = computed(() => _get(objects.value, 'project.status') !== 'need_approval')

fetchProcedureRestrictions({ show_modal: false })
</script>

<style lang="scss" scoped>
.info {
  font-size: rem(14px);
}
</style>