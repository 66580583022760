<template>
  <input
    type="text"
    class="input input--border input--radius"
    :value="nationalId"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    @blur="handleBlur()"
    v-bind="$attrs"
    ref="input"
  >

  <div
    class="input--errors"
    v-if="v && v.$error"
  >
    <span
      class="input--error"
      v-if="v.required?.$invalid"
    >
      {{ t('errors.input_text_required') }}
    </span>

    <span
      class="input--error"
      v-if="v.minLength?.$invalid"
    >
      {{ t('errors.input_national_id_invalid') }}
    </span>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from '@/vendors/i18n'
import Cleave from 'cleave.js'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  id: String,
  name: { type: String, default: 'input-national-id' },
  placeholder: { type: String, default: '...' },
  modelValue: String,
  disabled: { type: Boolean, default: false },
  delimiters: { type: Array, default: () => ['.', '.', '-', '.'] },
  blocks: { type: Array, default: () => [2, 2, 2, 3, 2] },
  v: Object
})

const { t } = useI18n()

const cleave = ref()
const input = ref()
const formattedInput = ref(props.modelValue)

const { name, id, placeholder, disabled, delimiters, blocks, v } = toRefs(props)

const nationalId = computed({
  get: () => formattedInput.value,
  set: value => emit('update:modelValue', value)
})

const handleBlur = () => {
  if (v.value) v.value.$touch()
}

onMounted(() => {
  cleave.value = new Cleave(input.value, {
    delimiters: delimiters.value,
    blocks: blocks.value,
    numericOnly: true,
    onValueChanged: ({ target }) => {
      formattedInput.value = target.value
      nationalId.value = target.rawValue
    }
  })
})

onBeforeUnmount(() => {
  if (!cleave.value) return
  cleave.value.destroy()
  cleave.value = null
})
</script>
