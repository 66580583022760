<template>
  <form
    class="donate"
    v-if="(isShowed('donate_field'))"
  >
    <label
      class="donate__label bold"
      for="amount"
      v-if="(isShowed('text_donate_field'))"
    >
      {{ label }}
    </label>

    <input-amount
      class="donate__input"
      id="amount"
      v-model="amount"
    />

    <app-save-button
      class="donate__button margin__t--12"
      :show-icon="false"
      :is-disabled="!isOngoing || isPreview"
      @click="handleClickDonate"
    >
      {{ labelButton }}
    </app-save-button>
  </form>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useRouter } from 'vue-router'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'
import { isPast, isFuture } from 'date-fns'

import InputAmount from '&/atoms/InputAmount'
import AppSaveButton from '&/molecules/AppSaveButton'

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { push } = useRouter()
const { objects, isShowed, getText, isPreview } = useBlock(props.block)

const amount = ref(0)
const procedure = computed(() => _get(objects.value, 'project.procedures', []).find(procedure => procedure.category === 'donation'))
const startDate = computed(() => new Date(procedure.value.start_date))
const endDate = computed(() => procedure.value.end_date ? new Date(procedure.value.end_date) : null)
const label = computed(() => translate(getText('donate_field')))
const labelButton = computed(() => translate(getText('donate_button')))
const isOngoing = computed(() => isPast(startDate.value) && (!endDate.value || isFuture(endDate.value)))

const handleClickDonate = () => push({ name: 'ClientProcedure', params: { procedureSlug: procedure.value.slug }, query: { ['actions_data[transaction][amount]']: parseInt(amount.value) } })
</script>

<style lang="scss" scoped>
.donate {
  border: 1px solid rgba(#2E293D, .1);
  border-radius: 4px;
  padding: 18px;

  &__input :deep(.input) {
    background-color: transparent;
  }

  &__label {
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
  }

  &__button {
    width: 100%;
    justify-content: center;
    border-radius: 4px;
  }
}
</style>
