<template>
  <li class="contribution">
    <user-profile-img
      class="contribution__img"
      :size="4"
      :avatar="contribution.customer.avatar"
    />

    <div class="contribution__cell">
      <div class="contribution__infos">
        <div>
          <p
            class="infos__name"
            v-if="contribution.customer.is_anonymous"
          >
            {{ $i18n.t('literal.anonymous') }}
          </p>

          <p
            class="infos__name"
            v-else
          >
            {{ contribution.customer.firstname }} {{ contribution.customer.lastname }}
          </p>

          <p>
            <app-money
              class="infos__amount"
              :amount="transaction.amount"
              v-if="transaction"
            />

            <app-date
              class="infos__date"
              :timestamp="contribution.created_at"
            />
          </p>
        </div>

        <div
          class="contribution__actions"
          v-if="isMessageKeyAvailable"
        >
          <button
            type="button"
            class="action__button"
            v-if="isModerated && isModerator && hasContent && !is_edited"
            @click.prevent="undoModerateMessage()"
          >
            <fa-icon
              :icon="['fal', 'eye']"
              fixed-width
            />
          </button>

          <button
            type="button"
            class="action__button"
            v-if="!isModerated && isModerator && hasContent && !is_edited"
            @click.prevent="doModerateMessage()"
          >
            <fa-icon
              :icon="['fal', 'eye-slash']"
              fixed-width
            />
          </button>

          <button
            type="button"
            class="action__button"
            v-if="!isModerated && !is_edited && isAuthUser"
            @click.prevent="is_edited = true"
          >
            <fa-icon
              :icon="['fal', 'edit']"
              fixed-width
            />
          </button>

          <button
            type="button"
            class="action__button color--success"
            v-if="is_edited && isAuthUser"
            @click.prevent="save()"
          >
            <fa-icon
              :icon="['fal', 'save']"
              fixed-width
            />
          </button>
        </div>
      </div>

      <p
        class="infos__message margin__t--6"
        v-if="!isModerated && !is_edited"
      >
        {{ message }}
      </p>

      <p
        class="margin__t--6 color--vlg"
        v-if="isModerated && !is_edited && (isAuthUser || isModerator)"
      >
        {{ $i18n.t('public_contribution.moderated_message') }}
      </p>

      <input-textarea
        class="textarea margin__t--6"
        v-model="message"
        v-if="is_edited && isAuthUser"
      />
    </div>
  </li>
</template>

<script setup>
import { defineAsyncComponent, ref, computed } from 'vue'
import { mapGetters, mapActions } from '@/store/map-state'
import { get as _get } from 'lodash'
import { useRoles } from '@/composables/app/useRoles'

import AppMoney from '&/atoms/AppMoney'
import AppDate from '&/atoms/AppDate'
import UserProfileImg from '&/atoms/UserProfileImg'
const InputTextarea = defineAsyncComponent(() => import('&/atoms/InputTextarea'))

const props = defineProps({
  contribution: {
    required: true,
    type: Object
  }
})

const regex_moderation = /^(\*\*\/)[\s\S]+(\/\*\*)$/g
const is_edited = ref(false)
const edited_message = ref(_get(props.contribution, 'informations.message', null))

const { isKoalect, isSuperAdmin } = useRoles()
const { user, isAuthenticated } = mapGetters('auth')
const { patchProcedureInformations } = mapActions('procedures')

const isAuthUser = computed(() =>  isAuthenticated.value && user.value.id === props.contribution.user_id)
const isModerator = computed(() => isKoalect.value || isSuperAdmin.value)
const isMessageKeyAvailable = computed(() => 'message' in _get(props.contribution, 'informations', {}))
const isModerated = computed(() => regex_moderation.test(message.value))
const messageLength = computed(() => message.value ? message.value.length : 0)
const hasContent = computed(() => message.value && messageLength.value > 0)
const transaction = computed(() => _get(props.contribution, 'objects.transaction', null))

const message = computed({
  get: () => edited_message.value,
  set: value => edited_message.value = value
})

const doModerateMessage = () => {
  if (hasContent.value && message.value.substring(0, 3) !== '**/' && message.value.substring(messageLength.value - 3, messageLength.value) !== '/**') {
    message.value = '**/' + message.value + '/**'
    save()
  }
}

const undoModerateMessage = () => {
  if (hasContent.value && message.value.substring(0, 3) === '**/' && message.value.substring(messageLength.value - 3, messageLength.value) === '/**') {
    message.value = message.value.slice(3, messageLength.value - 3)
    save()
  }
}

const save = () => {
  patchProcedureInformations({ slug: props.contribution.procedure_id, id: props.contribution.id, params: { ...props.contribution.informations, message: message.value } })
    .then(() => is_edited.value = false)
}
</script>

<style lang="scss" scoped>
.contribution {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__img {
    display: block;
    border: 3px solid $vdw;
    border-radius: 50%;
    flex: 0 0 auto;
  }

  &__cell {
    width: 100%;
  }

  &__infos {
    margin-left: $margin__base;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}

.action {
  &__button {
    padding: 5px;
    display: block;
  }
}

.textarea :deep(.input) {
  background: transparent;
  border-radius: 5px;
  resize: vertical;
  min-height: 70px;
}

.infos {
  &__name {
    font-weight: bold;
    font-size: em(14px);
  }

  &__date {
    color: $lg;
  }

  &__amount {
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 4px;
      height: 4px;
      background: $lg;
      border-radius: 50%;
      margin: 0 $margin__base;
    }
  }

  &__message {
    word-wrap: break-word;
  }
}
</style>
