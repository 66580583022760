<template>
  <div v-if="isShowed('text_contributions') || isShowed('contributions_list')">
    <p
      v-if="isShowed('text_contributions')"
      v-html="plural(getText('contributions'), contributionCount, variables)"
    />

    <template v-if="isShowed('contributions_list')">
      <template v-if="contributions.length">
        <ul class="contributions__ul margin__t--24">
          <public-contribution
            v-for="contribution in contributions"
            class="contributions__li"
            :contribution="contribution"
            :key="contribution.id"
          />
        </ul>

        <app-pagination
          class="margin__t--24"
          :paginator="pagination"
          @change="page => query.page = page"
        />
      </template>

      <div
        class="d-flex justify-content-center margin__t--12"
        v-if="loader"
      >
        <app-spinner />
      </div>

      <p
        class="margin__t--24 color--lg"
        v-if="!contributions.length && !loader"
      >
        {{ translate(getText('no_contributions')) || t('literal.no_contributions_yet') }}
      </p>
    </template>
  </div>
</template>

<script setup>
import { reactive, computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useSampleStore } from '@/stores/SampleStore'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'

const PublicContribution = defineAsyncComponent(() => import('&/modules/project/PublicContribution'))
const AppSpinner  = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const AppPagination = defineAsyncComponent(() => import('&/molecules/AppPagination'))

const props = defineProps({ block: { type: Object, required: true } })

const route = useRoute()
const { t, translate, plural } = useI18n()
const { getPublicProcedureExecutions } = useProjectStore()
const { getSamples } = useSampleStore()
const { objects, isPreview, isShowed, getText } = useBlock(props.block)

const query = reactive({ id: route.params.projectSlug, per_page: 10, page: 1, 'sort_by_field[field]': 'created_at', 'sort_by_field[direction]': 'desc' })

const { publicProcedureExecutions, samples, loader, pagination } = isPreview.value ? getSamples({ id: 'public_procedure_executions', query: { key: 'public_procedure_executions', ...query } }) : getPublicProcedureExecutions({ id: route.params.projectSlug, query })

const statistics = computed(() => _get(objects.value, 'statistics', {}))
const contributionCount = computed(() => _get(statistics.value, 'contributions_count', 0))
const contributions = computed(() => isPreview.value ? samples.value : publicProcedureExecutions.value)
const variables = computed(() => ({ number_of_contributions: `<strong class="bold color--prim">${contributionCount.value}</strong>` }))
</script>

<style lang="scss" scoped>
.contributions {
  &__li {
    &:not(:first-child) {
      margin-top: $margin__base;
    }
  }
}
</style>