<template>
  <article
    class="update"
    v-if="update"
    :class="{'card margin__b--18': is_editing }"
    :id="update.id"
  >
    <div class="padding--18">
      <form-notification
        class="margin__b--12"
        :keys="errors"
      />

      <header class="update__header margin__b--18">
        <div class="update__infos">
          <app-date
            class="update__date color--lg"
            :timestamp="update.created_at"
            :filter="dateFormat"
          />

          <ul class="status__ul">
            <li
              class="status__li"
              :title="t('literal.public')"
              v-if="isPublic && allowEdit"
            >
              {{ t('literal.public') }}
            </li>

            <li
              class="status__li"
              :title="t('literal.private')"
              v-if="!isPublic && allowEdit"
            >
              <app-tooltip
                placement="top"
                :triggers="['hover', 'click']"
              >
                <span>{{ t('literal.private') }}</span>

                <template #popper>
                  {{ t('literal.private_means_only_project_member_can_see_this_update') }}
                </template>
              </app-tooltip>
            </li>

            <li
              class="status__li"
              :title="t('literal.sent_by_email')"
              v-if="allowEdit && update.notify_by_email"
            >
              {{ t('literal.sent_by_email') }}
            </li>

            <li
              class="status__li"
              :title="t('literal.edited')"
              v-if="isEdited"
            >
              {{ t('literal.edited') }}
            </li>
          </ul>
        </div>

        <div
          class="members__actions"
          v-if="allowEdit"
        >
          <app-dropdown
            placement="bottom-end"
            :triggers="['click']"
            :disabled="loader.save || deleteLoader || is_editing"
          >
            <button
              class="popover__menu"
              :class="{ 'popover--disabled': loader.save || deleteLoader || is_editing }"
            >
              <span class="popover__span" />
              <span class="popover__span" />
              <span class="popover__span" />
            </button>

            <template #popper>
              <ul>
                <li class="popover__li">
                  <button
                    type="button"
                    class="popover__button"
                    :disabled="is_editing"
                    @click="is_editing = true"
                  >
                    {{ t('literal.edit') }}
                  </button>
                </li>

                <li
                  class="popover__li"
                  v-if="!isPublic"
                >
                  <button
                    type="button"
                    class="popover__button"
                    :disabled="loader"
                    @click="handleClickPatchUpdate({ is_public: true })"
                  >
                    <app-spinner
                      class="margin__r--6"
                      v-if="loader"
                      size="2"
                    />
                    {{ t('literal.make_public') }}
                  </button>
                </li>

                <li
                  class="popover__li"
                  v-if="isPublic"
                  @click="handleClickPatchUpdate({ is_public: false })"
                >
                  <button
                    type="button"
                    class="popover__button"
                    :disabled="loader"
                  >
                    <app-spinner
                      class="margin__r--6"
                      v-if="loader"
                      size="2"
                    />

                    {{ t('literal.make_private') }}
                  </button>
                </li>

                <li class="popover__li">
                  <button
                    type="button"
                    class="popover__button"
                    :disabled="loader || deleteLoader"
                    @click="openModal"
                  >
                    <app-spinner
                      class="margin__r--6"
                      v-if="deleteLoader"
                      size="2"
                    />

                    {{ t('literal.delete_update') }}
                  </button>
                </li>
              </ul>
            </template>
          </app-dropdown>
        </div>
      </header>

      <div
        class="editor"
        v-if="allowEdit && is_editing"
      >
        <app-text-editor
          v-model="cloned_update.content"
          :inline-style="true"
          :config="['bold', 'italic', 'underline', 'heading_h1', 'heading_h2', 'list_ul', 'list_ol', 'image', 'link', 'video', 'code', 'align_left', 'align_center', 'align_right', 'text_color']"
          :v="v$.content"
        />

        <button
          class="btn btn__container btn__size--small btn__color--prim margin__t--24"
          name="publish"
          :disabled="loader || fileLoader"
          @click.prevent="handleClickPatchUpdate({ content: cloned_update.content })"
        >
          <fa-icon
            v-if="!loader"
            class="margin__r--6"
            size="sm"
            :icon="['fal', 'save']"
            fixed-width
          />

          <app-spinner
            size="2"
            class="margin__r--6"
            v-else
          />

          {{ t('literal.save') }}
        </button>
      </div>

      <div
        class="editor__content"
        v-else
        v-html="cloned_update.content"
      />
    </div>

    <modal-confirm
      @close="closeModal"
      @confirm="handleDeleteUpdate()"
      v-if="show_modal"
    >
      <template #confirm-content>
        <div class="align--center">
          <p>{{ t('confirm_modale.project_remove_update') }}</p>
        </div>
      </template>
    </modal-confirm>
  </article>
</template>

<script setup>
import { ref, computed, toRefs, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useUpdateStore } from '@/stores/UpdateStore'
import { useFileStore } from '@/stores/FileStore'
import { useI18n } from '@/vendors/i18n'
import { useModals } from '@/composables/app/useModals'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { differenceInHours } from 'date-fns'

import AppTextEditor from '&/organisms/AppTextEditor'
import AppDate from '&/atoms/AppDate'

const AppDropdown = defineAsyncComponent(() => import('@/components/atoms/AppDropdown'))
const AppSpinner = defineAsyncComponent(() => import('@/components/atoms/AppSpinner'))
const AppTooltip = defineAsyncComponent(() => import('@/components/atoms/AppTooltip'))
const FormNotification = defineAsyncComponent(() => import('@/components/molecules/FormNotification'))
const ModalConfirm = defineAsyncComponent(() => import('@/components/organisms/ModalConfirm'))

const emit = defineEmits(['updated'])

const props = defineProps({
  update: { type: Object, required: true },
  allowEdit: { type: Boolean, default: false }
})

const { update, allowEdit } = toRefs(props)

const { t } = useI18n()
const { show_modal, openModal, closeModal } = useModals()
const { patchUpdate, deleteUpdate } = useUpdateStore()
const { loader: deleteLoader, error: deleteError } = storeToRefs(useUpdateStore())
const { loader: fileLoader } = storeToRefs(useFileStore())

const loader = ref(false)
const error = ref(null)
const is_editing = ref(false)
const cloned_update = ref({ ...update.value })

const v$ = useVuelidate({ content: { required } }, cloned_update)

const dateFormat = computed(() => differenceInHours(new Date(), new Date(update.value.created_at)) <= 48 ? 'formatDistanceToNowStrict' : 'dd/MM/yyyy')
const isPublic = computed(() => !!cloned_update.value.is_public)
const isEdited = computed(() => !!cloned_update.value.is_edit)
const errors = computed(() => error.value || deleteError.value)

const handleClickPatchUpdate = params => {
  v$.value.$touch()

  if (v$.value.$error) return

  loader.value = true
  error.value = null

  patchUpdate({ id: update.value.id, params })
    .then(update => {
      is_editing.value = false
      cloned_update.value = update

      emit('updated')
    })
    .catch(e => error.value = e)
    .finally(() => loader.value = false)
}

const handleDeleteUpdate = () =>  {
  closeModal()

  deleteUpdate({ id: update.value.id })
    .then(() => emit('updated'))
}
</script>

<style lang="scss" scoped>
.update {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__infos {
    display: block;

    @include mq(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }
  }

  &__date {
    font-size: rem(10px);

    @include mq(md) {
      font-size: rem(14px);
    }
  }
}

.status {
  &__ul {
    display: flex;
    margin-right: $margin__base;
    align-items: center;
    font-size: rem(10px);
    line-height: 24px;

    @include mq(md) {
      font-size: em(14px);
    }
  }

  &__li {
    background: hsl(var(--primary-color-h), calc(var(--primary-color-s) * 1%), calc(95%));
    color: $color--primary;
    display: block;
    position: relative;
    padding: 0 calc($padding__base / 2);
    border-radius: $radius__input;
    white-space: nowrap;
    overflow: hidden;
    max-width: 75px;
    text-overflow: ellipsis;

    @include mq(md) {
      max-width: none;
    }

    &:not(:last-child) {
      margin-right: calc($margin__base / 2);

      @include mq(md) {
        margin-right: $margin__base;
      }
    }
  }
}

.editor {
   & :deep() {
     .editor__content {
       min-height: 150px;

       @include mq(md) {
         min-height: 270px;
       }
     }
   }
}
</style>
