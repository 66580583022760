<template>
  <article
    class="product"
    :class="{'product--disabled': !product.is_in_stock }"
  >
    <figure
      class="product__cover"
      v-if="product.cover"
      :style="{ backgroundImage: `url(${product.cover})` }"
    />

    <div class="product__infos">
      <template v-if="product.has_long_name">
        <app-tooltip
          placement="top"
          :triggers="['hover', 'click']"
        >
          <h3 class="bold">
            {{ product.short_name }}
          </h3>

          <template #popper>
            {{ product.full_name }}
          </template>
        </app-tooltip>
      </template>

      <template v-else>
        <h3 class="bold">
          {{ product.full_name }}
        </h3>
      </template>

      <p class="right">
        <input-quantity
          class="product__input"
          v-model.number="quantity"
          :max="product.max_quantity"
          :min="0"
          :disabled="!product.is_in_stock"
          @update:model-value="quantity => emit('updateQuantity', { product_id: product.id, quantity })"
        />
      </p>

      <p class="color--prim">
        <span v-if="product.is_free">{{ t('literal.free') }}</span>

        <app-money
          v-else
          :amount="product.price"
        />
      </p>

      <p class="right light">
        <span v-if="product.is_unlimited_stock">{{ t('literal.unlimited') }}</span>

        <span
          v-else-if="product.is_in_stock"
          v-html="tc(`plural.x_items_left`, product.current_stock, { x: product.current_stock })"
        />

        <span
          v-else
          class="light"
        >
          {{ t('literal.out_of_stock') }}
        </span>
      </p>
    </div>
  </article>
</template>

<script setup>
import { defineAsyncComponent, ref, toRefs } from 'vue'
import { useI18n } from '@/vendors/i18n'

const InputQuantity = defineAsyncComponent(() => import('&/atoms/InputQuantity'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppTooltip = defineAsyncComponent(() => import('@/components/atoms/AppTooltip'))

const emit = defineEmits(['updateQuantity'])
const props = defineProps({ product: { required: true, type: Object } })

const { product } = toRefs(props)
const { t, tc } = useI18n()

const quantity = ref(0)
</script>

<style lang="scss" scoped>
.product {
  border: 1px solid rgba(#2E293D, .1);
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &--disabled {
    background-color: rgba(#2E293D, .1);
    cursor: not-allowed;

    .color--prim {
      color: rgba(#2E293D, .6);
    }

    .product__cover {
      filter: grayscale(100%);
    }
  }

  &__cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include aspect-ratio(3, 2);
  }

  &__infos {
    padding: 18px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 6px;

    .right {
      justify-self: flex-end;
      white-space: nowrap;
    }

    .light {
      color: rgba(#2E293D, .6);
    }
  }
}
</style>
