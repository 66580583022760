import i18n from '@/vendors/i18n'
import blocks from '@/configurations/procedures/blocks'
import { get as _get, clone as _clone } from 'lodash'
import { required, email, sameAs, numeric, minValue, minLength } from '@vuelidate/validators'
import { validIban, minDate, maxDate } from '@/utils/validators'
import { operators } from '@/utils/utils'

export default class Block {
  constructor ({ name = null, model = null, attributes = {}, mandatory = [], options = {}, conditions = [] } = {}) {
    this.name = name
    this.model = model
    this.attributes = attributes
    this.mandatory = mandatory
    this.options = options
    this.conditions = conditions
  }

  get reference () {
    return _clone(blocks.find(block => block.name === this.name))
  }

  get component_path () {
    return this.reference?.component_path
  }

  get options () {
    return this._options
  }

  set options (options) {
    this._options = { ..._get(this.reference, 'static_options', {}), ...options }
  }

  get mandatory () {
    return this._mandatory
  }

  set mandatory (mandatory) {
    _get(this.reference, 'static_mandatory', []).forEach(sm => {
      if (!mandatory.includes(sm)) mandatory.push(sm)
    })

    this._mandatory = mandatory
  }

  get default_value () {
    return _get(this.reference, 'default_value', null)
  }

  get is_required () {
    return Array.isArray(this.mandatory) && ['required', 'sameAs:true', 'productRequired', 'minAmount'].some(validator => this.mandatory.some(m => m.includes(validator)))
  }

  get validations () {
    let args = {}

    if (!Array.isArray(this.mandatory)) return {}

    this.mandatory.forEach(arg => {
      let condition

      if (arg.indexOf(':') !== -1) [arg, condition] = arg.split(/:(.*)/)

      if (arg === 'required' || arg === 'productRequired') args[arg] = required
      if (arg === 'email') args[arg] = email
      if (arg === 'numeric') args[arg] = numeric
      if (arg === 'iban') args[arg] = validIban
      if (arg === 'minLength' && condition) args[arg] = minLength(condition)
      if (arg === 'minDate' && condition) args[arg] = minDate(new Date(condition))
      if (arg === 'maxDate' && condition) args[arg] = maxDate(new Date(condition))

      if (['minValue', 'minAmount'].includes(arg) && condition) args[arg] = minValue(parseInt(condition))
      if (['terms', 'sameAs'].includes(arg) && condition) condition === 'true' || condition === 'false' ? args[arg] = sameAs(Boolean(condition)) : args[arg] = sameAs(condition)
    })

    return args
  }

  isAvailable (data) {
    if (this.name === 'transaction_payment_methods') return _get(data, 'actions_data.transaction.amount', 0) > 0
    return this.isVisible(data)
  }

  isVisible (data) {
    if (!this.conditions.length || !this.options.has_conditions) return true

    const conditions = this.conditions
      .map(condition => {
        if (!condition.model) return { operator: condition.logic_operator, value: true }

        let field = _get(data, condition.model)

        if (typeof field === 'string') field = field.replace(/\s+/g, '').toLowerCase()

        let target, comparisonResult

        if (condition.value_type === 'empty') {
          comparisonResult = condition.comparison_operator === 'eq' ? operators['empty'](field) : operators['nempty'](field)
        } else if (condition.value_type === 'checked') {
          if (field === null) field = false
          comparisonResult = condition.comparison_operator === 'eq' ? operators['checked'](field) : operators['unchecked'](field)
        } else if (condition.value_type === 'unchecked') {
          if (field === null) field = false
          comparisonResult = condition.comparison_operator === 'eq' ? operators['unchecked'](field) : operators['checked'](field)
        } else {
          target = operators[condition.value_type] ? operators[condition.value_type](i18n.translate(condition.value)) : i18n.translate(condition.value)
          comparisonResult = operators[condition.comparison_operator](field, target)
        }

        return { operator: condition.logic_operator, value: comparisonResult }
      })

    return conditions.length === 1 ? conditions[0].value : conditions.reduce((a, b) => operators[b.operator](a.value, b.value))
  }
}
