<template>
  <footer
    v-if="section"
    class="footer"
  >
    <div class="wrapper footer__wrapper">
      <div class="footer__col">
        <app-link
          :link="website"
          target="_self"
          class="footer__website bold"
          v-slot="{ text }"
        >
          <fa-icon
            class="footer__website-icon margin__r--6"
            :icon="['fal', 'long-arrow-left']"
          />

          {{ text }}
        </app-link>

        <nav
          v-if="navigation.length > 0"
        >
          <ul class="footer__menu">
            <li
              class="footer__menu-item"
              v-for="(item, index) in navigation"
              :key="`menu_item_${index}`"
            >
              <app-link
                :link="item.link"
                target="_self"
                class="footer__menu-link"
              />
            </li>
          </ul>
        </nav>

        <app-link
          :link="facebook"
          class="footer__facebook bold"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fab', 'facebook-f']"
          />

          {{ $i18n.translate(facebook.text) }}
        </app-link>
      </div>

      <div class="footer__col">
        <address
          v-if="$i18n.translate(column_2.address_title) || address.length > 0"
          class="footer__address"
        >
          <h4
            v-if="$i18n.translate(column_2.address_title)"
            class="bold"
          >
            {{ $i18n.translate(column_2.address_title) }}
          </h4>

          <template
            v-for="(item, index) in address"
            :key="'footer_address_line_' + index"
          >
            <span class="d-block">
              {{ $i18n.translate(item.line) }}
            </span>
          </template>
        </address>

        <address
          v-if="$i18n.translate(column_2.contact_title) || contactInfos.length > 0"
          class="footer__address margin__t--24"
        >
          <h5
            v-if="$i18n.translate(column_2.contact_title)"
            class="bold"
          >
            {{ $i18n.translate(column_2.contact_title) }}
          </h5>

          <span
            v-for="(item, index) in contactInfos"
            :key="`footer_contact_line_${index}`"
            class="d-block"
          >
            {{ item.label }}
            <a
              class="footer__infos-link"
              :key="'footer_infos_line_' + index"
              :href="item.url"
              target="_blank"
            >
              {{ item.text }}
            </a>
          </span>
        </address>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { get as _get } from 'lodash'

export default defineComponent({
  name: 'CustomFooterUZAFoundation',
  components: {
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  computed: {
    column_1 () {
      return _get(this.section, 'attributes.column_1')
    },
    column_2 () {
      return _get(this.section, 'attributes.column_2')
    },
    navigation () {
      return _get(this.column_1, 'navigation', [])
    },
    facebook () {
      return _get(this.column_1, 'facebook', false)
    },
    website () {
      return _get(this.column_1, 'website', false)
    },
    address () {
      return _get(this.column_2, 'address', false)
    },
    contactInfos () {
      return _get(this.column_2, 'contact', [])
        .map(info => {
          return {
            text: this.$i18n.translate(info.value),
            label: this.$i18n.translate(info.label),
            url: (info.type === 'phone_number' ? 'tel:' : 'mailto:') + this.$i18n.translate(info.value).replace(/ /g, '')
          }
        })
        .filter(info => info.text)
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #3122BE;
$color--secondary: #FB7A6D;

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sm) {
    padding: 0 30px;
  }

  @include mq(md) {
    padding: 0 60px;
  }
}

.footer {
  position: relative;
  background: $white;
  padding: $padding__base * 5 0 $padding__base * 3;
  color: $white;
  font-size: rem(14px);
  color: $color--primary;
  text-align: center;

  @include mq(sm) {
    text-align: left;
  }

  &__wrapper {
    @include mq(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include mq(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__col {
    &:not(:last-child) {
      margin-bottom: $margin__base * 3;

      @include mq(sm) {
        margin: 0 $margin__base * 2 0 0;
      }
    }
  }

  :deep(.footer__website) {
    display: block;
    font-size: rem(14px);
    transition: all .2s;
    color: $color--primary;

    .footer__website-icon {
      position: relative;
      display: inline-block;
      transition: all .2s;
      transform: none;
      color: $color--primary;
    }

    &:hover {
      color: $color--secondary;

      .footer__website-icon {
        color: $color--secondary;
        transform: translateX(-5px);
      }
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    max-width: 240px;
    margin: $margin__base auto 0;

    @include mq(sm) {
      margin: $margin__base 0 0;
    }

    &-item {
      width: 50%;
      margin-top: $margin__base;

      &:first-child, &:nth-child(2) {
        margin-top: 0;
      }
    }

    &-link {
      color: $color--primary;
      transition: all .2s;

      &:hover, &.is-active {
        color: $color--secondary;
      }
    }
  }

  :deep(.footer__facebook) {
    text-align: center;
    background: $color--primary;
    display: inline-block;
    color: $white;
    padding: 20px 20px 18px;
    margin-top: $margin__base;
    line-height: 1;
    border: 2px solid $color--primary;
    transition: all .2s;

    &:hover {
      background: transparent;
      color: $color--primary;
    }
  }

  &__address {
    line-height: 30px;

    a {
      color: $color--primary;
      transition: all .2s;
      line-height: 30px;

      &:hover {
        color: $color--secondary;
      }
    }
  }
}
</style>
