<template>
  <node-view-wrapper
    :class="className"
    :style="style"
    as="figure"
    draggable="true"
    data-drag-handle
  >
    <img
      :src="src"
      :alt="alt"
      :width="width"
      :height="height"
    >
    <figcaption>
      <input
        type="text"
        v-model="alt"
        :placeholder="caption"
        :style="{ width: width + 'px' }"
      >
    </figcaption>
  </node-view-wrapper>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useFileStore } from '@/stores/FileStore'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { useI18n } from '@/vendors/i18n'
import { get as _get, isUndefined as _isUndefined } from 'lodash'

const props = defineProps(nodeViewProps)

const { postSideNotification } = useNotificationStore()
const { t } = useI18n()
const { postFile } = useFileStore()
const { loader } = storeToRefs(useFileStore())

const attrs = computed(() => _get(props, 'node.attrs'))

const width = computed(() => _get(attrs.value, 'width'))
const height = computed(() => _get(attrs.value, 'height'))
const file = computed(() => _get(attrs.value, 'file'))
const textAlign = computed(() => _get(attrs.value, 'textAlign') || 'left')

const isUploaded = computed(() => ['//assets.koalect.com', '//assets.betakoalect.com', '//assets.koalect.dev'].map(url => src.value.includes(url)).some(bool => bool))

const style = computed(() => ({ textAlign: textAlign.value }))

const src = computed({
  get: () => _get(attrs.value, 'src'),
  set: src => props.updateAttributes({ src })
})

const alt = computed({
  get: () => _get(attrs.value, 'alt'),
  set: alt => props.updateAttributes({ alt })
})

const className = computed(() => {
  const classes = []

  if (props.selected) classes.push('selected')
  if (loader.value) classes.push('is-loading')

  return classes.join(' ')
})

const caption = ref(alt.value || t('literal.description'))

const uploadFile = () => {
  if (!file.value) return props.deleteNode()

  file.value.toArrayBuffer(binary => {
    postFile({ params: { binary, name: file.value.name, type: file.value.type } })
      .then(url => {
        if (_isUndefined(props.getPos())) return props.deleteNode()

        src.value = url
      })
      .catch(() => {
        _get(error, 'data.errors', []).forEach(text => postSideNotification({ text, type: 'error' }))

        if (!_isUndefined(props.getPos())) props.deleteNode()
      })
  })
}

onMounted(() => {
  if (!isUploaded.value) uploadFile()
})
</script>

<style lang="scss" scoped>
figure {
  &.is-loading {
    img {
      animation: loading-opacity 1s ease-in-out infinite alternate-reverse;
    }
  }

  &.selected {
    img {
      border-color: $color--secondary;
    }
  }

  img {
    border: 2px solid transparent;
    cursor: grab;
    transition: all $transition__duration;

    &:hover,
    &:focus,
    &:active {
      border-color: $color--secondary;
    }
  }

  figcaption {
    font-size: 14px;
    margin-top: $margin__base;

    input {
      display: inline-block;
      text-align: center;
      width: 100%;
      max-width: 90%;
      border: 0;
      font-size: 14px;
      background: transparent;

      &::placeholder {
        color: $vlg;
        font-style: italic;
      }

      @include mq(xl) {
        max-width: 630px;
      }
    }

    &:focus,
    input:focus {
      outline: 0;
    }
  }
}
</style>