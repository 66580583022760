<template>
  <span>
    {{ amountMoney }}
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    amount: {
      required: true,
      type: [Number, String]
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    showCurrency: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    amountMoney () {
      const format = this.$i18n.getLocaleCurrencyFormat()
      const amount = this.amount / 100
      const minimumFractionDigits = amount % 1 === 0 ? 0 : 2

      const decimalSeparator = Intl.NumberFormat(this.$i18n.locale).format(1.1).replace(/\p{Number}/gu, '')
      const thousandSeparator = Intl.NumberFormat(this.$i18n.locale).format(11111).replace(/\p{Number}/gu, '')

      let formatedAmount = new Intl.NumberFormat(this.$i18n.locale, { style: 'currency', currency: this.currency, minimumFractionDigits: minimumFractionDigits })
        .formatToParts(amount)
        .map(p => this.showCurrency ? p.value : (p.type !== 'currency' && p.type !== 'literal' ? p.value : ''))
        .join('')
        .replace(new RegExp(`\\${decimalSeparator}`), format.decimal || '.')

      if (thousandSeparator) formatedAmount = formatedAmount.replace(new RegExp(`\\${thousandSeparator}`, 'g'), format.delimiter || ',')

      return formatedAmount
    }
  }
})
</script>
