<template>
  <template v-if="contribution">
    <div class="title__container">
      <router-link
        class="contribution__back translate__x-3"
        :to="{ name: 'ProjectContributions', query }"
      >
        <fa-icon
          class="element"
          size="xs"
          :icon="['fal', 'chevron-left']"
        />
      </router-link>

      <h2 class="title__h2 bold">
        {{ title }}
      </h2>
    </div>

    <section class="contribution__data">
      <div class="data__group">
        <p class="data__label label--light">
          {{ t('literal.date_&_time') }}
        </p>

        <app-date
          :timestamp="contribution.created_at"
          filter="dd/MM/yyyy HH:mm"
        />
      </div>

      <div
        class="data__group"
        v-if="contribution.amount"
      >
        <p class="data__label label--light">
          {{ t('literal.total_amount') }}
        </p>

        <app-money :amount="contribution.amount" />
      </div>

      <div
        class="data__group"
        v-if="contribution.payment_method"
      >
        <p class="data__label label--light">
          {{ t('literal.payment_method') }}
        </p>

        <p class="data__flex">
          <app-payment-logo :type="contribution.payment_method" />

          <span class="margin__l--6">
            {{ t(`$payment.${contribution.payment_method.toLowerCase()}`) }}
          </span>
        </p>
      </div>
    </section>

    <h3 class="title__h3 bold">
      {{ t('literal.contribution_details') }}
    </h3>

    <hr class="separator separator__size--full border__color--dw margin__tb--18">

    <section>
      <div class="details__group">
        <p class="details__label">
          {{ t('literal.first_name') }}
        </p>

        <p v-if="customer.firstname">
          {{ customer.firstname }}
        </p>

        <span v-else>
          –
        </span>
      </div>

      <div class="details__group">
        <p class="details__label">
          {{ t('literal.last_name') }}
        </p>

        <p v-if="customer.lastname">
          {{ customer.lastname }}
        </p>

        <span v-else>
          –
        </span>
      </div>

      <div class="details__group">
        <p class="details__label">
          {{ t('literal.email') }}
        </p>

        <p v-if="customer.email">
          {{ customer.email }}
        </p>

        <span v-else>
          –
        </span>
      </div>

      <div
        class="details__group"
        v-if="customer.address.to_html"
      >
        <p class="details__label">
          {{ t('literal.address') }}
        </p>

        <address v-html="customer.address.to_html" />
      </div>

      <div
        class="details__group"
        v-if="customer.phone_number"
      >
        <p class="details__label">
          {{ t('literal.phone_number') }}
        </p>

        <p>{{ customer.phone_number }}</p>
      </div>

      <div
        class="details__group"
        v-if="contribution.private_message"
      >
        <p class="details__label">
          {{ t('literal.private_comment') }}
        </p>

        <p>{{ contribution.private_message }}</p>
      </div>

      <div
        class="details__group"
        v-if="contribution.message"
      >
        <p class="details__label">
          {{ t('literal.public_comment') }}
        </p>

        <p>{{ contribution.message }}</p>
      </div>

      <div
        class="details__group"
        v-if="contribution.donation_amount"
      >
        <p class="details__label">
          {{ t('literal.donation_amount') }}
        </p>

        <app-money :amount="contribution.donation_amount" />
      </div>

      <table
        class="table"
        role="table"
        aria-label="List of products"
        v-if="contribution.ordered_products.length"
      >
        <caption class="table__caption">
          {{ t('literal.list_of_products') }}
        </caption>

        <thead class="table__thead">
          <tr
            class="table__tr table__tr--naked"
            role="rowgroup"
          >
            <th
              class="table__th"
              role="columnheader"
            >
              {{ t('literal.products') }}
            </th>

            <th
              class="table__th"
              role="columnheader"
            >
              {{ t('literal.quantity') }}
            </th>

            <th
              class="table__th"
              role="columnheader"
            >
              {{ t('literal.price') }}
            </th>
          </tr>
        </thead>

        <tbody class="table__tbody card">
          <tr
            class="table__tr table__hover"
            role="rowgroup"
            v-for="(product, index) in contribution.ordered_products"
            :key="`product_${index}`"
          >
            <td
              class="table__td"
              role="cell"
            >
              {{ translate(product.product_name) }}
            </td>

            <td
              class="table__td"
              role="cell"
            >
              {{ product.quantity }}
            </td>

            <td
              class="table__td"
              role="cell"
            >
              <app-money :amount="product.unit_price" />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </template>

  <template v-else>
    <div class="contribution__spinner">
      <app-spinner />
    </div>
  </template>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/ProjectStore'
import { useContributionStore } from '@/stores/ContributionStore'
import { useI18n } from '@/vendors/i18n'
import { useHead } from '@unhead/vue'
import { getAmount } from '@/utils/utils'
import { get as _get } from 'lodash'

import AppDate from '&/atoms/AppDate'

const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppPaymentLogo = defineAsyncComponent(() => import('&/atoms/AppPaymentLogo'))
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const props = defineProps({
  projectSlug: { type: String, required: true },
  contributionId: { type: String,  required: true }
})

const { query } = useRoute()
const { t, translate, locale } = useI18n()
const { getProject } = useProjectStore()
const { getContribution } = useContributionStore()

const { project } = getProject({ id: props.projectSlug })
const { contribution } = getContribution({ id: props.contributionId })

const customer = computed(() => _get(contribution.value, 'customer'))
const title = computed(() => [customer.value.is_anonymous ? t('literal.anonymous') : `${customer.value.fullname}`, contribution.value.amount ? getAmount(contribution.value.amount, locale) : ''].filter(v => v).join(' — '))

useHead({ title: computed(() => project.value && contribution.value ? `${title.value} / ${t('literal.contributions')} / ${_get(project.value, 'name', '')}` : `${t('literal.project_contribution')} / ${t('literal.contributions')}`) })
</script>

<style lang="scss" scoped>
.page__padding {
  padding-top: $padding__base * 1.5;
  padding-bottom: $padding__base * 4;
}

.title {
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
  }
}

.contribution {
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
  }

  &__data {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 30px 0;
    gap: 12px;

    @include mq(sm) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__back {
    display: block;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    color: $color--primary;
  }
}

.data {
  &__group:not(:last-child) {
    margin-right: 48px;
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__label {
    margin-bottom: 6px;

    @include mq(sm) {
      margin-bottom: 12px;
    }
  }
}

.details {
  &__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;

    @include mq(sm) {
      flex-direction: row;
    }

    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }

  &__label {
    display: block;
    width: 220px;
    color: rgba($dg, .6);
  }
}

.table {
  margin-top: 24px;

  &__tr {
    grid-template-columns: repeat(3, 1fr);
    background-color: transparent;
    padding: 0;
    text-align: center;
  }

  &__th {
    justify-self: stretch;
    border-top: 1px solid $dw;
  }

  &__tbody, &__tbody &__tr:nth-child(odd) {
    background-color: transparent;
    box-shadow: none;
  }

  &__th, &__td {
    border-bottom: 1px solid $dw;
    border-left: 1px solid $dw;
    border-right: 1px solid $dw;
    padding: 12px;

    &:not(:last-child) {
      border-right: 0;
    }
  }
}
</style>
