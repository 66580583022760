<template>
  <div class="bg__color--white">
    <div
      class="container"
      v-if="project"
    >
      <section class="banner align--center">
        <div class="banner__wrapper padding__lr--12">
          <app-link
            :link="banner.back_to"
            target="_self"
            class="link__color--prim-white link--naked d-inline-block margin__b--24 banner__backto"
            v-if="translate(banner.back_to.text)"
          >
            <fa-icon
              class="margin__r--6"
              :icon="['fal', 'arrow-left']"
            />

            {{ translate(banner.back_to.text) }}
          </app-link>

          <h1 class="title__h1 color--white banner__title">
            {{ benefitingName }}
          </h1>
        </div>
      </section>

      <section
        class="counter"
        v-if="isVisible('counter') && statistics"
      >
        <ul class="counter__items">
          <li
            v-for="(metric, index) in counter.items"
            :key="`counter_${index}`"
            class="counter__item color--white align--center"
          >
            {{ translate(metric.text) }}

            <strong>
              <app-date
                v-if="metric.type === 'date' && metric.date"
                :timestamp="metric.date"
                filter="differenceInCalendarDays"
              />

              <app-money
                v-else-if="metric.type === 'amount_collected'"
                :amount="statistics[metric.type]"
              />

              <template v-else>
                {{ statistics[metric.type] }}
              </template>
            </strong>
          </li>
        </ul>

        <router-link
          class="cta cta--large counter__cta"
          :to="{ name: 'ClientProcedure', params: { procedureSlug: createProjectProcedure.slug }, query: { 'actions_data[project][benefiting]': '38b3655a-0d6d-4852-a8ae-7187ad451c6c' } }"
          target="_blank"
          v-if="createProjectProcedure && translate(counter.start_action.text)"
        >
          <span>{{ translate(counter.start_action.text) }}</span>
        </router-link>

        <app-link
          class="cta cta--large counter__cta"
          :link="counter.start_action"
        >
          <span>{{ translate(counter.start_action.text) }}</span>
        </app-link>

        <router-link
          class="cta cta--large counter__cta"
          :to="{ name: 'Project', params: { projectSlug: project.slug } }"
          target="_blank"
          v-if="translate(counter.support_us) && project"
        >
          <span>{{ translate(counter.support_us) }}</span>
        </router-link>
      </section>

      <section
        class="action margin__t--48"
        v-if="isVisible('action') && project"
      >
        <div class="action__wrapper">
          <div class="action__content">
            <h2
              v-if="translate(action.title)"
              class="title__h2 action__title"
            >
              {{ translate(action.title) }}
            </h2>

            <div
              class="editor editor__content action__excerpt margin__t--24"
              :class="{ 'is-excerpt': actionExcerpt !== translate(action.content) }"
              v-if="actionExcerpt"
              v-html="actionExcerpt"
            />

            <button
              class="bold action__more-info"
              @click="isShowActionDescriptionModal = true"
              v-if="translate(action.more_info) && translate(action.content) && actionExcerpt !== translate(action.content)"
            >
              {{ translate(action.more_info) }}
            </button>

            <div class="align--center margin__t--24">
              <router-link
                class="cta action__cta"
                :to="{ name: 'Project', params: { projectSlug: project.slug } }"
                target="_blank"
                v-if="translate(counter.support_us) && project"
              >
                <span>{{ translate(action.support_us) }}</span>
              </router-link>
            </div>

            <app-modal
              v-if="isShowActionDescriptionModal"
              size="md"
              @close="isShowActionDescriptionModal = false"
            >
              <template #body>
                <div
                  class="editor editor__content padding--36 action__description"
                  v-html="translate(action.content)"
                />
              </template>
            </app-modal>
          </div>

          <figure
            class="action__figure"
            :class="{ 'no-image': !translate(action.image) }"
            :style="{ backgroundImage: `url(${translate(action.image) })`}"
          >
            <fa-icon
              :icon="['far', 'image']"
              v-if="!translate(action.image)"
            />
          </figure>
        </div>
      </section>

      <section
        class="actions padding--36 margin__t--48"
        v-if="isVisible('actions') && projects.length"
      >
        <h2
          v-if="translate(actions.title)"
          class="color--white actions__title title__h2"
        >
          {{ translate(actions.title) }}
        </h2>

        <div
          class="editor editor__content actions__content margin__t--24 color--white"
          v-if="translate(actions.content)"
          v-html="translate(actions.content)"
        />

        <ul class="actions__items margin__t--30">
          <li
            class="actions__item"
            v-for="p in projects"
            :key="p.id"
          >
            <article class="project-card bg__color--white">
              <figure
                class="project-card__figure"
                :style="{ backgroundImage: `url(${ p.image.url || p.campaign.image.url })` }"
              />

              <div class="project-card__content padding__lr--24 padding__t--24 padding__b--36">
                <div class="project-card__amount">
                  <app-money :amount="p.amount_collected" />
                </div>

                <h3 class="project-card__title margin__t--18 margin__b--48 title__h3">
                  <router-link
                    class="project-card__link"
                    :to="{ name: 'Project', params: { projectSlug: p.slug } }"
                    target="_blank"
                    v-if="translate(counter.support_us) && project"
                  >
                    {{ p.name }}
                  </router-link>
                </h3>

                <span
                  class="project-card__cta"
                  v-if="translate(actions.support)"
                >
                  {{ translate(actions.support) }}
                </span>
              </div>
            </article>
          </li>
        </ul>

        <div
          class="align--center margin__t--36"
          v-if="translate(actions.see_more) && pagination.next_page"
        >
          <button
            class="actions__cta link__color--white-white link--naked"
            @click="fetchActions(pagination.next_page)"
            :disabled="loader.projects"
          >
            <app-spinner
              v-if="loader.projects"
              class="actions__loader"
            />

            <template v-else>
              {{ translate(actions.see_more) }}
            </template>
          </button>
        </div>
      </section>

      <section
        class="about"
        v-if="isVisible('about')"
      >
        <div class="about__wrapper">
          <div class="about__content">
            <h2
              v-if="translate(about.title)"
              class="title__h2 color--prim about__title"
            >
              {{ translate(about.title) }}
            </h2>

            <div
              class="editor editor__content about__excerpt margin__t--24 color--prim"
              v-if="translate(about.content)"
              v-html="translate(about.content)"
            />
          </div>

          <app-link
            class="cta about__cta"
            :link="about.start_action"
          >
            <span v-html="translate(about.start_action.text)" />
          </app-link>
        </div>
      </section>

      <section
        class="ngo margin__t--48"
        v-if="isVisible('ngo')"
      >
        <div class="ngo__wrapper">
          <figure
            class="ngo__figure"
            :class="{ 'no-image': !translate(ngo.video) && !translate(ngo.image), 'is-video': translate(ngo.video) }"
          >
            <iframe
              class="ngo__video"
              :src="translate(ngo.video)"
              scrolling="no"
              frameborder="1"
              allowfullscreen
              v-if="translate(ngo.video)"
            />

            <img
              :src="translate(ngo.image)"
              :alt="benefitingName"
              v-else-if="translate(ngo.image)"
            >

            <fa-icon
              :icon="['far', 'image']"
              v-else
            />
          </figure>

          <div class="ngo__content">
            <div
              class="align--center margin__b--36"
              v-if="translate(ngo.logo)"
            >
              <img
                :src="translate(ngo.logo)"
                :alt="benefitingName"
                class="ngo__logo"
              >
            </div>

            <div
              class="editor editor__content ngo__content"
              v-if="translate(ngo.content)"
              v-html="translate(ngo.content)"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, defineAsyncComponent } from 'vue'
import { mapActions } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import { get as _get, first as _first } from 'lodash'
import useSection from '@/composables/modules/pages/useSection'
import { projects_overview_options } from '@/configurations/general/components-default-data'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const AppModal = defineAsyncComponent(() => import('&/organisms/AppModal'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)

const { fetchClientStatistics } = mapActions('client')
const { fetchProject, fetchProjects } = mapActions('projects')

const isShowActionDescriptionModal = ref(false)
const project = ref(null)
const statistics = ref(null)
const projects = ref([])
const pagination = ref({})
const loader = reactive({ projects: false })

const settings = computed(() => getSectionAttributes('settings'))
const banner = computed(() => getSectionAttributes('banner'))
const counter = computed(() => getSectionAttributes('counter'))
const action = computed(() => getSectionAttributes('action'))
const actions = computed(() => getSectionAttributes('actions'))
const about = computed(() => getSectionAttributes('about'))
const ngo = computed(() => getSectionAttributes('ngo'))

const projectId = computed(() => _first(settings.value.project_id))
const benefitingId = computed(() => _get(project.value, 'benefiting.id'))
const benefitingName = computed(() => _get(project.value, 'benefiting.name', translate(props.page.title)))
const sort = computed(() => projects_overview_options.find(o => o.value === _get(actions.value, 'query.sort', 'activity_last'))?.query || { 'sort_by_field[field]': 'activity_last', 'sort_by_field[direction]': 'desc' })
const query = computed(() => ({ per_page: 4, ...sort.value, ..._get(actions.value, 'query', {}), benefiting_id: [benefitingId.value] }))
const actionExcerpt = computed(() => translate(action.value.content).split(' ').slice(0, 60).join(' '))

const fetchActions = (page = 1) => {
  loader.projects = true

  fetchProjects({ query: { ...query.value, page } })
    .then(({ items, paginator }) => {
      projects.value = [...projects.value, ...items]
      pagination.value = paginator
    })
    .finally(() => loader.projects = false)
}

fetchProject({ id: projectId.value })
  .then(highlighted_project => {
    project.value = highlighted_project

    if (isVisible('counter')) fetchClientStatistics({ query: { benefiting_id: [benefitingId.value] } }).then(stats => statistics.value = stats)
    if (isVisible('actions')) fetchActions()
  })
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: $padding__base * 13.5;
  background-image: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/jez/container_bg.png');
  background-position: center bottom 55px;
  background-repeat: no-repeat;
}

.title {
  &__h1 {
    font-size: rem(36px);
    line-height: 1.125;
    font-family: $font-family--secondary;
    word-break: break-word;

    @include mq(sm) {
      font-size: rem(48px);
    }
  }

  &__h2 {
    font-size: rem(32px);
    line-height: 1.1875;
    font-family: $font-family--secondary;
    word-break: break-word;
  }

  &__h3 {
    font-size: rem(16px);
    line-height: 1.333;
    font-family: $font-family--secondary;
    word-break: break-word;

    @include mq(lg) {
      font-size: rem(18px);
    }
  }
}

.cta,
:deep(.cta) {
  background: linear-gradient(235.47deg, #8E1B3F 0%, #C30146 99.3%);
  border-radius: 4px;
  text-align: center;
  color: $white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: rem(18px);
  line-height: 1.333;
  display: inline-block;
  position: relative;
  padding: $padding__base $padding__base * 1.5;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #8E1B3F;
    opacity: 0;
    transition: all $transition__duration;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &.cta--large {
    padding: $padding__base * 2;
  }

  span {
    position: relative;
    z-index: 10;

    &::after {
      content: '';
      display: block;
      background: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/jez/cta_underline.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 130px;
      height: 9px;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

figure {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  img,
  .embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  figcaption {
    position: absolute;
    opacity: 0;
  }
}

.no-image {
  font-size: rem(50px);
  color: $white;
  background: $color--secondary;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.banner {
  padding: $padding__base * 4 0 $padding__base * 3;
  position: relative;

  @include aspect-ratio(1, 1);

  &::before {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/jez/banner_bg.svg');
    background-size: 1424px auto;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
  }

  &__wrapper {
    position: relative;
    z-index: 10;
  }

  &__title {
    text-transform: uppercase;
    text-shadow: #222 3px 0px 0px, #222 2.83487px 0.981584px 0px, #222 2.35766px 1.85511px 0px, #222 1.62091px 2.52441px 0px, #222 0.705713px 2.91581px 0px, #222 -0.287171px 2.98622px 0px, #222 -1.24844px 2.72789px 0px, #222 -2.07227px 2.16926px 0px, #222 -2.66798px 1.37182px 0px, #222 -2.96998px 0.42336px 0px, #222 -2.94502px -0.571704px 0px, #222 -2.59586px -1.50383px 0px, #222 -1.96093px -2.27041px 0px, #222 -1.11013px -2.78704px 0px, #222 -0.137119px -2.99686px 0px, #222 0.850987px -2.87677px 0px, #222 1.74541px -2.43999px 0px, #222 2.44769px -1.73459px 0px, #222 2.88051px -0.838247px 0px;
  }

  &:deep(.banner__backto) {
    svg {
      position: relative;
      transition: all $transition__duration;
    }

    &:hover {
      svg {
        transform: translateX(-5px);
      }
    }
  }
}

.counter {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  gap: $margin__base * 1.5 $margin__base * 2;

  @include mq(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(lg) {
    grid-template-columns: auto 230px;
    grid-template-rows: repeat(2, 1fr);
  }

  @include mq(xl) {
    grid-template-columns: auto 303px;
  }

  &__items {
    background: linear-gradient(235.47deg, #8D1B3E 0%, #C30045 99.3%);
    display: grid;
    align-items: center;

    @include mq(sm) {
      grid-column: 1/3;
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(lg) {
      grid-column: 1/2;
      grid-row: 1/3;
    }
  }

  &__item {
    font-size: rem(18px);
    padding: $padding__base;

    @include mq(sm) {
      font-size: rem(16px);
      padding: $padding__base * 2.5 $padding__base * 2;
    }

    @include mq(lg) {
      font-size: rem(18px);
    }

    strong {
      font-family: $font-family--secondary;
      font-size: rem(36px);
      display: block;
      white-space: nowrap;
    }
  }


  &:deep(.counter__cta) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }
}

.action {
  color: #222222;
  position: relative;

  &__wrapper {
    display: grid;
    gap: $margin__base * 2;
    grid-template-columns: 1fr;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__content {
    background: #F1F2F2;
    padding: $padding__base * 2;
    min-height: 100%;

    @include mq(sm) {
      padding: $padding__base * 3;
    }
  }

  &__title {
    color: #000;
  }

  &__excerpt {
    font-size: rem(18px);
    line-height: 1.5;

    &.is-excerpt {
      &:deep() {
        & > *:last-child {
          &::after {
            content: '...';
          }
        }
      }
    }
  }

  &__more-info {
    transition: all $transition__duration;
    font-size: rem(18px);

    &:hover {
      color: $color--primary;
    }
  }

  &__cta {
    min-width: 240px;
    position: relative;
    z-index: 10;
  }

  &__description{
    font-size: rem(18px);
    max-width: 780px;
    margin: 0 auto;
  }

  &__figure {
    @include aspect-ratio(630, 420);
    z-index: 10;
  }
}
.actions {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: -80px;
    transform: translateX(-50%);
    background: url('https://koalect-images.s3.eu-west-3.amazonaws.com/clients/jez/actions_bg.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: auto 100%;
    width: 100vw;
    height: 903px;
    z-index: 5;
    pointer-events: none;

    @include mq(lg) {
      top: -110px;
      background-position: top left calc(50% + 100px);
    }
  }

  &:after {
    background: linear-gradient(259.89deg, rgba(140, 25, 59, 0.8) 0%, #8C193C 100%);
    opacity: 0.8;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__title {
    position: relative;
    z-index: 10;
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &__items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $margin__base * 2;
    z-index: 10;
  }

  &__item {
    width: 100%;

    @include mq(sm) {
      width: calc(50% - 12px);
    }

    @include mq(md) {
      width: calc(25% - 18px);
    }
  }

  &__cta {
    position: relative;
    z-index: 10;
    font-size: rem(18px);
  }

  &__loader {
    &:deep() {
      .spinner {
        &__circle {
          fill: $white;
        }
      }
    }
  }
}
.project-card {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  transition: all $transition__duration;

  &:hover {
    transform: scale(1.02);
    .project-card__cta {
      background-color: #8E1B3F;
    }
  }

  &__figure {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0 0;

    @include aspect-ratio(285, 192);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
  }

  &__amount {
    color: #D60A3C;
    padding-bottom: calc($padding__base / 2);
    border-bottom: 1px solid #D60A3C;
    width: 100%;
  }

  &__cta {
    background: #C30045;
    padding: $padding__base $padding__base * 1.5;
    border-radius: 4px;
    color: $white;
    font-size: rem(14px);
    line-height: 1.28;
    margin-top: auto;
    transition: all $transition__duration;
  }

  &__link {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
    }
  }
}

.about {
  position: relative;
  z-index: 20;
  margin-top: $margin__base * 4;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;

    @include mq(md) {
      grid-template-columns: auto 412px;
    }
  }

  &__content {
    background: #F1F2F2;
    padding: $padding__base * 2;

    @include mq(sm) {
      padding: $padding__base * 3;
    }
  }

  &__title {
    max-width: 600px;
  }

  &__excerpt {
    font-size: rem(18px);
    line-height: 1.5;
  }

  &:deep(.about__cta) {
    background: linear-gradient(235.47deg, #8D1B3E 0%, #C30045 99.3%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(24px);
    line-height: 1;
    color: $white;
    text-transform: uppercase;
    font-weight: normal;
    padding: $padding__base * 10 0;
    border-radius: 0;

    span {
      position: relative;
      transform: rotate(-3deg);
      display: block;

      strong {
        font-family: $font-family--secondary;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: rem(50px);
        display: block;
      }
    }
  }
}

.ngo {
  background: #F1F2F2;
  padding: $padding__base * 2;
  font-size: rem(18px);
  line-height: 1.5;
  color: #000;

  @include mq(sm) {
    padding: $padding__base * 3;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin__base * 2;
    align-items: flex-start;

    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__figure {
    @include aspect-ratio(596, 334);

    &.is-video {
      background: $black;

      @include aspect-ratio(1024, 576);
    }
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__logo {
    max-width: 100%;
    max-height: 90px;
  }
}
</style>