<template>
  <div class="align--center padding__tb--12 bg__color--white padding__lr--24">
    <app-save-button
      class="btn btn__size--medium btn__color--ter d-inline-flex"
      :show-icon="false"
      :disabled="is_loading"
      @click="handleClickButton()"
    >
      {{ !isSucceededProcedureExecution && category === 'create-project' && isLastStep ? t('literal.back_to_homepage') : translate(nextButton) }}

      <app-money
        class="btn__total"
        :amount="total"
        v-if="total && isBeforeLastStep"
      />
    </app-save-button>
  </div>
</template>

<script setup>
import { inject, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { storeToRefs } from 'pinia'
import { useProcedureStore } from '@/stores/ProcedureStore'
import { useProjectStore } from '@/stores/ProjectStore'
import { useSecureStore } from '@/stores/SecureStore'
import { useProcedureExecutionStore } from '@/stores/ProcedureExecutionStore'
import { useCartStore } from '@/stores/CartStore'
import { useProcedure, useProcedureStep, useProcedureNavigation, useProcedureTransaction, useProcedureExecution, useProcedureToken } from '@/composables/modules/procedures'
import { scrollTo } from '@/utils/utils'
import { omit as _omit } from 'lodash'
import { useTamaro } from '@/vendors/integrations/tamaro'

import AppSaveButton from '&/molecules/AppSaveButton'

const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')
const is_loading = inject('is_loading')
const v = inject('v')

const { t, translate } = useI18n()
const { getCart } = useCartStore()
const { loader: procedureLoader, error } = storeToRefs(useProcedureStore())
const { $resetStore: resetProjectStore } = useProjectStore()
const { fetchProcedureSecureToken } = useSecureStore()
const { postProcedureExecution } = useProcedureExecutionStore()
const { loader: procedureExecutionLoader } = storeToRefs(useProcedureExecutionStore())
const { id: procedureId, slug: procedureSlug, successPaymentPageUrl, paymentProvider, category } = useProcedure({ procedure })
const { isBeforeLastStep, isLastStep, nextButton, stepContainsCustomFields } = useProcedureStep({ procedure })
const { goToNextStep, nextRedirection } = useProcedureNavigation({ procedure, procedure_execution })
const { id: procedureExecutionId, handleDoneProcedureExecution, handlePatchProcedureExecution, isSucceeded: isSucceededProcedureExecution } = useProcedureExecution({ procedure, procedure_execution })
const { handlePayingProcedure, setRedirectUrl, hasTransaction } = useProcedureTransaction({ procedure, procedure_execution })
const { setTokens } = useProcedureToken({ procedure, procedure_execution })
const { validate: handleTamaroValidation } = useTamaro()

const { total } = getCart({ id: procedureId })

const handleClickButton = async () => {
  if (isBeforeLastStep.value && paymentProvider.value === 'tamaro') await handleTamaroValidation(procedure_execution.value.to_tamaro)

  v.value.$touch()

  if (v.value.$invalid) return scrollTo('.input--errors')

  if (isLastStep.value) {
    const promises = stepContainsCustomFields.value ? [handlePatchProcedureExecution()] : [Promise.resolve()]

    Promise.all(promises).then(() => {
      resetProjectStore(['projects', 'public_procedure_executions', 'statistics', 'contributions'])
      nextRedirection()
    })
  } else if (isBeforeLastStep.value) {
    setRedirectUrl(window.location.href)

    return setTokens().then(() => {
      if (paymentProvider.value === 'tamaro' && hasTransaction.value) procedureLoader.value = true

      postProcedureExecution({ procedure_id: procedureId, params: _omit(procedure_execution.value.to_database, procedure.value.blocks.getUnvisibleModels(procedure_execution.value)) })
        .then(() => {
          procedureExecutionLoader.value = true

          if (!procedureExecutionId.value) return window.location.href = translate(successPaymentPageUrl.value)

          hasTransaction.value ? handlePayingProcedure() : handleDoneProcedureExecution()
        })
        .catch(e => {
          error.value = e
          procedureLoader.value = false

          fetchProcedureSecureToken({ id: procedureSlug.value })
        })
    })
  } else {
    goToNextStep()
  }
}
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  justify-content: center;

  @include mq(md) {
    width: auto;
  }

  &__total {
    margin-left: 3px;

    &:before {
      content: '|';
      margin-right: 6px;
    }
  }
}
</style>