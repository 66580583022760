import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faArrowLeft as fasArrowLeft,
  faEuroSign as fasEuroSign,
  faLongArrowAltLeft as fasLongArrowAltLeft,
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faCheck as fasCheck,
  faTimes as fasTimes,
  faChevronRight as fasChevronRight,
  faChevronLeft as fasChevronLeft,
  faArrowUp as fasArrowUp,
  faArrowRight as fasArrowRight,
  faEllipsisH as fasEllipsisH,
  faCog as fasCog,
  faBars as fasBars,
  faTachometerAlt as fasTachometerAlt,
  faSearch as fasSearch,
  faPlus as fasPlus,
  faMoneyBillAlt as fasMoneyBillAlt,
  faGlobe as fasGlobe,
  faExclamationTriangle as fasExclamationTriangle,
  faExclamationCircle as fasExclamationCircle,
  faGripVertical as fasGripVertical,
  faPlusCircle as fasPlusCircle,
  faFont as fasFont,
  faAlignLeft as fasAlignLeft,
  faAt as fasAt,
  faCloudUploadAlt as fasCloudUploadAlt,
  faShare as fasShare,
  faRedo as fasRedo,
  faInfoCircle as fasInfoCircle,
  faUniversity as fasUniversity,
  faHourglassHalf as fasHourglassHalf,
  faSync as fasSync,
  faLock as fasLock,
  faUserCircle as fasUserCircle,
  faUser as fasUser,
  faUserPlus as fasUserPlus,
  faQuoteLeft as fasQuoteLeft,
  faQuoteRight as fasQuoteRight,
  faMapMarkerAlt as fasMapMarkerAlt,
  faPhoneAlt as fasPhoneAlt,
  faLongArrowAltRight as fasLongArrowAltRight,
  faEnvelope as fasEnvelope,
  faPhone as fasPhone,
  faFilter as fasFilter
} from '@fortawesome/free-solid-svg-icons'

import {
  faCheck as falCheck,
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faHeart as falHeart,
  faSignOut as falSignOut,
  faCog as falCog,
  faFileDownload as falFileDownload,
  faCalendar as falCalendar,
  faCreditCard as falCreditCard,
  faChevronLeft as falChevronLeft,
  faUser as falUser,
  faLock as falLock,
  faLanguage as falLanguage,
  faCheckCircle as falCheckCircle,
  faExclamationTriangle as falExclamationTriangle,
  faExclamationCircle as falExclamationCircle,
  faInfoCircle as falInfoCircle,
  faDownload as falDownload,
  faCamera as falCamera,
  faMapMarkerAlt as falMapMarkerAlt,
  faSave as falSave,
  faTrash as falTrash,
  faSearch as falSearch,
  faChevronRight as falChevronRight,
  faSignIn as falSignIn,
  faUserPlus as falUserPlus,
  faCircle as falCircle,
  faSync as falSync,
  faBan as falBan,
  faFlag as falFlag,
  faUsers as falUsers,
  faClock as falClock,
  faEdit as falEdit,
  faTimes as falTimes,
  faEnvelope as falEnvelope,
  faLink as falLink,
  faUnlink as falUnlink,
  faExternalLink as falExternalLink,
  faBold as falBold,
  faItalic as falItalic,
  faUnderline as falUnderline,
  faH1 as falH1,
  faH2 as falH2,
  faListUl as falListUl,
  faListOl as falListOl,
  faQuoteRight as falQuoteRight,
  faQuoteLeft as falQuoteLeft,
  faUndo as falUndo,
  faRedo as falRedo,
  faPaperclip as falPaperclip,
  faBadgeCheck as falBadgeCheck,
  faLightbulb as falLightbulb,
  faPiggyBank as falPiggyBank,
  faEmptySet as falEmptySet,
  faPlus as falPlus,
  faMinus as falMinus,
  faPaperPlane as falPaperPlane,
  faCloudDownload as falCloudDownload,
  faCommentAlt as falCommentAlt,
  faBars as falBars,
  faCheckSquare as falCheckSquare,
  faSquare as falSquare,
  faAlignLeft as falAlignLeft,
  faAlignCenter as falAlignCenter,
  faAlignRight as falAlignRight,
  faCode as falCode,
  faLongArrowLeft as falLongArrowLeft,
  faLongArrowRight as falLongArrowRight,
  faCopy as falCopy,
  faArrowCircleRight as falArrowCircleRight,
  faArrowCircleLeft as falArrowCircleLeft,
  faPlug as falPlug,
  faFlagCheckered as falFlagCheckered,
  faStopwatch as falStopwatch,
  faHeartRate as falHeartRate,
  faPlusCircle as falPlusCircle,
  faArrowLeft as falArrowLeft,
  faArrowRight as falArrowRight,
  faEye as falEye,
  faEyeSlash as falEyeSlach,
  faExternalLinkSquare as falExternalLinkSquare,
  faCircleUser as falCircleUser,
  faArrowDown as falArrowDown,
  faFilter as falFilter,
  faRotateRight as falRotateRight,
  faPalette as falPalette,
  faPhone as falPhone,
  faCirclePlay as falCirclePlay,
  faGripVertical as falGripVertical,
  faBasketShoppingSimple as falBasketShoppingSimple,
  faInboxFull as falInboxFull
} from '@fortawesome/pro-light-svg-icons'

import {
  faBars as farBars,
  faMapMarkerAlt as farMapMarkerAlt,
  faPhoneAlt as farPhoneAlt,
  faEnvelope as farEnvelope,
  faUniversity as farUniversity,
  faBookmark as farBookmark,
  faChevronUp as farChevronUp,
  faChevronDown as farChevronDown
} from '@fortawesome/pro-regular-svg-icons'

import {
  faSignOut as fasSignOut
} from '@fortawesome/pro-solid-svg-icons'

import {
  faFacebookSquare as fabFacebookSquare,
  faFacebookF as fabFacebookF,
  faFacebookMessenger as fabFacebookMessenger,
  faWhatsapp as fabWhatsapp,
  faTwitterSquare as fabTwitterSquare,
  faTwitter as fabTwitter,
  faXTwitter as fabXTwitter,
  faLinkedin as fabLinkedin,
  faLinkedinIn as fabLinkedinIn,
  faInstagram as fabInstagram,
  faYoutube as fabYoutube,
  faMedium as fabMedium,
  faTiktok as fabTiktok,
  faTwitch as fabTwitch,
  faStrava as fabStrava
} from '@fortawesome/free-brands-svg-icons'

import {
  faEdit as farEdit,
  faFlag as farFlag,
  faHandshake as farHandshake,
  faCheckCircle as farCheckCircle,
  faPaperPlane as farPaperPlane,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faTrashAlt as farTrashAlt,
  faFileAlt as farFileAlt,
  faSquare as farSquare,
  faImage as farImage,
  faCreditCard as farCreditCard,
  faCalendarAlt as farCalendarAlt,
  faCalendar as farCalendar,
  faAddressCard as farAddressCard,
  faSave as farSave,
  faUser as farUser,
  faTimesCircle as farTimesCircle,
  faFileImage as farFileImage,
  faThumbsUp as farThumbsUp
} from '@fortawesome/free-regular-svg-icons'

const icons = {
  fasArrowLeft,
  farEdit,
  fasEuroSign,
  fasLongArrowAltLeft,
  fasChevronUp,
  fasChevronDown,
  fasChevronRight,
  fasChevronLeft,
  fasCheck,
  fasTimes,
  fasArrowUp,
  fasArrowRight,
  fasEllipsisH,
  fasCog,
  farFlag,
  farHandshake,
  fasBars,
  fasTachometerAlt,
  fasSearch,
  fasPlus,
  fasMoneyBillAlt,
  farCheckCircle,
  farPaperPlane,
  farEye,
  farEyeSlash,
  fasGlobe,
  fasExclamationTriangle,
  fasExclamationCircle,
  fasGripVertical,
  fasPlusCircle,
  farTrashAlt,
  fasFont,
  farFileAlt,
  fasAlignLeft,
  farSquare,
  farImage,
  farCreditCard,
  fasAt,
  farCalendarAlt,
  farCalendar,
  farAddressCard,
  farSave,
  fasCloudUploadAlt,
  farUser,
  fasShare,
  fasRedo,
  fasInfoCircle,
  fasUniversity,
  farTimesCircle,
  fasHourglassHalf,
  fasSync,
  fasLock,
  falCheck,
  falChevronDown,
  falChevronUp,
  fabFacebookSquare,
  fabFacebookF,
  fabFacebookMessenger,
  fabWhatsapp,
  fabTwitterSquare,
  fabXTwitter,
  fabLinkedin,
  fabLinkedinIn,
  fabStrava,
  falHeart,
  fasUserCircle,
  falSignOut,
  fasSignOut,
  falCog,
  falFileDownload,
  falCalendar,
  falCreditCard,
  falChevronLeft,
  falUser,
  falLock,
  falLanguage,
  falCheckCircle,
  falExclamationTriangle,
  falExclamationCircle,
  falInfoCircle,
  falDownload,
  falCamera,
  falMapMarkerAlt,
  falSave,
  falTrash,
  falSearch,
  falChevronRight,
  falSignIn,
  falUserPlus,
  falCircle,
  falSync,
  falBan,
  falFlag,
  falUsers,
  falClock,
  falEdit,
  falTimes,
  fabTwitter,
  falEnvelope,
  falLink,
  falUnlink,
  falExternalLink,
  falBold,
  falItalic,
  falUnderline,
  falH1,
  falH2,
  falListUl,
  falListOl,
  falQuoteRight,
  falQuoteLeft,
  falUndo,
  falRedo,
  falPaperclip,
  falBadgeCheck,
  falLightbulb,
  farFileImage,
  falPiggyBank,
  falEmptySet,
  farThumbsUp,
  falPlus,
  falMinus,
  falPaperPlane,
  falCloudDownload,
  falCommentAlt,
  falBars,
  farBars,
  fasUser,
  fasUserPlus,
  fabInstagram,
  falCheckSquare,
  falSquare,
  fabYoutube,
  fabMedium,
  fabTiktok,
  fabTwitch,
  falAlignLeft,
  falAlignCenter,
  falAlignRight,
  fasQuoteLeft,
  fasQuoteRight,
  falCode,
  falLongArrowRight,
  falLongArrowLeft,
  fasMapMarkerAlt,
  fasPhoneAlt,
  falCopy,
  falArrowCircleRight,
  falArrowCircleLeft,
  farMapMarkerAlt,
  farPhoneAlt,
  farUniversity,
  farBookmark,
  falPlug,
  falFlagCheckered,
  falStopwatch,
  falHeartRate,
  falPlusCircle,
  falArrowLeft,
  falArrowRight,
  farEnvelope,
  falEye,
  falEyeSlach,
  fasLongArrowAltRight,
  falExternalLinkSquare,
  falCircleUser,
  fasEnvelope,
  fasPhone,
  falArrowDown,
  fasFilter,
  falFilter,
  falRotateRight,
  falPalette,
  falPhone,
  falCirclePlay,
  farChevronUp,
  farChevronDown,
  falGripVertical,
  falBasketShoppingSimple,
  falInboxFull
}

library.add(icons)

export default {
  install (app) {
    app.component('FaIcon', FontAwesomeIcon)
  }
}