export default [
  {
    name: 'custom_footer_kikirpa',
    path: 'modules/pages/kikirpa/FooterKikirpa'
  },
  {
    name: 'custom_header_kikirpa',
    path: 'modules/pages/kikirpa/HeaderKikirpa'
  },
  {
    name: 'homepage_kikirpa',
    path: 'modules/pages/kikirpa/HomepageKikirpa'
  },
  {
    name: 'doornik_kikirpa',
    path: 'modules/pages/kikirpa/DoornikKikirpa'
  },
  {
    name: 'sterren_kikirpa',
    path: 'modules/pages/kikirpa/SterrenKikirpa'
  }
]
