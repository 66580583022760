<template>
  <div
    id="gmaps"
    ref="container"
  >
    <div
      ref="info_window_content"
      v-if="slots.infowindow"
    >
      <slot
        name="infowindow"
        v-bind="selected_marker"
        v-if="selected_marker"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, watch, useSlots } from 'vue'
import { useClientColors } from '@/composables/app/useClientColors'
import { useGMaps } from '@/vendors/integrations/gmaps'
import { svgToBase64 } from '@/utils/utils'
import { get as _get } from 'lodash'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

const props = defineProps({
  markers: { type: Array, default: () => [] },
  zoom: { type: Number, default: 12 },
  mapStyle: { type: Array, default: () => [] },
  options: { type: Object, default: () => ({}) },
  center: { type: Object, default: () => ({ lat: 50.855095, lng: 4.2930168 }) },
  fitBounds: { type: Boolean, default: true },
  markerClustering: { type: Boolean, default: true },
  icon: String
})

const { zoom, mapStyle, fitBounds, markers, markerClustering } = toRefs(props)

const slots = useSlots()
const gmaps = useGMaps()
const { getColor } = useClientColors()

let all_markers = []
let marker_clusterer = null

const container = ref()
const map = ref(null)
const bounds = ref(null)
const info_window = ref(null)
const info_window_content = ref(null)
const selected_marker = ref(null)

const lat = computed(() => _get(props, 'center.lat'))
const lng = computed(() => _get(props, 'center.lng'))
const center = computed(() => props.center && lat.value && lng.value ? new google.maps.LatLng(lat.value, lng.value) : null)

const defaultIcon = computed(() => ({ url: svgToBase64(`<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 45" style="enable-background:new 0 0 32 45;" xml:space="preserve" fill="${getColor('secondary-color')}"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1,0C24.9,0,32,7.2,32,16.1c0,8.8-15.3,28.7-15.9,28.7C15.5,44.7,0,24.9,0,16.1C0,7.2,7.1,0,16.1,0z M16.1,10.1c3,0,5.5,2.5,5.5,5.5s-2.5,5.5-5.5,5.5s-5.5-2.5-5.5-5.5S13,10.1,16.1,10.1z"/></svg>`), scaledSize: new google.maps.Size(32, 45) }))
const defaultClusterIcon = computed(() => ({ url: svgToBase64(`<svg fill="${getColor('primary-color')}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity=".9" r="70" /></svg>`), scaledSize: new google.maps.Size(75, 75) }))

const options = computed(() => {
  return {
    center: center.value,
    scrollwheel: false,
    panControl: false,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    overviewMapControl: false,
    zoom: zoom.value,
    ...props.options
  }
})

const getMarkerIcon = icon => icon || props.icon ? ({ url: icon || props.icon }) : defaultIcon.value

const clearMarkers = () => {
  if (markerClustering.value && marker_clusterer) {
    marker_clusterer.removeMarkers(all_markers)
  } else {
    all_markers.forEach(marker => marker.setMap(null))
  }

  all_markers = []
  bounds.value = new google.maps.LatLngBounds()
}

const setMarkers = () => {
  clearMarkers()

  if (!markers.value || !markers.value.length) return

  all_markers = markers.value
    .filter(({ position }) => position.lat && position.lng)
    .map(item => {
      const { lat, lng } = item.position

      const latLng = new google.maps.LatLng(lat, lng)
      const marker = new google.maps.Marker({ map: map.value, position: latLng, icon: getMarkerIcon(item.icon) })

      if (info_window_content.value) {
        const infowindow = new google.maps.InfoWindow({ minWidth: 150, maxWidth: 200 })

        marker.addListener('click', () => {
          if (info_window.value) info_window.value.close()

          selected_marker.value = item

          setCenter(latLng)
          setZoom(zoom.value)

          infowindow.setContent(info_window_content.value)
          infowindow.open(map.value, marker)

          info_window.value = infowindow
        })
      }

      bounds.value.extend(latLng)

      return marker
    })

  if (markerClustering.value) marker_clusterer.addMarkers(all_markers)

  if (fitBounds.value && bounds.value) {
    if (all_markers.length > 1) {
      map.value.fitBounds(bounds.value, 30)
    } else if (all_markers.length) {
      setCenter(_get(all_markers, '0.position'))
    } else {
      setCenter()
    }
  }
}

const setCenter = position => {
  if (map.value) map.value.setCenter(position || center.value)
}

const setZoom = (zoom = 12) => {
  if (map.value && zoom) map.value.setZoom(zoom)
}

gmaps.loader().then(() => {
  map.value = new google.maps.Map(container.value, options.value)

  map.value.mapTypes.set('Styled', new google.maps.StyledMapType(mapStyle.value, { name: 'Styled' }))
  map.value.setMapTypeId('Styled')

  map.value.addListener('zoom_changed', () => {
    if (info_window.value) info_window.value.close()
  })

  marker_clusterer = new MarkerClusterer({ map: map.value, renderer: {
    render: ({ count, position }) => new google.maps.Marker({
      label: { text: String(count), color: 'white', fontSize: '14px' },
      icon: defaultClusterIcon.value,
      position,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    })
  } })

  setMarkers()
})

watch(markers, () => {
  if (window.google && google.maps) setMarkers()
})
</script>

<style lang="scss">
#gmaps {
  width: 100%;
  height: 100%;

  .gm-style .gm-style-iw-c {
    padding: 0;
  }

  .gm-style .gm-style-iw-d {
    padding: 0;
    overflow: auto!important;
  }

  button {
    z-index: 10;
    opacity: 1;

    span {
      background: $white;
    }
  }
}
</style>