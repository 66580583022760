<template>
  <div
    class="editor"
    ref="text_editor"
    v-if="editor"
  >
    <input
      type="text"
      v-if="id"
      class="editor__input"
      :id="id"
      @focus.prevent="handleFocus"
    >

    <div class="editor__menubar">
      <div
        class="menubar"
        ref="menubar"
      >
        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['bold', 'italic', 'underline'])"
        >
          <menu-bar-button
            v-if="isAvailablePlugins(['bold'])"
            :icon="['fal', 'bold']"
            :is-active="editor.isActive('bold')"
            @command="editor.chain().focus().toggleBold().run()"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['italic'])"
            :icon="['fal', 'italic']"
            :is-active="editor.isActive('italic')"
            @command="editor.chain().focus().toggleItalic().run()"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['underline'])"
            :icon="['fal', 'underline']"
            :is-active="editor.isActive('underline')"
            @command="editor.commands.toggleUnderline()"
          />
        </section>

        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['heading_h1', 'heading_h2'])"
        >
          <menu-bar-button
            v-if="isAvailablePlugins(['heading_h1'])"
            :icon="['fal', 'h1']"
            :is-active="editor.isActive('heading', { level: 1 })"
            @command="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['heading_h2'])"
            :icon="['fal', 'h2']"
            :is-active="editor.isActive('heading', { level: 2 })"
            @command="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          />
        </section>

        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['text_color'])"
        >
          <menu-bar-button
            :icon="['fal', 'palette']"
            :is-active="!!editor.getAttributes('textStyle').color"
            @command="text_color.toggle()"
          />
        </section>

        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['link'])"
        >
          <menu-bar-button
            :icon="['fal', 'link']"
            :is-active="editor.isActive('link')"
            :disabled="editor.isActive('link')"
            @command="link.show()"
          />
        </section>

        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['list_ul', 'list_ol', 'quote_right'])"
        >
          <menu-bar-button
            v-if="isAvailablePlugins(['list_ul'])"
            :icon="['fal', 'list-ul']"
            :is-active="editor.isActive('bulletList')"
            @command="editor.chain().focus().toggleBulletList().run()"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['list_ol'])"
            :icon="['fal', 'list-ol']"
            :is-active="editor.isActive('orderedList')"
            @command="editor.chain().focus().toggleOrderedList().run()"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['quote_right'])"
            :icon="['fal', 'quote-right']"
            :is-active="editor.isActive('blockquote')"
            @command="editor.chain().focus().toggleBlockquote().run()"
          />
        </section>

        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['align_left', 'align_center', 'align_right'])"
        >
          <menu-bar-button
            v-if="isAvailablePlugins(['align_left'])"
            :icon="['fal', 'align-left']"
            :is-active="editor.isActive({ textAlign: 'left' })"
            @command="editor.commands.unsetTextAlign()"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['align_center'])"
            :icon="['fal', 'align-center']"
            :is-active="editor.isActive({ textAlign: 'center' })"
            @command="editor.commands.setTextAlign('center')"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['align_right'])"
            :icon="['fal', 'align-right']"
            :is-active="editor.isActive({ textAlign: 'right' })"
            @command="editor.commands.setTextAlign('right')"
          />
        </section>

        <section
          class="menubar__group"
          v-if="isAvailablePlugins(['image', 'video', 'code'])"
        >
          <menu-bar-button-image
            v-if="isAvailablePlugins(['image'])"
            :icon="['far', 'file-image']"
            :is-active="editor.isActive('image')"
            :disabled="editor.isActive('image')"
            @command="input => editor.commands.setImages(input)"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['video'])"
            :icon="['fab', 'youtube']"
            :is-active="editor.isActive('embed', { type: 'video' })"
            :disabled="editor.isActive('embed')"
            @command="embed.toggle({ type: 'video' })"
          />

          <menu-bar-button
            v-if="isAvailablePlugins(['code'])"
            :icon="['fal', 'code']"
            :is-active="editor.isActive('embed', { type: 'code' })"
            :disabled="editor.isActive('embed')"
            @command="embed.toggle({ type: 'code' })"
          />
        </section>
      </div>

      <div class="menubar__options">
        <menu-bar-text-color
          :editor="editor"
          @show="({ padding }) => { showMenuBarOption('text_color'); setPadding(padding) }"
          @hide="() => setPadding()"
          ref="text_color"
        />

        <menu-bar-form-link
          :editor="editor"
          @show="({ padding }) => { showMenuBarOption('link'); setPadding(padding) }"
          @hide="() => setPadding()"
          ref="link"
        />

        <menu-bar-form-embed
          :editor="editor"
          @show="({ padding }) => { showMenuBarOption('embed'); setPadding(padding) }"
          @hide="() => setPadding()"
          ref="embed"
        />
      </div>
    </div>

    <editor-content
      class="editor__content"
      :style="editor_style"
      :editor="editor"
      @click.self="handleFocus"
    />

    <div
      class="input--errors"
      v-if="v && v.$error"
    >
      <span
        class="input--error"
        v-if="v.required && v.required.$invalid"
      >
        {{ t('errors.input_text_required') }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, nextTick, watch, onBeforeUnmount, onUnmounted, defineAsyncComponent, toRefs } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useI18n } from '@/vendors/i18n'
import MenuBarButton from '&/modules/editor/MenuBarButton'
import MenuBarTextColor from '&/modules/editor/MenuBarTextColor'
import MenuBarFormLink from '&/modules/editor/MenuBarFormLink'
import MenuBarFormEmbed from '&/modules/editor/MenuBarFormEmbed'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Blockquote from '@tiptap/extension-blockquote'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import Dropcursor from '@tiptap/extension-dropcursor'
import { Color } from '@tiptap/extension-color'
import { Paragraph, Heading, Link, Embed, Image, BulletList, OrderedList, ListItem, InlineStyle } from '@/vendors/tiptap'
import { get as _get } from 'lodash'

const MenuBarButtonImage = defineAsyncComponent(() => import('&/modules/editor/MenuBarButtonImage'))

const emit = defineEmits(['update:modelValue'])

const { client } = mapGetters('client')

const props = defineProps({
  id: String,
  modelValue: { validator: prop => typeof prop === 'string' || prop === null, required: true },
  config: { type: Array, default: () => ['bold', 'italic', 'underline', 'heading_h1', 'heading_h2', 'list_ul', 'list_ol', 'quote_right', 'image', 'link', 'video', 'code', 'align_left', 'align_center', 'align_right', 'text_color'] },
  inlineStyle: { type: Boolean, default: false },
  v: Object
})

const { t } = useI18n()

const { v } = toRefs(props)

const text_editor = ref(null)
const menubar = ref(null)
const text_color = ref(null)
const link = ref(null)
const embed = ref(null)

const observer = ref(null)
const menu = reactive({ position_top: null, position_bottom: null, width: null, height: null })
const editor_style = reactive({ paddingTop: '56px' })

const defaultColor = computed(() => _get(client.value.style.colors.find(color => color.name === 'primary-color'), 'hex', '#333333'))
const inlineTypes = computed(() => props.inlineStyle ? ['paragraph', 'heading', 'bulletList', 'orderedlist', 'listitem', 'link', 'image'] : [])
const inlineStyles = computed(() => {
  return {
    paragraph: 'min-height:24px;margin:0;font-size:16px;line-height:1.5',
    link: 'text-decoration:underline',
    button: 'padding:12px 24px;display:inline-block;text-decoration:none;border-radius:6px;margin:0 6px',
    heading_h1: 'margin:6px 0;font-weight:normal;line-height:1.5;font-size:28px',
    heading_h2: 'margin:6px 0;font-weight:normal;line-height:1.5;font-size:20px',
    bulletList: 'list-style:disc;padding-left:16px;margin:0;font-size:16px',
    orderedlist: 'padding-left:16px;margin:0;font-size:16px',
    listitem: 'margin: 6px 0',
    figure: 'position:relative;max-width:630px;margin:30px auto;padding:0;overflow:hidden;border-radius:6px',
    image: 'max-width:100%;height:auto;display:block;padding:0',
  }
})

const handleFocus = () => nextTick(() => editor.value.commands.focus('end'))
const isAvailablePlugins = plugins => plugins.some(plugin => props.config.includes(plugin))
const handleToggle = (plugin, attrs) => plugin.toggle(attrs)
const setPadding = (padding = 0) => editor_style.paddingTop = (56 + padding) + 'px'
const showMenuBarOption = name => {
  const refs = { text_color: text_color.value, link: link.value, embed: embed.value }

  Object.keys(refs).forEach(key => {
    if (key !== name && typeof refs[key].hide === 'function') refs[key].hide()
  })
}

const handleScroll = () => {
  let scrollTop = document.documentElement.scrollTop
  let position = window.innerWidth < 992 ? 60 : 0

  if (menu.position_bottom - menu.position_top > (200 - position)) {
    if (scrollTop >= (menu.position_top - position) && scrollTop < menu.position_bottom - 200) {
      Object.assign(menubar.value.parentNode.style, { position: 'fixed', top: `${position}px`, width: `${menu.width}px` })
    } else if (scrollTop >= menu.position_bottom - 200 - position) {
      Object.assign(menubar.value.parentNode.style, { position: 'absolute', top: `${(menu.position_bottom - menu.position_top + position - 200)}px`, width: `${menu.width}px` })
    } else {
      menubar.value.parentNode.removeAttribute('style')
    }
  } else {
    menubar.value.parentNode.removeAttribute('style')
  }
}

const setWindowWidth = () => {
  menu.width = text_editor.value.clientWidth
  menu.position_top = window.scrollY + text_editor.value.getBoundingClientRect().top
  menu.position_bottom = window.scrollY + text_editor.value.getBoundingClientRect().bottom

  handleScroll()
}

const editor = useEditor({
  content: props.modelValue,
  extensions: [
    Document,
    Text,
    Bold,
    Italic,
    Underline,
    Blockquote,
    TextStyle,
    Paragraph,
    Heading.configure({ levels: [1, 2] }),
    Color.configure({ types: ['textStyle'] }),
    Link.configure({ toggle: attrs => handleToggle(link.value, attrs), defaultColor: defaultColor.value }),
    Embed.configure({ toggle: attrs => handleToggle(embed.value, attrs) }),
    Image,
    BulletList,
    OrderedList,
    ListItem,
    InlineStyle.configure({ types: inlineTypes.value, styles: inlineStyles.value }),
    TextAlign.configure({ types: ['heading', 'paragraph', 'embed', 'image'], alignments: ['left', 'center', 'right'] }),
    Dropcursor.configure({ color: '#2E293D' })
  ],
  onUpdate: () => {
    let content = editor.value.getHTML()
    let json = editor.value.getJSON().content

    if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty('content')) content = null

    emit('update:modelValue', content)
  },
  parseOptions: {
    preserveWhitespace: true
  }
})

setTimeout(() => nextTick().then(() => setWindowWidth()))

watch(() => props.modelValue,
  content => {
    menu.position_bottom = window.scrollY + text_editor.value.getBoundingClientRect().bottom

    if (content !== editor.value.getHTML()) editor.value.commands.setContent(content)
  }
)

if (window.ResizeObserver) {
  observer.value = new ResizeObserver(() => setWindowWidth())
  observer.value.observe(document.querySelector('body'))
}

window.addEventListener('scroll', handleScroll)
window.addEventListener('resize', setWindowWidth)

onBeforeUnmount(() => {
  editor.value.destroy()

  if (window.ResizeObserver && observer.value) observer.value.disconnect()
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', setWindowWidth)
})
</script>

<style lang="scss" scoped>
.menubar {
  display: flex;
  padding: calc($padding__base / 2);
  border-top: 1px solid rgba($dg, 0.1);
  border-bottom: 1px solid rgba($dg, 0.1);
  background-color: $white;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include mq(md) {
    border-top: 0;
    border-top-left-radius: $radius__cards;
    border-top-right-radius: $radius__cards;
  }

  &__options {
    position: relative;
  }

  &__group {
    position: relative;
    padding: 0 calc($padding__base / 2);
    display: flex;

    &:not(:first-child):before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $dw;
    }
  }
}

.editor {
  margin: calc($margin__base / 2) 0;
  border: 1px solid rgba($dg, 0.1);
  border-top-width: 0;
  border-bottom-left-radius: $radius__cards;
  border-bottom-right-radius: $radius__cards;

  @include mq(md) {
    border-top-width: 1px;
    border-radius: $radius__cards;
  }

  &__input {
    width: 0;
    height: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    display: block;
  }

  &__menubar {
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  &__content {
    padding: 56px $padding__base $padding__base;
  }
}
</style>
