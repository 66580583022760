<template>
  <div class="app-currency">
    <span v-if="type === 'EUR'">€</span>
    <span v-if="type === 'USD'">$</span>
    <span v-if="type === 'GBP'">£</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppCurrency',
  props: {
    type: {
      required: true,
      type: String,
      default: 'EUR'
    }
  }
})
</script>

<style scoped lang="scss">
  .app-currency {
    display: inline-block;
  }
</style>
