<template>
  <div v-if="isAuthenticated && allowedSportingActivitiesProvider('strava') && clientId">
    <template v-if="athlete">
      <slot
        name="athlete"
        v-bind="{ athlete, athleteName }"
      >
        <p
          v-if="!hasScopedSlot('athlete')"
          class="athlete d-flex align-items-center"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fab', 'strava']"
          />

          <span v-html="t('literal.linked_with_strava_as_x', { x: `<strong class='bold'>${athleteName}</strong>` })" />
        </p>
      </slot>

      <slot
        name="unlink"
        v-bind="{ loader, handleClickUnlink }"
      >
        <button
          class="unlink align--left margin__t--18"
          :class="{'unlink--disabled': loader}"
          @click.prevent="handleClickUnlink"
          :disabled="loader"
          v-if="!hasScopedSlot('unlink')"
        >
          <app-spinner
            v-if="loader"
            class="margin__r--6"
            size="2"
          />

          <fa-icon
            class="margin__r--6"
            :icon="['fal', 'unlink']"
            v-else
          />

          {{ t('literal.unlink') }}
        </button>
      </slot>
    </template>

    <template v-else>
      <slot
        name="auth"
        v-bind="{ loader: auth_loader, handleClickAuth }"
      >
        <button
          class="btn align--left btn__container btn__size--classic btn__color--sec"
          :class="{'btn--disabled': auth_loader}"
          :disabled="auth_loader"
          @click.prevent="handleClickAuth"
          v-if="!hasScopedSlot('auth')"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fab', 'strava']"
            v-if="!auth_loader"
          />

          <app-spinner
            class="margin__r--6"
            v-else
            size="2"
          />

          {{ t('literal.link_strava_account') }}
        </button>
      </slot>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, useSlots } from 'vue'
import { storeToRefs } from 'pinia'
import { mapGetters, mapActions } from '@/store/map-state'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { useStravaStore } from '@/stores/StravaStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { get as _get } from 'lodash'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))

const emit = defineEmits(['beforeLeave'])
const slots = useSlots()

const { replace } = useRouter()
const { query } = useRoute()
const { t } = useI18n()
const { fetchExchangeCode, postDeauthorizeUser } = useStravaStore()
const { loader } = storeToRefs(useStravaStore())
const { postSideNotification } = useNotificationStore()
const { allowedSportingActivitiesProvider } = useAllowedFeatures()
const { client } = mapGetters('client')
const { user, isAuthenticated } = mapGetters('auth')
const { fetchAuthUser } = mapActions('auth')

const scope = 'read,activity:read_all'
const base_url = 'https://www.strava.com/oauth/'

const auth_loader = ref(false)

const clientId = computed(() => _get(client.value, 'integrations.strava.client_id'))
const athlete = computed(() =>  _get(user.value, 'integrations.strava.athlete', false))
const athleteName = computed(() => athlete.value ? _get(athlete.value, 'firstname', '') + ' ' + _get(athlete.value, 'lastname', '').charAt(0) + '.' : '')

const hasScopedSlot = name => !!slots[name]

const handleClickAuth = () => {
  auth_loader.value = true

  emit('beforeLeave')

  setTimeout(() => window.location = `${base_url}authorize?client_id=${clientId.value}&response_type=code&redirect_uri=${window.location.href}&scope=${scope}`, 100)
}


const getToken = code => {
  fetchExchangeCode({ query: { code } })
    .then(() => fetchAuthUser())
    .catch(errors => showErrors(errors))
}

const handleClickUnlink = () => {
  postDeauthorizeUser()
    .then(() => fetchAuthUser())
    .catch(errors => showErrors(errors))
}

const showErrors = errors => _get(errors, 'data.errors', []).forEach(error => postSideNotification({ text: `errors.${error}`, type: 'error', delay: 0 }))

if (query.code) {
  if (scope === query.scope) {
    getToken(query.code)
  } else {
    postSideNotification({ text: 'errors.strava_invalid_scope', type: 'error', delay: 0 })
  }

  replace({ query: {} })
}
</script>

<style lang="scss" scoped>
.btn {
  background: #fc4c02;
  flex: 0 0 auto;

  &:hover {
    background: darken(#fc4c02, 10);
  }

  &--disabled,
  &--disabled:hover {
    background: #cccccc;
  }
}

.unlink {
  display: flex;
  align-items: center;
  color: $color--secondary;
  transition: all .2s;
  flex: 0 0 auto;

  &:hover {
    color: shade($color--secondary, 12%);
  }

  &--disabled {
    color: $mg;
    cursor: not-allowed;

    &:hover {
      color: $mg;
    }
  }
}
</style>
