<template>
  <section
    class="section"
    v-if="columns && columns.length"
  >
    <template
      v-for="(column, index) in columns"
      :key="`column_${index}`"
    >
      <app-component-loader
        class="column"
        :objects="{ column }"
        :component-path="column.component_path"
        v-if="column.component_path"
      />

      <template-column
        class="column"
        :column="column"
        v-else
      />
    </template>
  </section>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useRoles } from '@/composables/app/useRoles'
import { useBreakpoints } from '@/composables/app/useBreakpoints'
import { useProjectStore } from '@/stores/ProjectStore'
import { mapGetters } from '@/store/map-state'

import TemplateColumn from '&/modules/templates/TemplateColumn'

const AppComponentLoader = defineAsyncComponent(() => import('&/atoms/AppComponentLoader'))

const props = defineProps({  row: { type: Object, required: true } })

const route = useRoute()
const { isSuperAdmin, isKoalect } = useRoles()
const { getResponsiveStyle } = useBreakpoints()
const { getProject } = useProjectStore()
const { user: authUser } = mapGetters('auth')

const { row } = toRefs(props)

const { project } = getProject({ id: route.params.projectSlug })

const role = computed(() => {
  if (project.value && project.value.isAuthorized(authUser.value)) return 'project_member'
  if (isSuperAdmin.value) return 'super_admin'
  if (isKoalect.value) return 'koalect'

  return 'user'
})

const columns = computed(() => row.value.columns?.filter(column => column.isAllowed({ role: role.value })))
const layout = computed(() => columns.value?.map(column => getResponsiveStyle(column.style)).map(style => style.width || 'minmax(0, 1fr)').join(' '))
</script>

<style lang="scss" scoped>
.section {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 18px;

  @include mq(md) {
    grid-template-columns: v-bind(layout);
    grid-auto-flow: column;
    gap: 24px;
  }

  &:not(:last-child) {
    .column:not(:empty) {
      margin-bottom: 24px;
    }
  }

}
</style>

