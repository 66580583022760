<template>
  <ul>
    <li
      class="recap__item"
      v-if="fee"
    >
      <article class="recap__line">
        <h3 class="recap__title">
          {{ t(`$block_order_recap.fee_title_${procedure.category.replace('-', '_')}`) }}
        </h3>

        <div class="recap__info">
          <app-money
            :amount="fee"
          />
        </div>
      </article>
    </li>

    <li
      class="recap__item"
      v-if="formAmount"
    >
      <article class="recap__line">
        <h3 class="recap__title">
          {{ t('literal.contribution') }}
        </h3>

        <div class="recap__info">
          <app-money :amount="formAmount" />
        </div>
      </article>
    </li>

    <li
      class="recap__item"
      v-for="category in categories"
      :key="category"
    >
      <h4
        class="recap__category bold"
        v-if="category"
      >
        {{ category }}
      </h4>

      <article
        class="recap__line"
        v-for="product in products[category]"
        :key="product.id"
      >
        <h3 class="recap__title">
          {{ product.full_name }}
        </h3>

        <div class="recap__info">
          <span
            v-if="!product.is_limited_per_order || product.max_quantity_per_order > 1"
            class="margin__r--36"
          >
            x{{ product.quantity }}
          </span>

          <app-money
            v-if="product.total"
            :amount="product.total"
          />

          <span
            class="free"
            v-else
          >
            {{ t('literal.free') }}
          </span>
        </div>
      </article>
    </li>

    <li class="recap__item bold">
      <article class="recap__line">
        <h3 class="recap__title">
          {{ t('literal.total') }}
        </h3>

        <div class="recap__info">
          <app-money
            v-if="total"
            :amount="total"
          />

          <span
            class="free"
            v-else
          >
            {{ t('literal.free') }}
          </span>
        </div>
      </article>
    </li>
  </ul>
</template>

<script setup>
import { inject, defineAsyncComponent, computed } from 'vue'
import { useCartStore } from '@/stores/CartStore'
import { useProcedure } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { groupBy as _groupBy } from 'lodash'

const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))

const procedure = inject('procedure')

const { t, translate } = useI18n()

const { getCart } = useCartStore()
const { id } = useProcedure({ procedure })

const { formAmount, fee, order, total } = getCart({ id })

const products = computed(() => _groupBy(order.value, order_line => translate(order_line.label) || ''))
const categories = computed(() => Object.keys(products.value))

</script>

<style lang="scss" scoped>
.recap {
  &__item {
    padding: 10px 0;
    font-size: rem(16px);

    &:not(:first-child) {
      border-top: 1px solid $dw;
    }

    &:last-child {
      border-color: $black;
    }
  }

  &__category {
    font-size: rem(14px);
    color: $vlg;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 3px;
    }
  }

  &__title {
    flex: 1 1 auto;
    margin-right: 12px;
  }

  &__info {
    margin-left: auto;
    flex: 0 0 auto;

    span {
      display: inline-block;
      min-width: 50px;
      text-align: right;
      white-space: nowrap;
    }
  }
}

.free {
  text-transform: uppercase;
  font-size: rem(12px);
  color: $color--secondary;
  display: inline-block;
  font-weight: bold;
}
</style>