<template>
  <div
    class="text-color"
    v-if="is_active"
  >
    <div class="text-color__settings">
      <button
        @click.prevent="hide"
        class="link__color--black-prim link--naked"
        :title="t('literal.close')"
      >
        <fa-icon
          :icon="['fal', 'times']"
          fixed-width
        />
      </button>

      <fieldset class="d-flex align-items-center">
        <button
          @click="handleClickUnset"
          class="text-color__reset link__color--black-prim link--naked"
        >
          <fa-icon
            class="icon margin__r--6"
            :icon="['fal', 'arrow-rotate-left']"
            fixed-width
          />

          <span class="d-none d-sm-inline">{{ t('literal.reset_color') }}</span>
        </button>

        <ul class="colors">
          <li
            :key="systemColor.name"
            v-for="systemColor in systemColors"
            class="colors__li"
          >
            <button
              :style="{ backgroundColor: systemColor.hex }"
              :class="[`colors__btn colors__btn--${systemColor.name}`, { 'colors__btn--active': systemColor.hex === color }]"
              @click="setColor(systemColor.hex)"
            />
          </li>
        </ul>
      </fieldset>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useClientColors } from '@/composables/app/useClientColors'
import { rgbToHex } from '@/utils/utils'
import { useI18n } from '@/vendors/i18n'

const emit = defineEmits(['show', 'hide', 'command'])

const props = defineProps({
  editor: { type: Object, required: true }
})

const { t } = useI18n()
const { systemColors } = useClientColors()

const is_active = ref(false)

const color = computed(() => {
  const color = props.editor.getAttributes('textStyle').color

  if (color && color.includes('rgb')) {
    const [, r, g, b] = /^rgb\(([0-9]{1,3}), ([0-9]{1,3}), ([0-9]{1,3})\)$/gm.exec(color)

    return rgbToHex(parseInt(r), parseInt(g), parseInt(b))
  }

  return color
})

const setColor = color => props.editor.chain().focus().setColor(color).run()

const toggle = () => !is_active.value ? show() : hide()

const show = () => {
  is_active.value = true

  emit('show', { padding: 56 })
}

const hide = () => {
  if (!is_active.value) return

  is_active.value = false

  emit('hide')
}

const handleClickUnset = () => {
  props.editor.commands.focus()
  props.editor.commands.unsetColor()
}

defineExpose({ show, hide, toggle })
</script>

<style lang="scss" scoped>
.text-color {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: $padding__base;
  background: $dw;
  transform: translateY(100%);

  &__settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__reset {
    font-size: rem(14px);
    margin-right: calc($margin__base / 1.5);
  }
}

.colors {
  display: flex;
  justify-content: center;

  &__li {
    &:not(:last-child) {
      margin-right: calc($margin__base / 1.5);

      @include mq(sm) {
        margin-right: $margin__base;
      }

      @include mq(md) {
        margin-right: calc($margin__base / 2);
      }

      @include mq(lg) {
        margin-right: $margin__base;
      }
    }
  }

  &__btn {
    display: block;
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all $transition__duration;

    @include mq(sm) {
      width: 24px;
      height: 24px;
    }

    @include mq(md) {
      width: 20px;
      height: 20px;
    }

    @include mq(lg) {
      width: 24px;
      height: 24px;
    }

    &--white-color {
      border-color: rgba($dg, 0.1);
    }

    &--active {
      border-color: rgba($dg, 0.5);
    }
  }
}
</style>
