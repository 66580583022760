<template>
  <div :class="['switch__size--' + size]">
    <input
      :id="id"
      type="checkbox"
      class="switch"
      v-model="model"
      :disabled="disabled"
      @blur="blurIfMandatory()"
    >

    <label
      :for="id"
      class="switch__label"
    />

    <div
      class="input--errors"
      v-if="v && v.$error"
    >
      <p
        class="input--error"
        v-if="v.required && v.required === false"
      >
        {{ t('errors.input_switch_required') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useI18n } from '@/vendors/i18n'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: { type: String },
  modelValue: { type: [Boolean, String], default: false },
  v: { type: Object },
  size: { type: String, default: 's' },
  trueValue: { type: String },
  falseValue: { type: String },
  disabled: { type: Boolean, default: false }
})

const { t } = useI18n()
const { id, modelValue, v, size, trueValue, falseValue, disabled  } = toRefs(props)

const model = computed({
  get: () => typeof modelValue.value === 'string' ? modelValue.value === trueValue.value : modelValue.value,
  set: value => {
    if (trueValue.value && (value === true || value === trueValue.value)) {
      emit('update:modelValue', trueValue.value)
    } else if (falseValue.value && (value === false || value === falseValue.value)) {
      emit('update:modelValue', falseValue.value)
    } else {
      emit('update:modelValue', value)
    }
  }
})

const blurIfMandatory = () => {
  if (v.value) v.value.$touch()
}
</script>

<style lang="scss" scoped>
.switch {
  position: absolute;
  visibility : hidden;
  width : 0;
  height : 0;

  &__label {
    position : relative;
    display : block;
    cursor : pointer;
    transition : $transition__duration;
    text-indent : -9999px;
    border-radius : 50px;
    background : transparent;
    border : 1px solid $vlg;

    &:after {
      content : "";
      position : absolute;
      transition : $transition__duration;
      border-radius : 50px;
      background : $vlg;
    }
  }

  &:checked + .switch__label {
    border-style: solid;
    border-color: $color--primary;

    &:after {
      transform: translateX(-100%);
      background : $color--primary;
    }
  }

  &:disabled + .switch__label {
    cursor: not-allowed;
    border-color: rgba(#2E293D, .1);

    &:after {
      background : rgba(#2E293D, .1);
    }
  }

  &:disabled:checked + .switch__label {
    border-color : rgba(#2E293D, .1);

    &:after {
      background : rgba(#2E293D, .1);
    }
  }

  &__size {
    &--s {
      .switch__label {
        width : 24px;
        height : 15px;

        &:after {
          top : 2px;
          left : 3px;
          width : 9px;
          height : 9px;
        }

        &:active:after {
          width : 12px;
        }
      }

      .switch:checked + .switch__label {
        border-width: 1px;

        &:after {
          left : calc(100% - 3px);
        }
      }
    }

    &--xs {
      .switch__label {
        width : 19px;
        height : 12px;

        &:after {
          top : 2px;
          left : 3px;
          width : 6px;
          height : 6px;
        }

        &:active:after {
          width : 8px;
        }
      }

      .switch:checked + .switch__label {
        border-width: 1px;

        &:after {
          left : calc(100% - 3px);
        }
      }
    }
  }
}
</style>
