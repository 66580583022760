import { useProjectStore } from '@/stores/ProjectStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { storeToRefs } from 'pinia'
import { whenever } from '@/utils/utils'
import mobile from 'is-mobile'

const Project = () => import(/* webpackChunkName: 'Project' */'@/views/project/ViewProject')
const ProjectUpdate = () => import(/* webpackChunkName: 'ProjectContributions' */'@/views/project/ViewProjectUpdate')
const ProjectPrivate = () => import(/* webpackChunkName: 'ProjectPrivate' */'@/views/project/ViewProjectPrivate')
const PrivateAllContributions = () => import(/* webpackChunkName: 'ProjectAllContributions' */'&/modules/project/PrivateAllContributions')
const PrivateContribution = () => import(/* webpackChunkName: 'ProjectContribution' */'&/modules/project/PrivateContribution')
const PrivateAllProducts = () => import(/* webpackChunkName: 'PrivateAllProducts' */'&/modules/project/PrivateAllProducts')
const PrivateNewProduct = () => import(/* webpackChunkName: 'PrivateNewProduct' */'&/modules/project/PrivateNewProduct')
const PrivateEditProduct = () => import(/* webpackChunkName: 'PrivateEditProduct' */'&/modules/project/PrivateEditProduct')
const ProjectQrcode = () => import(/* webpackChunkName: 'ProjectQrcode' */'@/views/project/ViewProjectQrcode')
const ProjectSettings = () => import(/* webpackChunkName: 'ProjectSettings' */'@/views/project/ViewProjectSettings')
const PrivateSettingsGeneral = () => import(/* webpackChunkName: 'PrivateSettingsGeneral' */'&/modules/project/PrivateSettingsGeneral')
const PrivateSettingsGoal = () => import(/* webpackChunkName: 'PrivateSettingsGoal' */'&/modules/project/PrivateSettingsGoal')
const PrivateSettingsDuration = () => import(/* webpackChunkName: 'PrivateSettingsDuration' */'&/modules/project/PrivateSettingsDuration')
const PrivateSettingsMembers = () => import(/* webpackChunkName: 'PrivateSettingsMembers' */'&/modules/project/PrivateSettingsMembers')

const routes = [
  {
    path: 'project/:projectSlug',
    name: 'Project',
    component: Project,
    props: true,
    meta: {
      layout: 'full'
    }
  },
  {
    path: 'project/:projectSlug/contributions',
    name: 'ProjectContributions',
    component: ProjectPrivate,
    props: true,
    redirect: { name: 'PrivateAllContributions' },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'PrivateAllContributions',
        component: PrivateAllContributions,
        props: true,
        meta: {
          layout: 'full'
        }
      },
      {
        path: ':contributionId',
        name: 'PrivateContribution',
        component: PrivateContribution,
        props: true,
        meta: {
          layout: 'full'
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      const { authUser } = storeToRefs(useAuthenticationStore())
      const { getProject } = useProjectStore()
      const { projectSlug, locale } = to.params

      const { project, error } = getProject({ id: projectSlug })

      whenever(project, () => authUser.value.is_super_admin || authUser.value.is_koalect || project.value.isAuthorized(authUser.value) ? next() : next({ name: 'Project', params: { projectSlug, locale } }), { immediate: true })
      whenever(error, () => next({ name: 'NotFound', params: { locale } }))
    }
  },
  {
    path: 'project/:projectSlug/products',
    name: 'ProjectProducts',
    component: ProjectPrivate,
    props: true,
    redirect: { name: 'PrivateAllProducts' },
    children: [
      {
        path: '',
        name: 'PrivateAllProducts',
        component: PrivateAllProducts,
        props: true,
        meta: {
          requiresAuth: true,
          layout: 'full'
        }
      },
      {
        path: 'new',
        name: 'PrivateNewProduct',
        component: PrivateNewProduct,
        props: true,
        meta: {
          requiresAuth: true,
          layout: 'full'
        }
      },
      {
        path: ':productId',
        name: 'PrivateEditProduct',
        component: PrivateEditProduct,
        props: true,
        meta: {
          requiresAuth: true,
          layout: 'full'
        }
      }
    ]
  },
  {
    path: 'project/:projectSlug/qr-codes',
    name: 'ProjectQrcode',
    component: ProjectQrcode,
    props: true,
    meta: {
      requiresAuth: true,
      layout: 'full'
    }
  },
  {
    path: 'project/:projectSlug/settings',
    name: 'ProjectSettings',
    component: ProjectSettings,
    props: true,
    meta: {
      requiresAuth: true,
      layout: 'full'
    },
    redirect: !mobile() ? { name: 'PrivateSettingsGeneral' } : null,
    beforeEnter: (to, from, next) => {
      const { authUser } = storeToRefs(useAuthenticationStore())
      const { getProject } = useProjectStore()
      const { projectSlug, locale } = to.params

      const { project, error } = getProject({ id: projectSlug })

      whenever(project, () => project.value.isAuthorized(authUser.value) ? next() : next({ name: 'Project', params: { projectSlug, locale } }), { immediate: true })
      whenever(error, () => next({ name: 'NotFound', params: { locale } }))
    },
    children: [
      {
        path: 'general',
        name: 'PrivateSettingsGeneral',
        component: PrivateSettingsGeneral,
        props: true,
        meta: {
          title: 'general',
          requiresAuth: true,
          layout: 'full'
        }
      },
      {
        path: 'goal',
        name: 'PrivateSettingsGoal',
        component: PrivateSettingsGoal,
        props: true,
        meta: {
          title: 'goal',
          requiresAuth: true,
          layout: 'full'
        },
        beforeEnter: (to, from, next) => {
          const { getProject } = useProjectStore()
          const { projectSlug, locale } = to.params

          const { project } = getProject({ id: projectSlug })

          whenever(project, () => project.value.is_goal_allowed ? next() : next({ name: 'PrivateSettingsGeneral', params: { projectSlug, locale } }), { immediate: true })
        }
      },
      {
        path: 'duration',
        name: 'PrivateSettingsDuration',
        component: PrivateSettingsDuration,
        props: true,
        meta: {
          title: 'duration',
          requiresAuth: true,
          layout: 'full'
        }
      },
      {
        path: 'members',
        name: 'PrivateSettingsMembers',
        component: PrivateSettingsMembers,
        props: true,
        meta: {
          title: 'members',
          requiresAuth: true,
          layout: 'full'
        },
        beforeEnter: (to, from, next) => {
          const { getProject } = useProjectStore()
          const { projectSlug, locale } = to.params

          const { project } = getProject({ id: projectSlug })

          whenever(project, () => project.value.is_members_invite_allowed ? next() : next({ name: 'PrivateSettingsGeneral', params: { projectSlug, locale } }), { immediate: true })
        }
      }
    ]
  },
  {
    path: 'project/:projectSlug/update/:updateId',
    name: 'ProjectUpdate',
    component: ProjectUpdate,
    props: true,
    meta: {
      requiresAuth: true,
      layout: 'full'
    }
  }
]

export default routes
