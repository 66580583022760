<template>
  <progress
    class="progressbar"
    :class="{'progressbar--disabled': disabled}"
    :value="value"
    :max="maxValue"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true
    },
    maxValue: {
      type: Number,
      required: true,
      default: 100
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.progressbar {
  appearance: none;
  display: block;
  border: none;
  width: 100%;
  height: 6px;
  margin: calc($margin__base / 2) 0;
  color: $color--primary; /* For IE10 */

  &::-webkit-progress-bar {
    background-color: rgba($dg, .1);
    border-radius: 4px;
  }

  &::-moz-progress-bar {
    border-radius: 4px;
    background-color: $color--primary;
  }

  &::-webkit-progress-value {
    border-radius: 4px;
    background-color: $color--primary;
  }

  &--disabled::-webkit-progress-value, &--disabled::-moz-progress-bar {
    background-color: rgba($dg, .4);
  }
}
</style>
