<template>
  <button
    class="menu"
    :class="{'menu--active': state}"
    type="button"
    @click.prevent="$emit('toggle', state)"
  >
    <span class="menu__label">{{ state ? $i18n.t('literal.close') : 'Menu' }}</span>
    <div class="menu__lines">
      <span class="menu__line" />
      <span class="menu__line" />
      <span class="menu__line" />
      <span class="menu__close" />
    </div>
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppBurgerMenu',
  emits: ['toggle'],
  props: {
    state: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped lang="scss">
.menu {
  padding: $padding__base;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &__lines {
    position: relative;
  }

  &__label {
    font-size: em(14px);
    margin-right: $margin--base;
  }

  &__line {
    display: block;
    width: 24px;
    height: 2px;
    margin-bottom: 3px;
    background-color: $dg;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;

    &:nth-child(3) {
      width: 14px;
      margin: 0;
    }
  }

  &__close {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%) translateY(-50%);
    transform-origin: top left;

    &:before, &:after {
      content: '';
      display: block;
      background-color: $black;
      position: absolute;
      transition: transform 0.3s ease-in-out;
    }

    &:before {
      width: 2px;
      height: 20px;
      top: 0;
      left: 50%;
      margin-left: -1px;
      transition-delay: 0.1s;
      transform: scaleY(0);
      transform-origin: top;
    }

    &:after {
      width: 20px;
      height: 2px;
      top: 50%;
      left: 0;
      margin-top: -1px;
      transition-delay: 0;
      transform: scaleX(0);
      transform-origin: right;
    }
  }

  &--active {
    .menu__line {
      transform: scaleX(0);
    }

    .menu__close {
      &::before {
        transform: scaleY(1);
        transition-delay: 0.5s;
      }

      &::after {
        transform: scaleX(1);
        transition-delay: 0.6s;
      }
    }
  }
}

@for $i from 1 through 3 {
  .menu__line {
    &:nth-child(#{$i}) {
      transition-delay: 0.1s + 0.1s * $i;
    }
  }

  .menu--active {
    .menu__line {
      &:nth-child(#{$i}) {
        transition-delay: 0.1s - 0.1s * ($i - 1);
      }
    }
  }
}
</style>
