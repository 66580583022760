<template>
  <template
    v-for="(block, i) in blocks"
    :key="getKey(block.name, index, i)"
  >
    <article class="block">
      <app-component-loader
        :component-path="block.component_path"
        :objects="{ block }"
      />
    </article>
  </template>
</template>

<script setup>
import { guid } from '@/utils/utils'

import AppComponentLoader from '&/atoms/AppComponentLoader'

defineProps({
  blocks: { type: Array, required: true },
  index: { type: [String, Number], required: true }
})

const getKey = (name, index, i) => name === 'transaction_payment_methods' ? name : `${name}_${index}_${i}_${guid(1)}`
</script>

<style lang="scss" scoped>
.block {
  &:not(:first-child):not(:empty) {
    margin-top: 12px;
  }

  &:first-child:empty + .block {
    margin-top: 0;
  }
}
</style>