<template>
  <div
    class="updates"
    v-if="isShowed('updates')"
  >
    <article
      v-if="isAuthorized"
      class="card margin__b--24"
    >
      <div class="card__main padding--18">
        <h3 class="title__h3 margin__b--18 bold">
          {{ t('literal.new_update') }}
        </h3>

        <form-notification
          class="margin__tb--12"
          :keys="error"
        />

        <app-text-editor
          class="editor"
          v-model="new_update.content"
          :v="v$.content"
          :inline-style="true"
        />

        <input-checkbox
          v-model="new_update.notify_by_email"
          class="margin__t--18"
        >
          {{ t('literal.send_update_by_email_to_contributors') }}
        </input-checkbox>

        <input-checkbox
          v-model="new_update.is_public"
          class="margin__t--12"
        >
          {{ t('make_update_public_on_project_page') }}
        </input-checkbox>

        <button
          class="btn btn__container btn__size--small btn__color--prim margin__t--24"
          type="button"
          name="publish"
          :disabled="loader || fileLoader"
          @click.prevent="handleClickPostUpdate()"
        >
          <fa-icon
            v-if="!loader"
            class="margin__r--6"
            size="sm"
            :icon="['fal', 'paper-plane']"
            fixed-width
          />

          <app-spinner
            size="2"
            class="margin__r--6"
            v-else
          />

          {{ t('literal.publish') }}
        </button>
      </div>
    </article>

    <section v-if="sortedUpdates.length">
      <update-card
        v-for="update in sortedUpdates"
        :update="update"
        :allow-edit="isAuthorized"
        :key="update.id"
        @updated="fetchUpdates({ query })"
      />
    </section>

    <article v-if="!sortedUpdates.length && !updatesLoader">
      <div class="align--center margin__t--48">
        <fa-icon
          class="margin__r--6"
          :icon="['fal', 'comment-alt']"
        />

        {{ t('literal.no_updates_yet') }}
      </div>
    </article>
  </div>
</template>

<script setup>
import { reactive, computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useSampleStore } from '@/stores/SampleStore'
import { useFileStore } from '@/stores/FileStore'
import { useUpdateStore } from '@/stores/UpdateStore'
import { useBlock } from '@/composables/modules/templates'
import { useI18n } from '@/vendors/i18n'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters } from '@/store/map-state'
import Update from '@/classes/updates/Update'
import { get as _get, orderBy as _orderBy } from 'lodash'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const InputCheckbox = defineAsyncComponent(() => import('&/atoms/InputCheckbox'))
const FormNotification = defineAsyncComponent(() => import('&/molecules/FormNotification'))
const AppTextEditor = defineAsyncComponent(() => import('&/organisms/AppTextEditor'))
const UpdateCard = defineAsyncComponent(() => import('&/organisms/UpdateCard'))

const props = defineProps({ block: { type: Object, required: true } })

const route = useRoute()
const { t } = useI18n()
const { objects, isPreview, isShowed } = useBlock(props.block)
const { getUpdates, postUpdate, fetchUpdates } = useUpdateStore()
const { getSamples } = useSampleStore()
const { loader, error } = storeToRefs(useUpdateStore())
const { loader: fileLoader } = storeToRefs(useFileStore())
const { user: authUser } = mapGetters('auth')

const query = { target_type: 'Project', target_id: route.params.projectSlug }
const initial_update = new Update({ target_type: 'Project', target_id: route.params.projectSlug, is_public: true })

const new_update = reactive({ ...initial_update })

const v$ = useVuelidate({ content: { required } }, new_update)

const { updates, samples, loader: updatesLoader } = isPreview.value ? getSamples({ id: 'updates', query: { key: 'updates' } }) : getUpdates({ query })

const project = computed(() => _get(objects.value, 'project'))
const isAuthorized = computed(() => project.value.isAuthorized(authUser.value) && project.value.status === 'published')
const sortedUpdates = computed(() => _orderBy(isPreview.value ? samples.value : updates.value, [c => c.created_at], ['desc']))

const handleClickPostUpdate = () => {
  v$.value.$touch()

  if (v$.value.$error) return

  postUpdate({ params: new_update })
    .then(() => {
      fetchUpdates({ query })

      Object.assign(new_update, initial_update)

      v$.value.$reset()
    })
}
</script>

<style lang="scss" scoped>
.updates {
  max-width: 930px;
}

.editor {
  & :deep() {
    .editor__content {
      min-height: 150px;

      @include mq(md) {
        min-height: 270px;
      }
    }
  }
}
</style>