<template>
  <div>
    <header
      class="header"
      :class="{'header--fixed': mobileMenuOpened}"
      v-if="section"
    >
      <div class="wrapper header__wrapper">
        <div class="header__cell">
          <app-link
            :link="website"
            class="logo"
            target="_self"
            v-slot="{ text }"
            v-if="website && website.show"
          >
            <span class="logo__website">
              <fa-icon
                class="logo__website-icon margin__r--6"
                :icon="['fal', 'long-arrow-left']"
              />

              {{ text }}
            </span>

            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </app-link>

          <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale } }"
            v-show="client.logo.url"
            v-else
            class="logo"
          >
            <img
              class="logo__img"
              :src="client.logo.url"
              :alt="client.name"
            >
          </router-link>
        </div>

        <div class="header__cell padding__tb--18">
          <div class="header__row margin__b--6 d-none d-md-flex align-items-center">
            <button
              @click.prevent="openSessionAppModal('register')"
              class="header__session"
              v-if="!isAuthenticated"
            >
              <fa-icon
                class="margin__r--6"
                :icon="['fas', 'user']"
              />
              <span v-html="$i18n.t('literal.login')" />
            </button>

            <router-link
              class="header__session header__session--user d-flex align-items-center"
              :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                size="2"
                :avatar="user.avatar"
              />

              <span class="d-block margin__l--6">{{ user.firstname }}</span>
            </router-link>

            <language-switcher
              class="header__language margin__l--24"
              v-if="isMultilingual"
            />
          </div>

          <div class="header__row">
            <nav v-if="navigation.length > 0">
              <ul class="header__menu">
                <li
                  class="header__menu-item"
                  v-for="(item, index) in navigation"
                  :key="`menu_item_${index}`"
                >
                  <app-link
                    :link="item.link"
                    target="_self"
                    class="header__menu-link"
                    @click="mobileMenuOpened = false"
                  />
                </li>
              </ul>
            </nav>

            <nav
              v-if="actions.length > 0"
            >
              <ul class="header__actions">
                <li
                  class="header__actions-item"
                  v-for="(item, index) in actions"
                  :key="`actions_item_${index}`"
                >
                  <app-link
                    :link="item.link"
                    target="_self"
                    class="header__actions-link bold"
                    @click="mobileMenuOpened = false"
                  />
                </li>
              </ul>
            </nav>

            <app-burger-menu
              class="header__burger d-md-none"
              :state="mobileMenuOpened"
              @toggle="toggleMobileMenu"
            />
          </div>
        </div>
      </div>

      <transition name="fade-mobile-menu">
        <section
          class="mobile"
          v-if="mobileMenuOpened"
        >
          <div class="wrapper mobile__wrapper">
            <div class="mobile__close">
              <button @click="mobileMenuOpened=false">
                <fa-icon
                  :icon="['fal', 'times']"
                  fixed-width
                />
              </button>
            </div>
            <ul
              class="mobile__menu"
              v-if="navigation.length > 0"
            >
              <li
                class="mobile__menu-item"
                v-for="(item, index) in navigation"
                :key="`menu_mobile_item_${index}`"
              >
                <app-link
                  :link="item.link"
                  target="_self"
                  class="mobile__menu-link"
                  @click="mobileMenuOpened = false"
                />
              </li>
            </ul>

            <ul
              class="mobile__actions"
              v-if="actions.length > 0"
            >
              <li
                class="mobile__actions-item"
                v-for="(item, index) in actions"
                :key="`actions_mobile_item_${index}`"
              >
                <app-link
                  :link="item.link"
                  target="_self"
                  class="mobile__actions-link bold"
                  @click="mobileMenuOpened = false"
                />
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">

            <ul class="mobile__sessions">
              <li
                class="mobile__sessions-item"
                v-if="!isAuthenticated"
              >
                <a
                  @click.prevent="openSessionAppModal('login')"
                  class="link__color--white link--naked pointer"
                >
                  <fa-icon
                    class="margin__r--6"
                    :icon="['fas', 'user']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.login') }}
                </a>
              </li>

              <li
                class="mobile__sessions-item pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                  @click.capture="mobileMenuOpened = false"
                  class="d-flex align-items-center link__color--white link--naked"
                >
                  <user-profile-img
                    class="margin__r--6"
                    size="2"
                    :avatar="user.avatar"
                  />

                  {{ user.firstname }}
                </router-link>
              </li>

              <li
                class="mobile__sessions-item mobile__sessions-item--logout pointer"
                v-if="isAuthenticated && user"
              >
                <router-link
                  :to="{ name: 'Logout' }"
                  class="link--naked pointer link__color--danger"
                >
                  <fa-icon
                    class="margin__r--6 color--danger"
                    :icon="['fas', 'sign-out']"
                    fixed-width
                  />
                  {{ $i18n.t('literal.logout') }}
                </router-link>
              </li>

              <li class="mobile__sessions-item">
                <language-switcher v-if="isMultilingual" />
              </li>
            </ul>

            <hr class="separator separator__size--full border__color--dw margin__tb--18">
          </div>
        </section>
      </transition>
    </header>

    <app-modal
      v-if="showSessionAppModal"
      size="sm"
      @close="showSessionAppModal = false"
    >
      <template #body>
        <session-forms
          class="modal__body"
          :tab="template"
          @authenticated="showSessionAppModal = false"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { get as _get } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfileImg from '&/atoms/UserProfileImg'
import AppBurgerMenu from '&/atoms/AppBurgerMenu'
import LanguageSwitcher from '&/atoms/LanguageSwitcher'

export default defineComponent({
  name: 'CustomHeaderUZAFoundation',
  components: {
    UserProfileImg,
    AppBurgerMenu,
    LanguageSwitcher,
    SessionForms: defineAsyncComponent(() => import('&/modules/sessions/SessionForms')),
    AppModal: defineAsyncComponent(() => import('&/organisms/AppModal')),
    AppLink: defineAsyncComponent(() => import('&/atoms/AppLink'))
  },
  props: {
    section: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showSessionAppModal: false,
      mobileMenuOpened: false,
      template: null
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/client',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    isMultilingual () {
      return this.client.active_languages.length > 1
    },
    navigation () {
      return _get(this.section, 'attributes.navigation', [])
    },
    actions () {
      return _get(this.section, 'attributes.actions', [])
    },
    website () {
      return _get(this.section, 'attributes.website', {})
    }
  },
  methods: {
    openSessionAppModal (template) {
      this.template = template
      this.showSessionAppModal = true
    },
    toggleMobileMenu (value) {
      this.mobileMenuOpened = !value
    }
  }
})
</script>

<style lang="scss" scoped>
$color--primary: #3122BE;
$color--secondary: #FB7A6D;

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sm) {
    padding: 0 30px;
  }

  @include mq(md) {
    padding: 0 60px;
  }
}

:deep(.logo) {
  display: block;
  position: relative;
  width: 210px;
  padding: 40px 20px;
  background: $white;

  .logo__website {
    display: block;
    font-size: rem(12px);
    margin-bottom: 20px;
    transition: all .2s;
    color: $black;

    &-icon {
      position: relative;
      display: inline-block;
      transition: all .2s;
      transform: none;
      color: $black;
    }
  }

  .logo__img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &.logo:hover {
    .logo__website {
      color: $color--secondary;

      &-icon {
        color: $color--secondary;
        transform: translateX(-5px);
      }
    }
  }
}

.header {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__session {
    font-size: rem(13px);
  }

  &__language {
    & :deep() {
      .locale-switcher {
        background: none;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__menu {
    display: none;

    @include mq(md) {
      display: flex;
      align-items: center;
      font-size: rem(17px);
    }

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    :deep(.header__menu-link) {
      position: relative;
      display: block;
      color: $color--primary;
      padding: 10px 0;

      &::after {
        content: '';
        position: absolute;
        display: block;
        background: $color--primary;
        width: 100%;
        height: 2px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) scale(0, 1);
        transition: all .2s;
      }

      &:hover, &.is-active {
        &:after {
          transform: translateX(-50%) scale(1);
        }
      }
    }
  }

  &__actions {
    display: none;

    @include mq(md) {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-left: 20px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    :deep(.header__actions-link) {
      display: block;
      color: $color--primary;
      padding: 19px 20px 15px;
      border: 2px solid $color--primary;
      line-height: 1;
      transition: all .2s;

      &:hover {
        background: $color--primary;
        color: $white;
      }
    }
  }

  &__burger {
    color: $color--primary;
    line-height: 1;

    :deep() {
      .menu__line {
        background-color: $color--primary;
      }

      .menu__close::before, .menu__close::after {
        background-color: $color--primary;
      }
    }
  }
}

.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $color--primary;
  z-index: 999;
  text-align: center;

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
  }

  &__close {
    text-align: right;
    font-size: 36px;
    color: $white;
  }

  &__menu {
    font-size: rem(36px);
    flex: 1 1 auto;
    margin-top: $margin__base * 2;

    &-item {
      &:not(:first-child) {
        margin-top: $margin__base;
      }
    }

    :deep(.mobile__menu-link) {
      color: $white;
      padding: 10px 0;
      display: inline-block;
      position: relative;
      line-height: 1;

      &.is-active {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          background: $white;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    :deep(.mobile__actions-link) {
      display: block;
      color: $white;
      padding: 19px 20px 15px;
      border: 2px solid $white;
      line-height: 1;
      transition: all .2s;

      &:hover {
        background: $color--secondary;
      }
    }
  }

  &__sessions {
    color: $white;
    display: flex;
    align-items: center;

    &-item {
      &:not(:last-child) {
        margin-right: $margin__base * 2;
      }
      &:last-child {
         margin-left: auto;
      }
    }
  }
}

.modal {
  &__body {
    padding: $padding__base * 2;

    @include mq(md) {
      padding: $padding__base * 3 $padding__base * 4;
    }
  }
}

.fade-mobile-menu-enter-active, .fade-mobile-menu-leave-active {
  transition: opacity .5s;
}
.fade-mobile-menu-enter, .fade-mobile-menu-leave-to {
  opacity: 0;
}
</style>
