export default {
  name: 'transaction_customer_birthday',
  component_path: 'modules/procedures/blocks/BlockInputDate',
  static_options: {
    autocomplete: 'bday',
    open_date: '1990-01-01 00:00:00',
    available_dates: {
      start: new Date(1900, 0, 0),
      end: new Date()
    }
  },
  static_mandatory: [`minDate:${new Date(1900, 0, 0).toISOString()}`, `maxDate:${new Date().toISOString()}`]
}