<template>
  <div>
    <template
      v-for="block in blocks"
      :key="`column_${block.name}`"
    >
      <app-component-loader
        class="block"
        :objects="{ block }"
        :component-path="block.component_path"
        v-if="block.component_path"
      />
    </template>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { get as _get } from 'lodash'

import AppComponentLoader from '&/atoms/AppComponentLoader'

const props = defineProps({
  column: { type: Object, required: true }
})

const { column } = toRefs(props)

const blocks = computed(() => _get(column.value, 'blocks', []))
</script>

<style lang="scss" scoped>
.block {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
}
</style>