<template>
  <div v-if="isAvailable">
    <project-counter
      class="statistic"
      :statistics="statistics"
      :statistic="statistic"
      :show-statistic="isShowed('statistic')"
    >
      <template
        #value
        v-if="statistic === 'duration'"
      >
        {{ daysLeft }}
      </template>

      <template
        #text="{ value }"
        v-if="isShowed(`text_${text}`)"
      >
        <template v-if="statistic === 'duration'">
          {{ plural(getText(text), daysLeft).toLowerCase() }}
        </template>

        <template v-else>
          {{ plural(getText(text), value, variables).toLowerCase() }}
        </template>
      </template>
    </project-counter>

    <app-progress-bar
      :value="amountCollected"
      :max-value="goalAmount"
      v-if="isShowed('progressbar') && goalAmount"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { getAmount } from '@/utils/utils'
import { get as _get } from 'lodash'
import { isFuture, differenceInCalendarDays } from 'date-fns'

import ProjectCounter from '@/components/modules/project/ProjectCounter'

const AppProgressBar = defineAsyncComponent(() => import('&/atoms/AppProgressBar'))

const props = defineProps({ block: { type: Object, required: true } })

const { plural, locale } = useI18n()
const { settings, objects, isShowed, getText } = useBlock(props.block)

const { statistic, text } = settings

const project = computed(() => _get(objects.value, 'project'))
const statistics = computed(() => _get(objects.value, 'statistics', {}))

const procedure = computed(() => _get(project.value, 'procedures', []).find(procedure => procedure.category === 'donation'))
const startDate = computed(() => new Date(procedure.value.start_date))
const endDate = computed(() => procedure.value.end_date ? new Date(procedure.value.end_date) : null)
const goalAmount = computed(() => _get(procedure.value, 'goal_amount') || 0)
const amountCollected = computed(() => _get(statistics.value, 'amount_collected', 0))

const daysLeft = computed(() => {
  const difference = differenceInCalendarDays(endDate.value, new Date())

  return difference > 0 ? difference : 0
})

const variables = computed(() => ({ goal: getAmount(goalAmount.value, locale) }))

const isAvailable = computed(() => {
  if (statistic === 'duration' && (!endDate.value || isFuture(startDate.value))) return false

  return isShowed('statistic') || isShowed(`text_${text}`) || (isShowed('progressbar') && goalAmount)
})
</script>

<style lang="scss" scoped>
.statistic {
  color: rgba($dg, .6);
}

.progressbar.progressbar {
  margin-top: 12px;
}
</style>