<template>
  <header
    class="header"
    :class="{ 'is-fixed': showMobileMenu }"
    translate="no"
  >
    <div class="container d-flex justify-content-between align-items-center">
      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="logo margin__r--30"
      >
        <img
          class="logo__img"
          :src="translate(logo) || client.logo.url"
          :alt="client.name"
        >
      </router-link>

      <nav
        class="navigation d-none d-md-block"
        :class="{ 'd-flex': showMobileMenu }"
      >
        <ul class="navigation__ul d-md-flex align--center">
          <li
            v-for="({ link, subnav }, index) in links"
            :key="`navigation_${index}`"
            class="navigation__li d-md-flex align-items-center"
          >
            <app-link
              :link="link"
              class="navigation__link link--naked link__color--black-prim"
            />

            <fa-icon
              :icon="['far', 'chevron-down']"
              class="navigation__icon margin__l--6"
              v-if="subnav.length"
            />

            <ul
              v-if="subnav.length"
              class="navigation__subnav"
            >
              <li
                v-for="({ link: sublink }, i) in subnav"
                :key="`navigation_${index}_${i}`"
                class="navigation__li"
              >
                <app-link
                  :link="sublink"
                  class="navigation__link link--naked link__color--black-prim"
                />
              </li>
            </ul>
          </li>
        </ul>

        <div class="navigation__actions d-flex justify-content-center flex-column flex-sm-row align-items-center">
          <app-link
            :link="cta"
            class="bold btn btn__color--prim btn__size--medium d-inline-block d-md-none cta"
          />

          <div class="d-md-none d-flex justify-content-center">
            <router-link
              class="session__user d-inline-flex align-items-center link__color--white-prim link--naked"
              :to="{ name: 'Profile', params: { locale } }"
              v-if="isAuthenticated && user"
            >
              <user-profile-img
                :size="2"
                :avatar="user.avatar"
              />

              <p class="margin__l--6">
                {{ user.firstname }}
              </p>
            </router-link>

            <button
              @click.prevent="openModal()"
              class="session__login link__color--white-prim link--naked"
              v-else
            >
              <fa-icon
                :icon="['fas', 'user']"
                fixed-width
              />

              {{ t('literal.login') }}
            </button>
          </div>
        </div>
      </nav>

      <div class="d-flex align-items-center flex-md-column justify-content-md-end align-items-md-end header__actions">
        <div class="d-none d-md-flex justify-content-center">
          <language-switcher
            class="language-switcher margin__r--18"
            v-if="isMultilingual"
          />

          <router-link
            class="session__user d-inline-flex align-items-center link__color--black-prim link--naked"
            :to="{ name: 'Profile', params: { locale } }"
            v-if="isAuthenticated && user"
          >
            <user-profile-img
              :size="2"
              :avatar="user.avatar"
            />

            <p class="margin__l--6">
              {{ user.firstname }}
            </p>
          </router-link>

          <button
            @click.prevent="openModal()"
            class="session__login link__color--black-prim link--naked"
            v-else
          >
            <fa-icon
              :icon="['fas', 'user']"
              fixed-width
            />

            {{ t('literal.login') }}
          </button>
        </div>

        <app-link
          :link="cta"
          class="bold btn btn__color--prim btn__size--medium d-none d-sm-inline-block cta"
        />

        <language-switcher
          class="language-switcher d-md-none"
          v-if="isMultilingual"
        />

        <app-burger-menu
          class="header__burger d-md-none"
          :state="showMobileMenu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>

    <session-modal
      tab="login"
      v-if="show_modal"
      @close="closeModal"
    />
  </header>
</template>

<script setup>
import { ref, computed, watch, defineAsyncComponent } from 'vue'
import { mapGetters } from '@/store/map-state'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { useModals } from '@/composables/app/useModals'
import { useRoute } from 'vue-router'
import { scrollTo } from '@/utils/utils'
import { get as _get } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const UserProfileImg = defineAsyncComponent(() => import('&/atoms/UserProfileImg'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const LanguageSwitcher = defineAsyncComponent(() => import('&/atoms/LanguageSwitcher'))
const SessionModal = defineAsyncComponent(() => import('&/organisms/SessionModal'))

const props = defineProps({
  section: { type: Object, required: true }
})

const route = useRoute()
const { t, translate, getLocale } = useI18n()
const { isAuthenticated, user } = mapGetters('auth')
const { show_modal, openModal, closeModal } = useModals()
const { getClient } = useClientStore()

const { client } = getClient()

const showMobileMenu = ref(false)

const isMultilingual = computed(() => client.value.active_languages.length > 1)
const locale = computed(() => getLocale())
const logo = computed(() => _get(props, 'section.attributes.logo', {}))
const cta = computed(() => _get(props, 'section.attributes.cta'))
const links = computed(() => _get(props, 'section.attributes.nav', []))

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => showMobileMenu.value = !showMobileMenu.value, 100)
}

watch(route, () => showMobileMenu.value = false)
</script>

<style lang="scss" scoped>
$color--primary: #E60F8E;
$color--tertiary: #653472;

.header {
  background: $white;
  padding: $padding__base 0;
  position: relative;
  z-index: 999;

  @include mq(sm) {
    padding: $padding__base * 2 0;
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    @include mq(md) {
      position: relative;
    }
  }

  :deep() {
    .cta {
      font-size: rem(18px);
      line-height: 1;
      border-radius: 25px;
    }
  }

  &__actions {
    gap: $margin__base;

    @include mq(md) {
      gap: calc($margin__base / 2);
    }
  }

  &__burger {
    padding: 0!important;

    & :deep() {
      .menu {
        &__label {
          font-size: rem(12px);
          margin-right: 6px;
        }
      }
    }
  }
}

.logo {
  &__img {
    height: 28px;
    width: auto;
    display: block;

    @include mq(sm) {
      height: 32px;
    }
  }
}

.navigation {
  flex: 1 1 auto;
  position: fixed;
  top: 52px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  background: $color--tertiary;
  z-index: 777;
  padding: $padding__base * 2 $padding__base;
  flex-direction: column;
  justify-content: space-between;

  @include mq(sm) {
    height: calc(100vh - 90px);
    top: 90px;
  }

  @include mq(md) {
    position: relative;
    top: 0;
    width: auto;
    height: inherit;
    padding: 0;
    z-index: 888;
    background: none;
    padding: $padding__base;
  }

  &__actions {
    gap: 12px;
  }

  &__li {
    position: relative;

    &:not(:last-child) {
      @include mq(md) {
        margin-right: $margin__base;
      }

      @include mq(xl) {
        margin-right: $margin__base * 1.5;
      }
    }

    &:deep() {
      .navigation__link {
        font-size: rem(20px);
        line-height: 2.1;
        display: block;
        color: $white;

        @include mq(sm) {
          font-size: rem(32px);
        }

        @include mq(md) {
          font-size: rem(16px);
          color: $black;

          &:hover {
            color: $color--primary;
          }
        }

        @include mq(lg) {
          font-size: rem(20px);
        }

        &.is-active {
          color: $color--primary;
        }
      }
    }

    &:hover {
      .navigation__icon {
        transform: rotate(-180deg);
      }

      .navigation__subnav {
        @include mq(md) {
          transform: translate(-50%, 100%);
          opacity: 1;
          pointer-events: inherit;
        }
      }
    }
  }

  &__icon {
    font-size: rem(10px);
    transition: transform $transition__duration;
    display: none;

    @include mq(md) {
      display: block;
    }
  }

  &__subnav {
    z-index: 9999;
    background: $color--tertiary;
    padding: calc($padding__base / 2);
    text-align: center;
    transition: transform $transition__duration, opacity $transition__duration;
    min-width: 150px;

    @include mq(md) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 90%);
      opacity: 0;
      pointer-events: none;
    }

    .navigation {
      &__li {
        margin: 0;

        @include mq(md) {
          &:not(:last-child) {
            border-bottom: 1px solid rgba(#ffffff, 0.2);
          }
        }

        &:deep() {
          .navigation__link {
            font-size: rem(18px);
            line-height: 1.4;
            padding: calc($padding__base / 2) $padding__base;
            color: $white;
            opacity: 0.7;

            @include mq(sm) {
              font-size: rem(20px);
            }

            @include mq(md) {
              font-size: rem(16px);
            }

            &:hover,
            &.is-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.language-switcher {
  & :deep() {
    .v-select,
    .vs__search {
      border: 0px!important;
    }

    .vs__selected {
      line-height: 1;
    }

    .ls__dropdown-menu-icon {
      display: none;
    }

    .vs__dropdown-toggle {
      padding: 0;
    }

    .vs__actions {
      padding: 0;
    }
  }
}
</style>
