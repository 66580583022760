
import { inject } from 'vue'
import store from '@/store'
import { $cookies } from '@/utils/utils'
import { get as _get } from 'lodash'

class GTM {
  constructor () {
    this.isLoaded = false
    this.isDraft = !!window.location.host.match('^draft([a-zA-Z0-9_-]+).koalect.com')
    this.containerId = null
  }

  init () {
    this.containerId = _get(store.getters, 'client/client.frontend_integrations.gtm_containerid', null)

    if (this.containerId && !this.isDraft) this.loadScript()
  }

  loadScript () {
    return new Promise(resolve => {
      if (this.isLoaded) resolve(this)

      const w = window
      const d = document
      const s = 'script'
      const l = 'dataLayer'

      w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

      let f = d.getElementsByTagName(s)[0]
      let j = d.createElement(s)
      let dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + this.containerId + dl; f.parentNode.insertBefore(j, f)
      j.onload = () => {
        this.isLoaded = true
        resolve(this)
      }
    })
  }

  setDefaultConsent () {
    const cookie = $cookies.get('koa_gtm_cookie_consent')

    this.consent('consent', 'default', {
      functional_storage: 'granted',
      security_storage: 'granted',
      analytics_storage: cookie && !!cookie.includes('analytics')  ? 'granted' : 'denied',
      ad_storage: cookie && !!cookie.includes('marketing') ? 'granted' : 'denied',
      ad_user_data: cookie && !!cookie.includes('marketing') ? 'granted' : 'denied',
      ad_personalization: 'denied'
    })
  }

  dataLayer () {
    return (window.dataLayer = window.dataLayer || [])
  }

  track (event) {
    this.dataLayer().push(event)
  }

  consent () {
    this.dataLayer().push(arguments)
  }
}

const gtmSymbol = Symbol()
const gtm = new GTM()

export const createGTM = {
  install: app => {
    gtm.setDefaultConsent()
    gtm.init()
    app.provide(gtmSymbol, gtm)
  }
}

export function useGTM () {
  return inject(gtmSymbol)
}

export default gtm