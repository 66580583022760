<template>
  <div
    class="datepicker__container"
    v-bind="$attrs"
  >
    <v-date-picker
      class="datepicker"
      v-model="date"
      color="indigo"
      :available-dates="availableDates"
      :is-required="true"
      :select-attribute="props.availableDates.start"
    >
      <template #default="{ togglePopover }">
        <button
          class="input__container input--border input--radius"
          @click="e => handleClickDate(e, togglePopover)"
        >
          <input
            :id="id"
            type="date"
            :min="minDate"
            :max="maxDate"
            :value="inputDate"
            :placeholder="t('literal.select_a_date')"
            class="input"
            :class="{'is-placeholder': !date}"
            @blur="e => handleDateBlur('date', e.target)"
          >

          <fa-icon
            class="color--sec input__icon"
            :icon="['fal', 'calendar']"
            fixed-width
          />
        </button>
      </template>
    </v-date-picker>

    <v-date-picker
      class="datepicker"
      :class="{'is-placeholder': !date}"
      v-model="date"
      color="indigo"
      mode="time"
      :available-dates="availableDates"
      :is-24hr="true"
      v-if="showTime"
    >
      <template #default="{ togglePopover }">
        <button
          class="input__container input--border input--radius"
          @click="e => handleClickDate(e, togglePopover)"
        >
          <input
            :value="inputTime"
            type="time"
            class="input"
            @blur="e => handleDateBlur('time', e.target)"
          >
        </button>
      </template>
    </v-date-picker>
  </div>

  <div
    class="input--errors"
    v-if="v && v.$error"
  >
    <p
      class="input--error"
      v-if="'required' in v && v.required.$invalid === true"
    >
      {{ t('errors.input_datepicker_required') }}
    </p>

    <p
      class="input--error"
      v-else-if="'minDate' in v && v.minDate.$invalid === true"
    >
      {{ t('errors.input_datepicker_min_date', { value: format(v.minDate.$params.min, dateFormat) }) }}
    </p>

    <p
      class="input--error"
      v-else-if="'maxDate' in v && v.maxDate.$invalid === true"
    >
      {{ t('errors.input_datepicker_max_date', { value: format(v.maxDate.$params.max, dateFormat) }) }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { format } from 'date-fns'
import isMobile from 'is-mobile'
import { DatePicker as VDatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const props = defineProps({
  id: String,
  modelValue: [Date, String],
  availableDates: { type: Object, default: () => ({}) },
  showTime: { type: Boolean, default: false },
  openDate: [Date, String],
  v: Object
})

const isMobileDevice = isMobile({ tablet: true })
const dateFormat = computed(() => props.showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy')
const minDate = computed(() => isMobileDevice && props.availableDates.start ? format(props.availableDates.start, 'yyyy-MM-dd') : '')
const maxDate = computed(() => isMobileDevice && props.availableDates.end ? format(props.availableDates.end, 'yyyy-MM-dd') : '')
const inputDate = computed(() => date.value ? format(date.value, 'yyyy-MM-dd') : '')
const inputTime = computed(() => date.value ? format(date.value, 'HH:mm') : '')

const currentDate = computed(() => {
  if (date.value) return date.value
  if (props.openDate) return new Date(props.openDate)
  if (props.availableDates.start) return new Date(props.availableDates.start)

  return new Date()
})

const date = computed({
  get: () => props.modelValue ? new Date(props.modelValue) : '',
  set (value) {
    if (value && !isNaN(value.getTime())) emit('update:modelValue', value.toISOString())
  }
})

const handleClickDate = (e, toggle) => {
  if (!date.value) date.value = currentDate.value

  if (!isMobileDevice) {
    e.preventDefault()

    toggle()
  }
}

const handleDateBlur = (type, target) => {
  const [year, month, day] = type === 'time' ? format(currentDate.value, 'yyyy-MM-dd').split('-') : target.value.split('-')
  const [hour, minute] = type === 'time' ? target.value.split(':') : format(currentDate.value, 'HH:mm').split(':')

  date.value = new Date(year, month - 1, day, hour, minute)
}
</script>

<style lang="scss" scoped>
.input {
  background: transparent;
  position: relative;
  height: 44px;

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }

  &.is-placeholder {
    color: $dw;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__icon {
    position: absolute;
    background: $white;
    right: 12px;
  }
}

.datepicker {
  flex-grow: 1;
  position: relative;

  &:not(:first-child) {
    margin-left: 24px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    @include mq(sm) {
      flex-direction: row;
    }
  }
}
</style>
