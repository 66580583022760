import { toValue } from 'vue'
import { defineStore } from 'pinia'
import { set as _set } from 'lodash'
import ProcedureExecution from '@/classes/procedures/ProcedureExecution'

export const useProcedureExecutionStore = defineStore('ProcedureExecutionStore', {
  state: () => ({
    procedure_execution: {},
    loader: false,
    error: {}
  }),
  getters: {
    getProcedureExecution: state => ({ procedure_id, execution_id, dependencies }) => {
      return state.$getItem({
        resource: 'procedure_execution',
        key: procedure_id,
        dependencies,
        fetch: () => state.fetchProcedureExecution({ procedure_id: toValue(procedure_id), execution_id: toValue(execution_id), default_value: new ProcedureExecution() })
      })
    }
  },
  actions: {
    fetchProcedureExecution ({ procedure_id, execution_id, default_value }) {
      return this.$fetchItem({
        endpoint: `/api/v1/procedures/${toValue(procedure_id)}/executions/${toValue(execution_id)}`,
        resource: `procedure_execution.${toValue(procedure_id)}`,
        model: ProcedureExecution,
        default_value
      })
    },
    postProcedureExecution ({ procedure_id, params }) {
      return this.$postItem({
        endpoint: `/api/v1/procedures/${toValue(procedure_id)}/execute`,
        resource: `procedure_execution.${toValue(procedure_id)}`,
        model: ProcedureExecution,
        params
      })
    },
    patchProcedureExecutionInformations ({ procedure_id, execution_id, params }) {
      return this.$patchItem({
        endpoint: `/api/v1/procedures/${toValue(procedure_id)}/executions/${toValue(execution_id)}`,
        resource: `procedure_execution.${toValue(procedure_id)}`,
        model: ProcedureExecution,
        params: {
          procedure_execution: { informations: params }
        }
      })
    }
  },
  persist: {
    paths: ['procedure_execution'],
    storage: sessionStorage,
    afterRestore: ({ store }) => {
      const state = store.$state.procedure_execution
      Object.keys(state).forEach(key => _set(state, key, { item: new ProcedureExecution(state[key].item), loader: false, error: state[key].error, timestamp: state[key].timestamp }))
    }
  }
})
