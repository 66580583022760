import { useAuthenticationStore } from '@/stores/AuthenticationStore'

const Login = () => import(/* webpackChunkName: 'ViewLogin' */'@/views/sessions/ViewLogin')
const Register = () => import(/* webpackChunkName: 'ViewRegister' */'@/views/sessions/ViewRegister')
const ForgotPassword = () => import(/* webpackChunkName: 'ViewForgotPassword' */'@/views/sessions/ViewForgotPassword')
const ResetPassword = () => import(/* webpackChunkName: 'ViewResetPassword' */'@/views/sessions/ViewResetPassword')

const routes = [
  {
    path: 'login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'full',
      requiresGuest: true
    }
  },
  {
    path: 'register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'full',
      requiresGuest: true
    }
  },
  {
    path: 'forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      layout: 'full',
      requiresGuest: true
    }
  },
  {
    path: 'reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresGuest: true
    },
    beforeEnter (to, from, next) {
      if (Object.keys(to.query).length === 0) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: 'logout',
    name: 'Logout',
    meta: { requiresAuth: true },
    beforeEnter (to, from, next) {
      const { logout } = useAuthenticationStore()
      logout()

      from.meta.requiresAuth ? next({ name: 'Home' }) :  next({ name: from.name })
    }
  }
]

export default routes
