<template>
  <div>
    <label
      class="input__label"
      :for="id"
    >
      {{ translate(label) }} <span v-if="block.is_required">*</span>
    </label>

    <input-national-id
      class="margin__t--6"
      v-model="model"
      :v="validator"
      :id="id"
      :placeholder="translate(placeholder)"
    />

    <p
      class="additional-text"
      v-if="translate(text)"
    >
      {{ translate(text) }}
    </p>
  </div>
</template>

<script setup>
import { computed, toRefs, inject } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useModule } from '@/composables/app/useModule'
import { useProcedureBlockValidator } from '@/composables/modules/procedures'
import { get as _get } from 'lodash'

import InputNationalId from '&/atoms/InputNationalId'

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure_execution = inject('procedure_execution')

const { translate } = useI18n()
const { label, placeholder, model, id, attributes } = useModule({ component: block, parent: procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: block.value.validations, state: model })

const text = computed(() => _get(attributes.value, 'text', {}))
</script>

<style lang="scss" scoped>
.additional-text {
  color: $lg;
  margin-top: 4px;
  font-size: rem(12px);
  white-space: pre-line;
}
</style>